import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { useAppSelector } from "store"
import { setSelectedFactory } from "store/slice/factory.slice"

const FactoryDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch()
  // const factories = useSelector((state) => state.factory.factories);
  const selectedFactory = useAppSelector(state => state.factory.selected)
  const factory = useAppSelector(state => state.factory.factories);

  function factorySelect(fac) {
    // props.updateSelectedFactory(fac);
    dispatch(setSelectedFactory(fac));
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item  waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bxs-factory" style={{ fontSize: '16px' }} />
          {selectedFactory && <span className="d-none d-xl-inline-block me-2 ms-1">{selectedFactory['name']}</span>}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> Factories </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {factory &&
              factory.map((fac, index) => {
                const isSelected = (selectedFactory && fac['_id'] === selectedFactory['_id']) ? true : false;
                return (
                  <Link
                    to="#"
                    onClick={(e) => factorySelect(fac)}
                    key={index}
                    className="text-reset notification-item"
                  >
                    <div className="media" style={{ backgroundColor: (isSelected) ? 'lightblue' : '', display: 'flex', alignItems: 'center' }}>
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          {fac.name.charAt(0)}
                        </span>
                      </div>
                      <div className="media-body" style={{paddingLeft: '10px'}}>
                        <h6 className="mt-0 mb-1">{fac.name}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{/* {fac.name} */}</p>
                          <p className="mb-0">
                            {/* <i className="mdi mdi-clock-outline" />{" "} */}
                            {/* {props.t("3 min ago")}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(FactoryDropdown)

FactoryDropdown.propTypes = {
  t: PropTypes.any,
}
