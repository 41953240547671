import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import moment from "moment"
import { connect, useDispatch } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import EditableColumn from "../Factory/EditableColumn"
import ExternalMonitoring from "./ExternalMonitoring"
import PerformanceMonitoring from "./PerformanceMonitoring"
import MonthlyInspection from "./monthlyInspection"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import ReportPrint from "./printReport"
import _ from "lodash"
import { useAppSelector } from "store"
import { setCurrentDate, setMinDate } from "store/slice/dailymonitoring.slice"

const Options = [
  { label: "Yes", value: "yes:1" },
  { label: "No", value: "no:0" },
  // { label: "N/A", value: "na:1" },
]

const monthsAndnumbers: any = {}
moment.monthsShort().map((m, i) => {
  monthsAndnumbers[m] = i + 1
  // return { [m]: i }
})

const MonthlyMonitoring = (props: any) => {
  const dispatch = useDispatch();
  const date = useAppSelector(state => state.dailymontoringSlice);
  const months = moment.monthsShort()
  const year = moment().format("YYYY")
  const [userList, setuserList] = useState([])
  const [count, setCount] = useState(true)
  const [submitedBy, setSubmitedBy] = useState()
  const [showSubmit, setShowSubmit] = useState(false)
  const [reports, setReports] = useState<any>([])
  const [performanceReport, setperformanceReport] = useState<any>()
  const [performanceReportaggre, setperformanceReportaggre] = useState<any>();
  const [monthlyInspection, setMonthlyInspection] = useState([])
  const [score, setScore] = useState<any>()
  let location = useLocation();
  let isperformanceReview = location.pathname.includes('performanceReview');
  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) { }
  }

  async function getReport() {
    const { orgId, _id: facId } = props.selectedFactory
    try {
      let response = await backend.getmonthlyMonitoringReport(
        orgId,
        facId,
        year
      )
      if (response && response.report) {
        setReports(response.report)
        scoreCalc(response.report)
        let initialSetREport = response.report
        // console.log("123kjbbjh", reports)
        props.selectedFactory &&
          props.selectedFactory &&
          props.selectedFactory.monthlyMonitoringForm &&
          props.selectedFactory.monthlyMonitoringForm.map(
            (group: any, groupIndex: number) => {
              {
                group.lists.map((question: any, qIndex: number) => {
                  // console.log("groupquiestions", question)
                  const lmonth = _.map(question.months, "value")
                  {
                    months.map((m, mIndex) => {
                      const isMonth = lmonth.includes(m)
                        ? { backgroundColor: "#dddddd" }
                        : {}
                      const obj = {
                        groupId: group["_id"],
                        questionId: question["_id"],
                        month: m,
                      }
                      let disabled = false
                      // console.log("groupquiestionsisMonthhbhjb", isMonth)
                      if (isMonth) {
                        let mergedate = `${year}-${monthsAndnumbers[m]
                          }-${question.date.padStart(2, "0")}`
                        let todayDate = moment().format("YYYY-MM-DD")

                        // let date = (idx ).toLocaleString()
                        //     return date.padStart(2, "0")
                        disabled = moment(todayDate).isAfter(mergedate) // true
                        const find = _.find(initialSetREport, obj)
                        // console.log(
                        //   "findfindfindfindfind",
                        //   find,
                        //   disabled,
                        //   todayDate,
                        //   mergedate
                        // )

                        if (disabled && !find) {
                          // console.log(
                          //   "innerfindfindfindfindfindfind",
                          //   find,
                          //   initialSetREport
                          // )
                          // function updateReportInitial(
                          //   obj,
                          //   value,
                          //   inputtype,
                          //   initialSetREport
                          // ) {
                          // console.log("reports", obj, initialSetREport)

                          const cloneReports = [...initialSetREport]
                          const findIndex = _.findIndex(cloneReports, obj)
                          if (findIndex < 0) {
                            cloneReports.push({
                              ...obj,
                              value: Options[1].value,
                              type: question.type,
                            })
                          } else {
                            cloneReports[findIndex].value = Options[1].value
                          }

                          initialSetREport = cloneReports

                        }

                      }
                    })
                  }
                })
              }
            }
          )
        // console.log("initialSetREport", initialSetREport)

        setReports(initialSetREport)
        scoreCalc(initialSetREport)
      }
    } catch (e) { }
  }

  async function scoreCalc(reportsP: any) {
    const lreports = reportsP ? reportsP : reports
    const op = func.monthlyMonitoringScoreCalc(
      props.selectedFactory.monthlyMonitoringForm,
      props.selectedFactory.responsibility,
      props.selectedFactory.teams,
      lreports,
      year
    )
    // console.log("ooooooooop", op)

    setScore(op)
  }

  // get reports
  async function getmonthlyperformaceReport() {
    const { orgId, _id: facId } = props.selectedFactory
    const startDate = `${year}-${"01"}-${"01"}`
    const endDate = `${year}-${12}-${31}`
    try {
      let response = await backend.getmonthlyperformaceReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      if (response) {
        setperformanceReport(response)
      }
    } catch (e) {
      console.log("get performance report", e)
    }
  }

  // get usersList
  useEffect(() => {
    if (count && props.selectedFactory) {
      getUsers()
      getmonthlyperformaceReport()
      dispatch(setMinDate(props.selectedFactory.createdAt))
      getReport()
      setCount(false)
    }
  }, [count, userList, props.selectedFactory])

  async function submit() {
    const { orgId, _id: facId } = props.selectedFactory
    // console.log("submit", reports)
    try {
      const data = { report: reports }
      let response = await backend.savemonthlyMonitoringReport(
        orgId,
        facId,
        year,
        data
      )
      Toaster.success("Report saved SuccessFully")
      getReport()
    } catch (e) {
      Toaster.error("Report saved Failed")
    }
  }

  function updateReport(obj: any, value: any, inputtype: any) {
    // console.log("reports", obj, value, inputtype)

    const cloneReports: any = [...reports]
    const findIndex = _.findIndex(cloneReports, obj)
    if (findIndex < 0) {
      cloneReports.push({ ...obj, value: value, type: inputtype })
    } else {
      cloneReports[findIndex].value = value
    }
    // console.log("cloneReportscloneReportscloneReports", cloneReports)

    setReports([...cloneReports])
    scoreCalc(cloneReports)
  }
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current
  })

  if (!props.selectedFactory) {
    return <></>
  }

  // console.log("", props.selectedFactory.monthlyMonitoringForm)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* {!showSubmit && ( */}
          <div style={{ display: "none" }}>
            <ReportPrint
              // date={`${year}-${month}-${day}`}
              ref={componentRef}
              Props={props}
              year={year}
              score={score}
              performanceReportaggre={performanceReportaggre}
              performanceReport={performanceReport}
              // submitedBy={submitedBy}
              userList={userList}
              months={months}
              reports={reports}
              Options={Options}
            />
          </div>
          {/* )} */}
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="mt-0 card-title">{isperformanceReview ? 'Performance Review' : 'Monthly Monitoring'} </div>
                    {/* <p style={{ marginTop: "2px" }}> Monthly Monitoring</p> */}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="mt-0 card-title">Factory Name : </div>{"  "}
                    <p style={{ marginTop: "2px" }}>
                      &nbsp;&nbsp; {props.selectedFactory.name}
                    </p>
                  </div>
                  {!showSubmit && (
                    <Button
                      onClick={handlePrint}
                      style={{ margin: "0px 0px", width: "90px" }}
                      color="success"
                    >
                      Print
                    </Button>
                  )}
                  {/* {!showSubmit && (
                    <Button
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                      onClick={handlePrint}
                      color="success"
                    >
                      Print
                    </Button>
                  )} */}
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              {isperformanceReview && <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table
                      className="table table-hover table-borderless mb-0"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          {props.selectedFactory &&
                            props.selectedFactory.teams.map((team: any, mIndex: number) => {
                              const find: any = _.find(userList, [
                                "_id",
                                team.userId,
                              ])
                              if (find) return <th key={mIndex}>{find.role}</th>
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {props.selectedFactory &&
                            props.selectedFactory.teams.map((team: any, mIndex: number) => {
                              const find = _.find(userList, [
                                "_id",
                                team.userId,
                              ])
                              const divide = 100 / 1200
                              let value = func.monthlyScoreOverall(
                                performanceReportaggre,
                                score,
                                team.userId,
                                year
                              )
                              if (find)
                                return (
                                  <th key={mIndex}>
                                    {(divide * value).toFixed(1)}
                                  </th>
                                )
                            })}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div style={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}>
                    <input
                      className="form-control"
                      type="month"
                      onChange={e => {
                        dispatch(setCurrentDate(e.target.value))
                      }}
                      style={{ width: "auto" }}
                      min={moment(date.minDate, 'YYYY-MM').format("YYYY-MM")}
                      value={moment(date.currentDate, 'YYYY-MM').format("YYYY-MM")}
                      id="example-month-input"
                    />
                  </div>
                  <div
                    className="table-responsive"
                    style={{ overflowY: "auto", height: "65vh" }}
                  >
                    <Table
                      className="table table-hover  table-bordered  mb-0"
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: 0,
                        textAlign: "center",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              background: "#ffff",
                              zIndex: 1,
                              borderTop: "1px solid #eff2f7",
                              borderBottom: "1px solid #eff2f7",
                            }}
                          >
                            Description
                          </th>
                          {months.map((m, mIndex) => {
                            return (
                              <th
                                style={{
                                  position: "sticky",
                                  top: "0",
                                  background: "#ffff",
                                  zIndex: 1,
                                  borderTop: "1px solid #eff2f7",
                                  borderBottom: "1px solid #eff2f7",
                                }}
                                key={mIndex}
                              >
                                <span style={{ opacity: (date.months.includes(m)) ? 1 : .5 }}>{m} </span>
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.monthlyMonitoringForm && !isperformanceReview &&
                          props.selectedFactory.monthlyMonitoringForm.map(
                            (group: any, groupIndex: number) => {
                              const _filter = _.find(props.selectedFactory.responsibility, (o) => {
                                return (o.groupId == group['_id'] && o.userId == props.user['_id'] || props.isAdmin)
                              })
                              if (_filter && _filter.status || props.isAdmin) {
                                return (
                                  <React.Fragment key={groupIndex}>
                                    <tr
                                      key={groupIndex}
                                      style={{ textAlign: "center" }}
                                      className="table-info"
                                    >
                                      <th colSpan={months.length + 1} scope="row">
                                        {group.name}
                                      </th>
                                    </tr>
                                    {group.lists.map((question: any, qIndex: number) => {
                                      const lmonth = _.map(
                                        question.months,
                                        "value"
                                      );
                                      return (
                                        <React.Fragment key={qIndex}>
                                          <tr>
                                            <th scope="row" rowSpan={2}>
                                              {question.name}
                                            </th>
                                            {months.map((m, mIndex) => {
                                              const isMonth = lmonth.includes(m)
                                              const style = !isMonth
                                                ? { backgroundColor: "#dddddd" }
                                                : {}
                                              return (
                                                <td key={mIndex} style={style}>
                                                  {isMonth ? question.date : ""}
                                                </td>
                                              )
                                            })}
                                          </tr>
                                          <tr>
                                            {months.map((m, mIndex) => {
                                              const isMonth = lmonth.includes(m)
                                              const style = !isMonth
                                                ? { backgroundColor: "#dddddd" }
                                                : {}
                                              const obj = {
                                                groupId: group["_id"],
                                                questionId: question["_id"],
                                                month: m,
                                              }
                                              let disabled = false;
                                              let isIncludeMonth = date.months.includes(m);
                                              if (isMonth && !props.isAdmin && question.date) {
                                                let mergedate = `${year}-${monthsAndnumbers[m]
                                                  }-${question.date.padStart(
                                                    2,
                                                    "0"
                                                  )}`
                                                let todayDate = moment().format(
                                                  "YYYY-MM-DD"
                                                )

                                                // let date = (idx ).toLocaleString()
                                                //     return date.padStart(2, "0")
                                                disabled = moment(
                                                  todayDate
                                                ).isAfter(mergedate) // true
                                                // if(disabled){
                                                //   updateReport(
                                                //     obj,
                                                //     Options[1].value,
                                                //     question.type
                                                //   )

                                                // }
                                                // console.log(
                                                //   "questionquestionquestion",
                                                //   moment(todayDate).isAfter(
                                                //     mergedate
                                                //   ),
                                                //   question,
                                                //   mergedate,
                                                //   todayDate,
                                                //   monthsAndnumbers,
                                                //   isMonth
                                                // )
                                              }
                                              const find = _.find(reports, obj)

                                              return (
                                                <td key={mIndex} style={style}>
                                                  {isMonth ? (
                                                    <EditableColumn
                                                      disabled={disabled || !isIncludeMonth}
                                                      update={(value: any) => {
                                                        updateReport(
                                                          obj,
                                                          value,
                                                          question.type
                                                        )
                                                      }}
                                                      options={Options}
                                                      placeholder={" "}
                                                      type={question.type}
                                                      value={
                                                        find && find.value
                                                          ? find.value
                                                          : null
                                                      }
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                              )
                                            })}
                                          </tr>
                                        </React.Fragment>
                                      )
                                    })}
                                  </React.Fragment>
                                )
                              } else {
                                return null;
                              }
                            }
                          )}

                        {props.selectedFactory && isperformanceReview &&
                          userList.length > 0 &&
                          props.selectedFactory.teams && (
                            <PerformanceMonitoring
                              year={year}
                              updateReport={(e: any) => setperformanceReportaggre(e)}
                              performanceReport={performanceReport}
                              userList={userList}
                              teams={props.selectedFactory.teams}
                              months={months}
                            />
                          )}
                        {/* {props.selectedFactory && (
                          <MonthlyInspection months={months}
                            teams={props.selectedFactory.teams}
                            isperformanceReview={isperformanceReview}
                            userList={userList}
                            setMonthlyInspection={setMonthlyInspection}
                            year={year} score={score} />
                        )} */}
                        {props.selectedFactory && isperformanceReview &&
                          userList.length > 0 &&
                          props.selectedFactory.teams && (
                            <ExternalMonitoring
                              score={score}
                              year={year}
                              userList={userList}
                              monthlyInspection={monthlyInspection}
                              performanceReport={performanceReportaggre}
                              teams={props.selectedFactory.teams}
                              months={months}
                            />
                          )}
                      </tbody>
                    </Table>
                  </div>
                  {!isperformanceReview && <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    {
                      <Button
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                        onClick={submit}
                        color="success"
                      >
                        Submit
                      </Button>
                    }
                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any, props: any) => {
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return { selectedFactory, user, isAdmin: isAdmin }
}

export default withRouter(connect(mapStateToProps, {})(MonthlyMonitoring))
