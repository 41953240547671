import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
} from "reactstrap"
import { connect } from "react-redux"
import EditableColumn from "./EditableColumn"
import { withRouter } from "react-router-dom"
import Toaster from "../../components/Common/Toaster"
import * as backend from "../../helpers/backend_helper"

// Redux Store

const StatutoryReport = (props: any) => {
  // console.log("props", props)

  const {
    match: {
      params: { orgId, facId },
    },
  } = props

  const [reports, setReports] = useState<any[]>([])

  // add group
  function addGroup() {
    setReports([...reports, { name: null, lists: [] }])
  }

  useEffect(() => {
    setReports(props.statutoryReport)
    // console.log("propsagcgsdcd", props.statutoryReport)
  }, [])

  // on submit
  async function submit() {
    try {
      await backend.updateFactory(orgId, facId, {
        statutoryReport: reports,
      })

      Toaster.success("Statutory Report Updated Success")
    } catch (e) {
      console.log("statutoryReport error", e)
      Toaster.error("Statutory Report Updated Failed")
    }

    console.log(reports)
  }
  // remove group
  function removeGroup(idx) {
    const cloneList = [...reports]
    cloneList.splice(idx, 1)
    setReports([...cloneList])
  }

  function addSubList(id) {
    const cloneList = [...reports]
    cloneList[id].lists = [
      ...cloneList[id].lists,
      { name: null, type: "select" },
    ]
    setReports([...cloneList])
  }

  function updateGroupValue(e, key, idx) {
    const cloneList = [...reports]
    cloneList[idx][key] = e
    setReports([...cloneList])
  }

  function updateListValue(e, key, idx, liIndex) {
    const cloneList = [...reports]
    const list = cloneList[idx].lists[liIndex]
    if (list) {
      list[key] = e
    }
    setReports([...cloneList])
  }

  function onDelete(idd, subIndex) {
    const cloneList = [...reports]

    console.log("idd, subIndex", idd, subIndex, reports)

    const list = cloneList[idd].lists

    list.splice(subIndex, 1)
    console.log("cloneListcloneList", cloneList)
    setReports([...cloneList])
  }

  const SubListRender = (sublist, subIndex, idd) => (
    <Row>
      <Col lg={5}>
        <EditableColumn
          value={sublist.name}
          update={e => updateListValue(e, "name", idd, subIndex)}
        />
      </Col>
      <Col lg={4}>
        {!sublist.isHeader && (
          <Input
            onChange={e =>
              updateListValue(e.target.value, "type", idd, subIndex)
            }
            value={sublist.type}
            type="select"
            name="selectMulti"
            id="exampleSelectMulti"
          >
            <option></option>
            <option value="input"> Input </option>
            <option value="select"> Select </option>
          </Input>
        )}
      </Col>
      <Col lg={2}>
        <Label check>
          <Input
            checked={sublist.isHeader}
            type="checkbox"
            onChange={e =>
              updateListValue(e.target.checked, "isHeader", idd, subIndex)
            }
          />{" "}
          isHeader
        </Label>
      </Col>
      <Col lg={1}>
        <button
          onClick={e => onDelete(idd, subIndex)}
          type="button"
          className="btn btn-danger waves-effect waves-light"
        >
          <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
        </button>
      </Col>
    </Row>
  )

  return (
    <React.Fragment>
      <Container fluid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={addGroup}
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            New Group
          </button>
        </div>
        <br />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <ul
                  className="list-unstyled"
                  style={{ height: "60vh", overflow: "auto" }}
                >
                  {reports.map((report, idd) => (
                    <li key={idd}>
                      <div
                        className="factory-monitoring-group-list"
                        style={{ textAlign: "start" }}
                      >
                        <Row>
                          <Col md={10}>
                            <EditableColumn
                              value={report.name}
                              update={e => updateGroupValue(e, "name", idd)}
                            />
                          </Col>
                          {/* <Col lg={1} md={1}>
                            <button
                              onClick={e => addSubList(idd)}
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              ADD
                            </button>
                          </Col> */}
                          <Col md={1}>
                            <button
                              onClick={e => removeGroup(idd)}
                              type="button"
                              className="btn btn-danger waves-effect waves-light"
                            >
                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <ul className="list-unstyled">
                        {report.lists.map((sublist, subIndex) => (
                          <li key={subIndex} style={{ padding: "10px" }}>
                            {SubListRender(sublist, subIndex, idd)}
                          </li>
                        ))}
                      </ul>

                      <Col
                        lg={10}
                        md={10}
                        style={{ textAlign: "center", padding: "24px 0px" }}
                      >
                        <button
                          onClick={e => addSubList(idd)}
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          ADD
                        </button>
                      </Col>
                    </li>
                  ))}
                </ul>
                {reports.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="success">
                      Submit
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(StatutoryReport))
