import React, { useState, useEffect } from "react"
import Flatpickr from "react-flatpickr"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
  FormGroup,
  Input,
  Label,
  CardSubtitle,
} from "reactstrap"
import { desOptions } from "./const"
import * as backend from "../../helpers/backend_helper"
import _ from "lodash"

const Analysis = (props: any) => {
  const [userList, setuserList] = useState([])
  const [sortBy, setSortBy] = useState("person")
  const [range, setRange] = useState<any[]>([])
  const [user, setUserId] = useState("")
  const [finalReport, setFinalReport] = useState({})
  // const scopeList = Object.keys(desOptions)

  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) { }
  }

  async function filter() {
    try {
      const startDate = range[0]
      const endDate = range[1]
      const { orgId, _id: facId } = props.selectedFactory

      const response = await backend.getDailyWorkReportAnalysis(
        orgId,
        facId,
        startDate,
        endDate
      )
      // console.log("responseresponse", response)

      // setReportList(response)
      dataAnalysis(response)
    } catch (e) {
      console.log(e)
    }
  }

  const dataAnalysis = response => {
    let finaldata = {}
    if (sortBy === "person") {
      props.selectedFactory.workReportScope.map(key => {
        finaldata[key.name] = 0.0
      })

      response.map(data => {
        data.timeList.map(nesData => {
          if (user === data.submitedBy) {
            finaldata[nesData.scope] =
              Number(finaldata[nesData.scope]) + Number(nesData.duration)
          }
        })
      })
    } else {
      let finalworkdata = {}
      let userListInTeam: any[] = []

      props.selectedFactory &&
        props.selectedFactory.teams &&
        props.selectedFactory.teams.map((team, tIndex) => {
          const find: any = _.find(userList, ["_id", team.userId])
          if (find) {
            finalworkdata[find._id] = 0.0
            userListInTeam.push(find)
          }
        })

      response.map(data => {
        data.timeList.map(nesData => {
          if (nesData.scope === user) {
            finalworkdata[data.submitedBy] =
              Number(finalworkdata[data.submitedBy]) + Number(nesData.duration)
          }
        })
      })
      userListInTeam.map(user => {
        if (Object.keys(finalworkdata).includes(user._id)) {
          finaldata[user.name + user.role] = finalworkdata[user._id]
        }
      })
    }
    setTimeout(() => {
      setFinalReport(finaldata)
    }, 10)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const filterUserName: any = _.find(userList, ["_id", user])

  // console.log("filterUserName", filterUserName, finalReport)

  return (
    <React.Fragment>
      <Container fluid>
        {/* <h1>Im ready for analysis ----------------></h1> */}
        <Row>
          <Col md={2}>
            <Flatpickr
              className="form-control d-block"
              placeholder="Select Date"
              // style={{ margin: "2px" }}

              value={range}
              onChange={e => {
                // updateListValue(
                //   moment(e[0]).format("HH:mm"),
                //   "startDate",
                //   tIndex
                // )

                if (e.length === 2) {
                  // console.log("range picker", e)
                  // console.log("loggg", [
                  //   moment(e[0]).format("YYYY-MM-DD"),
                  //   moment(e[1]).format("YYYY-MM-DD"),
                  // ])
                  let startdate = moment(e[0]).format("YYYY-MM-DD")
                  let endDate = moment(e[1]).format("YYYY-MM-DD")

                  setRange([startdate, endDate])
                }
              }}
              options={{
                // enableTime: true,
                // noCalendar: true,
                // dateFormat: "H:i",
                mode: "range",
                dateFormat: "Y-m-d",
                // defaultDate: ["2016-10-10", "2016-10-20"],
              }}
            />
          </Col>
          <Col md={2}>
            <Input
              onChange={e => {
                setSortBy(e.target.value)
                setUserId("");
                setFinalReport({})
              }}
              type="select"
              name="selectMulti"
              value={sortBy}
              placeholder={"Select here"}
            >
              {[
                { key: "person", lable: "Sort by Person" },
                { key: "work", lable: "Sort by Work" },
              ].map((key, tIndex) => {
                return (
                  <option value={key.key} key={tIndex}>
                    {key.lable}
                  </option>
                )
              })}
            </Input>
          </Col>
          <Col md={4}>
            {sortBy === "person" ? (
              <Input
                onChange={e => {
                  setUserId(e.target.value)
                  setFinalReport({})
                }}
                value={user}
                type="select"
                name="selectMulti"
                placeholder={"Select here"}
              >
                <option value={""} key={""}></option>
                {props.selectedFactory &&
                  props.selectedFactory.teams &&
                  props.selectedFactory.teams.map((team, tIndex) => {
                    const find: any = _.find(userList, ["_id", team.userId])
                    if (find) {
                      return (
                        <option value={team.userId} key={tIndex}>
                          {find && find.name} ({find.role})
                        </option>
                      )
                    }
                  })}
              </Input>
            ) : (
              <Input
                type="select"
                value={user}
                name="selectMulti"
                placeholder={"Select here"}
                onChange={e => {
                  setUserId(e.target.value)
                  setFinalReport({})
                }}
              >
                <option value={""} key={""}></option>
                {props.selectedFactory && props.selectedFactory.workReportScope &&
                  props.selectedFactory.workReportScope.map((op, index) => (
                    <option value={op.name} key={index}>
                      {op.name}
                    </option>
                  ))}
              </Input>
            )}
          </Col>
          <Col>
            <Button
              style={{ marginLeft: "2px", marginRight: "2px" }}
              onClick={filter}
              color="success"
            >
              Search
            </Button>
          </Col>
        </Row>
        {Object.keys(finalReport).length > 0 && (
          <Row style={{ marginTop: "20px" }}>
            <Col md={10}>
              <Card>
                <CardBody>
                  <CardTitle>
                    {sortBy === "work" ? (
                      user
                    ) : (
                      <React.Fragment>
                        {filterUserName && filterUserName.name}(
                        {filterUserName && filterUserName.role})
                      </React.Fragment>
                    )}
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                  Use one of two modifier classNamees to make{" "}
                  <code>&lt;thead&gt;</code>s appear light or dark gray.
                </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          {/* <th>Person Name</th> */}
                          <th>Work report</th>
                          <th>Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(finalReport).map((data, i) => {
                          return (
                            <tr key={i}>
                              <th scope="row">{i + 1}</th>
                              <td>{data}</td>
                              <td>{finalReport[data]} Hours</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // console.log("state", state)

  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false

  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
    dailyWorkReport: state.dailyWorkReport,
  }
}

export default withRouter(connect(mapStateToProps, {})(Analysis))
