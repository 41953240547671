import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardSubtitle,
  CardTitle,
  Input,
} from "reactstrap"
import { useReactToPrint } from "react-to-print"
import ReportPrint from "./printReport"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import * as _ from "lodash"
import { saveHealthAndSafety, removeHealthAndSafety } from "../../store/healthAndSafety/action"
import { fileUrl } from "service"

const HealthAndSaftyReport = (props: any) => {
  const thisMonth = moment().format("YYYY-MM")
  const [date, setDate] = useState(thisMonth)
  const [reports, setReport] = useState([])
  const [departments, setDepartments] = useState([])
  const [images, setImages] = useState<any[]>([])
  const [values, setValues] = useState<any[]>([])
  const [formModal_data, setformModal_data] = useState<any>({})
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_data, setmodal_data] = useState<any>()
  const [submitedBy, setSubmitedBy] = useState<any>({})
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  })

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    // console.log("propsssssssssfdxdfxf", props)
    if (props && props.selectedFactory && props.selectedFactory.healthSafetyReport) {
      let clonedArray = JSON.parse(
        JSON.stringify(props.selectedFactory.healthSafetyReport)
      )

      setDepartments(props.selectedFactory.departments)

      setReport(clonedArray)
      getReport()
    }
  }, [props && props.selectedFactory && props.selectedFactory.healthSafetyReport])

  const getReport = async (newdate?, isFromImage?) => {
    if (props.selectedFactory.statutoryReport) {
      const { orgId, _id: facId } = props.selectedFactory
      let data = await backend.gethealthandsaftyreport(
        orgId,
        facId,
        newdate ? newdate : date
      )
      // console.log("datadatadata", data)

      if (Object.keys(data).length > 0) {
        // console.log("inside", isFromImage)
        if (isFromImage) {
          setImages(data.images)
          setSubmitedBy(data.submitedBy)
          getLocalSaveData(newdate ? newdate : date)
        } else {
          setImages(data.images)
          setValues(data.values)
          setSubmitedBy(data.submitedBy)
          getLocalSaveData(newdate ? newdate : date)
        }
      } else {
        setValues([])
        setImages([])
        setSubmitedBy({})
        getLocalSaveData(newdate ? newdate : date)
      }
    }
  }

  function setValue(groupid, questionid, deprtID, type, value) {
    // console.log("oId, idx, e", groupid, questionid, deprtID, type, value)
    let cloneReports = [...values]

    let dataIndex = _.findIndex(cloneReports, value => {
      return (
        value.groupId === groupid &&
        value.questionId === questionid &&
        value.departmentId === deprtID
      )
    })
    if (dataIndex >= 0) {
      if (value) {
        cloneReports[dataIndex].value = value
      } else {
        cloneReports.splice(dataIndex, 1)
      }
      // console.log("datafinddatafind", dataIndex, cloneReports)
    } else {
      cloneReports.push({
        groupId: groupid,
        questionId: questionid,
        departmentId: deprtID,
        type: type,
        value: value,
      })
    }

    // const list = cloneReports[oId].lists[idx]
    // if (list) {
    //   list["value"] = e.target.value
    // }
    setValues([...cloneReports])
    // console.log("cloneReportscloneReports", cloneReports)
  }
  function saveHandS() {
    const { _id: facId } = props.selectedFactory
    const data = {
      facId: facId,
      values: values,
      // submitedBy: submitedBy,
      date: date,
    }
    props.saveHealthAndSafety(data)
    Toaster.success("Report saved Successfully")
  }

  function getLocalSaveData(currentActivedate) {
    const { _id: facId } = props.selectedFactory

    if (
      props.savedHealthAndSafety[facId] &&
      props.savedHealthAndSafety[facId][currentActivedate] &&
      props.savedHealthAndSafety[facId][currentActivedate].date ===
      currentActivedate
    ) {
      // console.log(
      //   "prossin get saved",
      //   props.savedHealthAndSafety[facId][currentActivedate]
      // )
      // setTimeList(
      //   props.dailyWorkReport.dailyWorkReportSave[facId][currentActivedate].list
      // )

      var clonedArraySave = JSON.parse(
        JSON.stringify(
          props.savedHealthAndSafety[facId][currentActivedate].values
        )
      )

      setValues(clonedArraySave)
    }
  }

  async function submit() {
    const { orgId, _id: facId } = props.selectedFactory
    // console.log("selectedFactoryselectedFactory", props.selectedFactory)
    let data = {
      values: values,
      submitedBy: { name: props.user.name, id: props.user["_id"] },
    }
    try {
      await backend.HealthAndSaftyReport(orgId, facId, date, data)

      const removeData = {
        facId: facId,
        // reports: reports,
        // submitedBy: submitedBy,
        date: date,
      }
      console.log("removeData", removeData);


      props.removeHealthAndSafety(removeData)


      getReport(date, true)
      Toaster.success("Report Updated Success")
    } catch (e) {
      console.log("statutoryReport error", e)
      Toaster.error("Report Updated Failed")
    }
  }
  //uploadImage
  async function uploadImage() {
    const { orgId, _id: facId } = props.selectedFactory
    const { departmentId, groupId, questionId } = modal_data
    console.log("formModal_data", formModal_data)

    const formData = new FormData()
    // Update the formData object
    _.each(formModal_data.file, file => {
      formData.append("image", file)
    })
    formData.append("desc", formModal_data.desc || '')
    formData.append("departmentId", departmentId)
    formData.append("groupId", groupId)
    formData.append("questionId", questionId)
    formData.append("date", date)
    await backend.uploadHealthAndSaftyImage(orgId, facId, formData)
    getReport(date, true)
    tog_standard()
  }

  const deleteImage = (e, img) => {
    e.stopPropagation()

    const { orgId, _id: facId } = props.selectedFactory
    // console.log("imgimgimg", img, orgId, facId)
    let data = {
      date: date,
      imageId: img._id,
    }
    backend
      .deleteHealthAndSaftyImage(orgId, facId, data)
      .then(() => {
        console.log("Sucess")
        getReport(date, true)
        // getDailyMonitoringReport(true)
      })
      .catch(() => {
        console.log("error in delete")
      })
  }

  // console.log("reportjhsdhsbdhsj", reports, departments, images)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <input
              className="form-control"
              type="month"
              onChange={e => {
                console.log("e", e.target.value)
                setDate(e.target.value)
                getReport(e.target.value)
              }}
              style={{ width: "20%" }}
              value={date}
              id="example-month-input"
            />
          </div>
          <Row>
            <Col md={4}>
              {submitedBy && submitedBy.name && (
                <span style={{ display: "flex" }}>
                  <h4>Submit By :</h4>{" "}
                  <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                    {submitedBy.name}
                  </p>{" "}
                </span>
              )}
              {/* {submitedBy && (
                <Button
                  onClick={handlePrint}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                  color="success"
                >
                  Print
                </Button>
              )} */}
            </Col>
          </Row>
          <div style={{ marginLeft: "0px", display: 'flex', flex: 1, flexDirection: "row" }}>
            {reports.length > 0 && (
              <div style={{ display: "flex", marginBottom: "20px", marginRight: "2px", }}>
                <Button onClick={submit} color="success">
                  Submit
                </Button>
              </div>
            )}

            {submitedBy && submitedBy.id && (
              <Button
                onClick={handlePrint}
                style={{
                  marginLeft: "2px",
                  marginRight: "2px",
                  marginBottom: "20px",
                }}
                color="success"
              >
                Print
              </Button>
            )}
            {reports.length > 0 && (
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <Button onClick={saveHandS} color="success">
                  Save
                </Button>
              </div>
            )}
          </div>
          {submitedBy && submitedBy.name && (
            <div style={{ display: "none" }}>
              <ReportPrint
                date={date}
                ref={componentRef}
                images={images}
                reports={reports}
                selectedFactory={props.selectedFactory}
                values={values}
                submitedBy={submitedBy}
                departments={departments}
                reportName={`Onsite Inspection`}
              />
            </div>
          )}
          {reports.length > 0 && (
            <React.Fragment>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Onsite Inspection</CardTitle>
                      {/* <CardSubtitle className="mb-3">
                    Use one of two modifier classNamees to make{" "}
                    <code>&lt;thead&gt;</code>s appear light or dark gray.
                  </CardSubtitle> */}

                      <div className="table-responsive">
                        <Table className="table mb-0 table-bordered table">
                          <thead className="thead-light">
                            <tr>
                              <th>Check Points</th>
                              {departments.map((department: any, key: any) => {
                                return <th key={key}>{department.name}</th>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {reports.map((report: any, key: any) => {
                              return (
                                <React.Fragment key={key}>
                                  <tr
                                    className="table-info"
                                    style={{ textAlign: "center" }}
                                  >
                                    <th colSpan={12 + 1} scope="row">
                                      {report.name}
                                    </th>
                                  </tr>
                                  {report.lists.map((que, index) => {
                                    // console.log("que123456", que)

                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <th scope="row">{que.name}</th>

                                          {departments.map((dept: any, index: any) => {
                                            let specificValue = _.find(
                                              values,
                                              value => {
                                                return (
                                                  value.groupId ===
                                                  report._id &&
                                                  value.questionId ===
                                                  que._id &&
                                                  value.departmentId ===
                                                  dept._id
                                                )
                                              }
                                            )

                                            return (
                                              <React.Fragment key={index}>
                                                {que.type === "select" ? (
                                                  <td>
                                                    <select
                                                      onChange={e => {
                                                        setValue(
                                                          report._id,
                                                          que._id,
                                                          dept._id,
                                                          que.type,
                                                          e.target.value
                                                        )
                                                        console.log(
                                                          "dept id",
                                                          dept
                                                        )
                                                        console.log(
                                                          "question id",
                                                          que
                                                        )
                                                        console.log(
                                                          "group id",
                                                          report
                                                        )
                                                      }}
                                                      //   setValue(oId, idx, e)
                                                      // }
                                                      value={
                                                        specificValue &&
                                                          specificValue.value
                                                          ? specificValue.value
                                                          : ""
                                                      }

                                                      id="ddlCreditCardType"
                                                      name="ddlCreditCardType"
                                                      className="form-control"
                                                    >
                                                      <option value=""></option>
                                                      <option value="yes">
                                                        Yes
                                                      </option>
                                                      <option value="no">
                                                        No
                                                      </option>
                                                      <option value="n/a">
                                                        N/A
                                                      </option>
                                                    </select>
                                                    {specificValue &&
                                                      specificValue.value ===
                                                      "no" && (
                                                        <button
                                                          onClick={e => {
                                                            setmodal_data({
                                                              departmentId:
                                                                dept._id,
                                                              groupId:
                                                                report._id,
                                                              questionId:
                                                                que._id,
                                                            })
                                                            setformModal_data(
                                                              {}
                                                            )
                                                            tog_standard()
                                                          }}
                                                          style={{
                                                            padding: "2px 20px",
                                                            margin: "6px 0px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-danger waves-effect waves-light"
                                                        >
                                                          <i className="mdi mdi-upload d-block font-size-16"></i>{" "}
                                                        </button>
                                                      )}
                                                  </td>
                                                ) : (
                                                  <td>
                                                    <Input
                                                      onChange={e => {
                                                        setValue(
                                                          report._id,
                                                          que._id,
                                                          dept._id,
                                                          que.type,
                                                          e.target.value
                                                        )
                                                      }}
                                                      value={
                                                        specificValue &&
                                                          specificValue.value
                                                          ? specificValue.value
                                                          : ""
                                                      }
                                                      type="text"
                                                      className="form-control"
                                                      id="ddlCreditCardType"
                                                      name="ddlCreditCardType"
                                                    ></Input>
                                                  </td>
                                                )}
                                              </React.Fragment>
                                            )
                                          })}
                                        </tr>
                                      </React.Fragment>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {images.length > 0 && (
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardTitle style={{ paddingLeft: "8px" }}>
                          Remarks (Enclose Evidences)
                        </CardTitle>
                        <div
                          className="popup-gallery"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {departments &&
                            departments.map((depart: any, key: any) => {
                              const find1 = _.find(images, {
                                departmentId: depart._id,
                              })
                              // console.log("departdepartdepart12345",find1 && find1.departmentId===depart._id,find1 ,depart._id)
                              return (
                                <React.Fragment key={key}>
                                  <div>
                                    {images.length > 0 && find1 && (
                                      <div
                                        style={{ paddingLeft: "8px" }}
                                        className="mt-0 card-title"
                                      >
                                        {depart.name}
                                      </div>
                                    )}
                                    {images &&
                                      images.map((image, key) => {
                                        // console.log(
                                        //   "imageimages",
                                        //   image,
                                        //   images
                                        // )
                                        const find = _.find([image], {
                                          departmentId: depart._id,
                                        })
                                        // console.log(find)
                                        if (find) {
                                          let data = _.find(values, value => {
                                            return (
                                              value.groupId === find.groupId &&
                                              value.questionId ===
                                              find.questionId &&
                                              value.departmentId ===
                                              find.departmentId
                                            )
                                          })
                                          // console.log("datajsbdbhdf", data)
                                          // if (data) {
                                          return (
                                            <div
                                              className="img-fluid float-left"
                                              style={{
                                                padding: "10px",
                                                margin: "12px",
                                                height: "245px",
                                                width: "180px",
                                                backgroundColor:
                                                  "rgb(250, 250, 250)",
                                                border: "1px dashed black",
                                              }}
                                              key={key}
                                            >
                                              {find.img && (
                                                <React.Fragment>
                                                  <div
                                                    onClick={e => {
                                                      deleteImage(e, image)
                                                    }}
                                                    style={{
                                                      float: "right",
                                                      margin: "-2px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i className="mdi mdi-close-thick"></i>
                                                  </div>
                                                  <img
                                                    width="150px"
                                                    height="150px"
                                                    src={find.img.id ? fileUrl(find.img.id) : `data:${find.img.contentType};base64, ${find.img.data}`}
                                                    onClick={() => {
                                                      // setisGallery(true)
                                                      // setphotoIndex(0)
                                                    }}
                                                    alt=""
                                                  // width="120"
                                                  />
                                                  {find.desc && (
                                                    <p
                                                      style={{
                                                        overflow: "auto",
                                                        height: "10vh",
                                                      }}
                                                    >
                                                      {" "}
                                                      {find.desc}{" "}
                                                    </p>
                                                  )}
                                                </React.Fragment>
                                              )}
                                            </div>
                                          )
                                          // }
                                        }
                                      })}
                                  </div>
                                </React.Fragment>
                              )
                            })}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}
        </Container>

        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {"Upload Documents"}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={event =>
                  setformModal_data({
                    ...formModal_data,
                    desc: event.target.value,
                  })
                }
                placeholder="description"
              />
            </div>
            <div className="custom-file">
              <input
                type="file"
                multiple
                accept="image/jpeg, image/png"
                className="custom-file-input"
                id="customFile"
                onChange={event => {
                  setformModal_data({
                    ...formModal_data,
                    file: event.target.files,
                  })
                }}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {formModal_data && formModal_data.file
                  ? formModal_data.file[0].name
                  : "Choose file"}
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => {
                uploadImage()
              }}
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

// export default

const mapStateToProps = (state, props) => {
  // console.log("state.factory", state.factory, state)
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
    factories: state.factory.factories,
    savedHealthAndSafety: state.healthAndSafety.saveHealthAndSafety,
  }
}

export default withRouter(
  connect(mapStateToProps, { saveHealthAndSafety, removeHealthAndSafety })(HealthAndSaftyReport)
)
