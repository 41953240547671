import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as backend from "../../helpers/backend_helper"
import DeleteAlert from "../../common/ui/customAlert"
import Toaster from "../../components/Common/Toaster"
import _ from "lodash";
import Select from "react-select"
import { pagesPermissions } from "common/SidementContent"


const totalTeams = 5

const FactoryTeams = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
    },
  } = props
  const [teams, setTeams] = useState<any>([{ userId: "", name: null, role: null }])
  const [userList, setuserList] = useState<any>([])
  const [count, setCount] = useState(true)
  const [updateTeams, setupdateTeams] = useState(true)
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  function handleAddRowNested() {
    const item = { userId: "", name: null, role: null }
    if (teams.length < totalTeams) setTeams([...teams, item])
  }

  async function handleRemoveRowNested(e: any, idx: any) {
    try {
      const clone = [...teams]
      if (clone[idx].userId) {
        clone[idx].status = !clone[idx].status;
        await updateUserActivityRole(clone[idx].userId, clone[idx].status)
      }
      // clone.splice(idx, 1)
      setTeams([...clone])
      await submit()
    } catch (e) {
      console.log(e)
    }
  }

  async function inputTeamUpdate(value: any, key: any, idx: any) {
    try {
      const clone = [...teams]
      if (clone[idx][key]) {
        await removeUser(clone[idx][key])
        Toaster.warning("User Removed")
      }
      clone[idx][key] = value
      await adduser(value)
      Toaster.success("User Added")
      const find = _.find(userList, ["_id", value])
      if (find) {
        clone[idx].role = find.role
        clone[idx].name = find.name
      }
      setTeams([...clone])
      await submit()
    } catch (e) {
      console.log(e)
      Toaster.error("Something went wrong")
    }
  }

  async function removeUser(userId: any) {
    console.log("remove user ==> ", userId)
    await backend.removeUserFactory(orgId, facId, userId)
  }

  async function updateUserActivityRole(userId: any, status: any) {
    await backend.updateUserActivityRole(orgId, facId, userId, status);
  }

  async function updateUserPermissions(permissions: any, userId: any) {
    try {
      const clone = [...teams]
      const _find = _.findIndex(clone, ['userId', userId]);
      if (_find > -1) {
        clone[_find].permissions = permissions;
      }
      setTeams([...clone]);
      let data = await adduser(userId, _.map(permissions, 'value'));
    } catch (err) {

    }
  }

  async function adduser(userId: any, permissions?: any) {
    await backend.addUserFactory(orgId, facId, userId, permissions)
  }

  async function submit() {
    const userIds = _.map(teams, o => {
      return { userId: o.userId, status: o.status }
    })
    try {
      await backend.updateFactory(orgId, facId, { teams: userIds })
      props.update()
      Toaster.success("Team Update Success")
    } catch (e) {
      console.log("Team update ==>", e)
      Toaster.error("Team Update Failed")
    }
  }
  // update teams
  useEffect(() => {
    if (props.teams && userList.length > 0 && updateTeams) {
      const newuserList: any = []
      _.each(props.teams, (team) => {
        const find = _.find(userList, ["_id", team.userId])
        if (find) {
          const _permission = _.map(pagesPermissions, (item) => {
            if (team.permissions && team.permissions.includes(item.value)) {
              return item;
            }
            return null;
          }).filter(i => i);
          newuserList.push({ userId: team.userId, name: find.name, status: team.status, role: find.role, permissions: _permission })
        }
      })
      if (newuserList.length > 0) setTeams(newuserList)
      setupdateTeams(false)
    }
  }, [props.teams, userList, updateTeams])

  async function getUsers() {
    try {
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) { }
  }

  useEffect(() => {
    if (count) {
      getUsers()
      setCount(false)
    }
  }, [count])

  function OptionBuilder(idx: any) {
    const newuserList: any = []
    const filterTeams = _.map(teams, (o, index) => {
      if (index != idx) {
        return o.userId
      } else return null
    }).filter(o => o != null && o != "")
    _.each(userList, user => {
      const find = filterTeams.includes(user["_id"])
      if (!find) newuserList.push(user)
    })
    return newuserList
  }

  // on onDelete
  const onDelete = async (e: any, deleteId: any) => {
    e.stopPropagation()
    setDeleteId(deleteId)
    setTimeout(() => {
      Submit(deleteId);
    }, 100)
    // toggleDeleteAlert()
  }

  const Submit = (deleteId) => {
    // console.log("deleteIddeleteIddeleteId", deleteId);

    handleRemoveRowNested(null, deleteId)
    // handleRemoveRowNested(null, deleteId)
    // toggleDeleteAlert()
  }

  const toggleDeleteAlert = () => {
    setDeleteAlertVisible(!deleteAlertVisible)
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Form className="outer-repeater">
                  <div className="inner-repeater mb-4">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr id="addrMain">
                          <td>
                            <Row
                              className="mb-2"
                              style={{ textAlign: "center" }}
                            >
                              <Col md="3">
                                <h5>User</h5>
                              </Col>
                              <Col md="2">
                                <h5>Name</h5>
                              </Col>
                              <Col md="2">
                                <h5>Role</h5>
                              </Col>
                              <Col md="3">
                                <h5>Permissions</h5>
                              </Col>
                              <Col md="2">
                                <h5>Status</h5>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                        {teams.map((team: any, idx: any) => (
                          <tr id="addrMain" key={idx}>
                            <td>
                              <Row className="mb-2">
                                <Col md="3">
                                  <Input
                                    onChange={e =>
                                      inputTeamUpdate(
                                        e.target.value,
                                        "userId",
                                        idx
                                      )
                                    }
                                    value={team.userId}
                                    type="select"
                                    name="selectMulti"
                                    id="exampleSelectMulti"
                                  >
                                    <option></option>
                                    {OptionBuilder(idx).map((user: any, idu: any) => (
                                      <option key={idu} value={user["_id"]}>
                                        {user.email}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                                <Col md="2" style={{ textAlign: "center" }}>
                                  <span>{team.name}</span>
                                </Col>
                                <Col md="2" style={{ textAlign: "center" }}>
                                  <span>{team.role}</span>
                                </Col>
                                <Col md="3" style={{ textAlign: "center" }}>
                                  <Select
                                    value={team.permissions}
                                    isMulti={true}
                                    onChange={(e) => {
                                      // console.log(e)
                                      updateUserPermissions(
                                        e, team.userId
                                      )
                                    }}
                                    options={pagesPermissions}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col md="2" style={{ display: "flex", justifyContent: "center" }}>
                                  {
                                    <div className="form-check form-switch form-switch-lg mb-3">
                                      <input type="checkbox"
                                        defaultChecked={team.status}
                                        onClick={e => {
                                          onDelete(e, idx)
                                        }} className="form-check-input" id="customSwitchsizelg" />
                                    </div>
                                    // <Button
                                    //   color="primary"
                                    //   className="btn-block inner"
                                    //   style={{ width: "100%" }}
                                    //   onClick={e => {
                                    //     onDelete(e, idx)
                                    //   }}
                                    // >
                                    //   {" "}
                                    //   Delete{" "}
                                    // </Button>
                                  }
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {teams.length < totalTeams && (
                      <Button
                        onClick={() => {
                          handleAddRowNested()
                        }}
                        color="success"
                        className="mt-1"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="primary">
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DeleteAlert
          visible={deleteAlertVisible}
          Title={"Delete Team"}
          Message={"Do you Want to Delete Team?"}
          toggleAlert={toggleDeleteAlert}
          Submit={Submit}
        />
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {}
}

export default withRouter<any, any>(connect(mapStateToProps, {})(FactoryTeams))
