import { SAVE_DAILY_MONITORING_REPORT } from './actionTypes'

const initialState = {
    dailymonitroing: {
    }
}

const dailymonitroing = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DAILY_MONITORING_REPORT:
      state = {
        ...state,
        dailymonitroing: {...state.dailymonitroing, [action.payload.key]: action.payload.value},
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default dailymonitroing
