import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Input, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import * as backend from "../../helpers/backend_helper"
import EditableColumn from "./EditableColumn"
import Toaster from "../../components/Common/Toaster"
import DeleteAlert from "../../common/ui/customAlert"


const DailyMonitoring = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
    },
  } = props

  const [daily, setDaily] = useState<any[]>([])
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false)
  const [deleteData, setdeleteData] = useState<any>({})

  // add group
  function addGroup() {
    setDaily([...daily, { name: null, lists: [] }])
  }
  // remove group
  function removeGroup(idx) {
    const cloneList = [...daily]
    cloneList.splice(idx, 1)
    setDaily([...cloneList])
  }

  function addSubList(id) {
    const cloneList = [...daily]
    cloneList[id].lists = [
      ...cloneList[id].lists,
      { name: null, type: "select" },
    ]
    setDaily([...cloneList])
  }

  function updateGroupValue(e, idx) {
    const cloneList = [...daily]
    cloneList[idx].name = e
    setDaily([...cloneList])
  }

  function updateListValue(e, key, idx, liIndex) {
    const cloneList = [...daily]
    const list = cloneList[idx].lists[liIndex]
    if (list) {
      list[key] = e
    }
    setDaily([...cloneList])
  }

  //deleteListValue
  function deleteListValue(idx, liIndex) {
    const cloneList = [...daily]
    const list = cloneList[idx].lists
    list.splice(liIndex, 1)
    setDaily([...cloneList])
  }

  // on onDelete
  const onDelete = async (id, index) => {
    setdeleteData({id:id,index:index})
    // setDeleteId(deleteId)
    toggleDeleteAlert()
  }

  const Submit = () => {
    deleteListValue(deleteData.id,deleteData.index)
    toggleDeleteAlert()
  }

  const toggleDeleteAlert = () => {
    setDeleteAlertVisible(!deleteAlertVisible)
  }


  useEffect(() => {
    setDaily(props.form)
  }, [props.form])

  // on submit
  async function submit() {
    try {
      await backend.updateFactory(orgId, facId, { dailyMonitoringForm: daily })
      props.update()
      Toaster.success("Daily Monitoring Updated Success")
    } catch (e) {
      Toaster.error("Daily Monitoring Updated Failed")
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>Daily Monitoring</h4>
          <button
            onClick={addGroup}
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            New Group
          </button>
        </div>
        <br />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <ul
                  className="list-unstyled"
                  // style={{ height: "65vh", overflow: "auto" }}
                >
                  {daily.map((daily, idd) => (
                    <li key={idd}>
                      <div
                        className="factory-monitoring-group-list"
                        style={{ textAlign: "start" }}
                      >
                        <Row>
                          <Col md={10}>
                            <EditableColumn
                              value={daily.name}
                              update={e => updateGroupValue(e, idd)}
                            />
                          </Col>
                          <Col md={1}>
                            <button
                              onClick={e => addSubList(idd)}
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              ADD
                            </button>
                          </Col>
                          <Col md={1}>
                            <button
                              onClick={e => removeGroup(idd)}
                              type="button"
                              className="btn btn-danger waves-effect waves-light"
                            >
                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <ul className="list-unstyled">
                        {daily.lists.map((sublist, subIndex) => (
                          <li key={subIndex} style={{ padding: "10px" }}>
                            <Row>
                              <Col lg={6}>
                                <EditableColumn
                                  value={sublist.name}
                                  update={e =>
                                    updateListValue(e, "name", idd, subIndex)
                                  }
                                />
                              </Col>
                              <Col lg={5}>
                                <Input
                                  onChange={e =>
                                    updateListValue(
                                      e.target.value,
                                      "type",
                                      idd,
                                      subIndex
                                    )
                                  }
                                  value={sublist.type}
                                  type="select"
                                  name="selectMulti"
                                  id="exampleSelectMulti"
                                >
                                  <option></option>
                                  <option value="input"> Input </option>
                                  <option value="select"> Select </option>
                                </Input>
                              </Col>
                              <Col lg={1}>
                                <button
                                  onClick={e => onDelete(idd, subIndex)}
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                </button>
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                {daily.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="success">
                      Submit
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DeleteAlert
          visible={deleteAlertVisible}
          Title={"Delete Form"}
          Message={"Do you Want to Delete?"}
          toggleAlert={toggleDeleteAlert}
          Submit={Submit}
        />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

export default withRouter<any, any>(connect<any>(mapStateToProps, {})(DailyMonitoring))
