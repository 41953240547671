import _ from "lodash";

export const SidementContent = [
    // {
    //     label: 'Dashboards',
    //     icon: 'bx bxs-dashboard',
    //     path: '#'
    // }, 
    {
        label: 'Organization',
        icon: 'bx bx-user',
        path: '/orgs/list'
    },
    {
        label: 'Daily Monitoring',
        icon: 'bx bx-calendar-alt',
        key: 'daily',
        list: [
            {
                label: 'Onsite Inspection',
                key: 'Daily_onSiteInspection',
                path: '/daily-monitoring'
            },
            {
                label: 'Work Report',
                key: 'Daily_work_report',
                path: '/daily-work-report'
            }
        ]
    },
    {
        label: 'Monthly Monitoring',
        icon: 'bx bx-calendar-event',
        key: 'monthly',
        list: [
            {
                label: 'Onsite Inspection',
                dynamic: 'healthSafetyName',
                key: 'Monthly_onSiteInspection',
                path: '/Health-Safety'
            },
            {
                label: 'Document Review',
                key: 'Monthly_Document_Review',
                dynamic: 'statutoryName',
                path: '/statutory-report'
            },
            {
                label: 'Work Report',
                key: 'Monthly_work_report',
                path: `/monthly-monitoring`
            },
           
        ]
    },
    // {
    //     label: 'Performance Review',
    //     key: 'Performance_Review',
    //     path: '/monthly-monitoring/performanceReview',
    //     icon: 'bx bx-line-chart'
    // },
    {
        label: 'Performance Score',
        path: '/performance-score',
        icon: 'bx bxs-pie-chart-alt-2'
    }
]

export const pagesPermissions = [
    { label: "Daily - Onsite Inspection", value: 'Daily_onSiteInspection' },
    { label: "Daily - Work report", value: 'Daily_work_report' },
    { label: "Monthly - Onsite Inspection", value: 'Monthly_onSiteInspection' },
    { label: "Monthly - Work report", value: 'Monthly_work_report' },
    { label: "Monthly - Document Review", value: 'Monthly_Document_Review' },
    { label: "Performance Review", value: 'Performance_Review' }
]

export const sidementfilter = (permissions: string[]) => {
    let _localFilter: any[] = [];
    if (permissions && !permissions.includes('ADMIN')) {
        _.each(SidementContent, (content) => {
            if (content.list && content.list.length > 0) {
                const _local: any[] = [];
                _.each(content.list, (value, key) => {
                    if (permissions && permissions.includes(value.key)) {
                        _local.push(value);
                    }
                })
                if (_local.length > 0) {
                    _localFilter.push({
                        ...content,
                        list: _local
                    });
                }
            } else {
                if (content.key === 'Performance_Review') {
                    if (permissions && permissions.includes('Performance_Review')) {
                        _localFilter.push(content);
                    }
                } else {
                    _localFilter.push(content);
                }
            }
        })
    } else {
        _localFilter = SidementContent;
    }


    return _localFilter
}