import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment"

interface Init {
    minDate: string;
    currentDate: string;
    months: string[];
}

const initialState: Init = {
    minDate: moment().format('YYYY-MM'),
    currentDate: moment().format('YYYY-MM'),
    months: []
}


export const dailymontoringSlice = createSlice({
    name: 'dailymontoringnew',
    initialState: initialState,
    reducers: {
        setMinDate: (state, payload: PayloadAction<string>) => {
            state.minDate = moment(payload.payload).format('YYYY-MM');
            state.currentDate = moment(payload.payload).format('YYYY-MM');
            const month = moment(payload.payload).month();
            var m = moment();
            let months: any[] = []
            for (var i = month; i < 12; i++) {
                months.push(m.month(i).format('MMM'))
            }
            state.months = months;
        },
        setCurrentDate: (state, payload: PayloadAction<string>) => {
            state.currentDate = payload.payload;
            const month = moment(state.minDate, 'YYYY-MM').month();
            const isyearSame = moment(payload.payload, 'YYYY-MM').year() == moment(state.minDate, 'YYYY-MM').year()
            var m = moment();
            let months: any[] = []
            for (var i = isyearSame ? month : 0; i < 12; i++) {
                months.push(m.month(i).format('MMM'))
            }
            state.months = months;
        }
    }
})


export const { setMinDate, setCurrentDate } = dailymontoringSlice.actions

export default dailymontoringSlice.reducer