import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import * as backend from "../../helpers/backend_helper";


const initialState = {
    isLoggedIn: false,
    user: null,
}

// First, create the thunk
export const fetchCurrentuser = createAsyncThunk(
    'auth/getuser',
    async () => {
        const response = await backend.getUser(); 
        return response
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setIsloggedIn: (state, payload: PayloadAction<boolean>) => {
            state.isLoggedIn = payload.payload;
        },
        setUser: (state, payload: PayloadAction<any>) => {
            state.user = payload.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchCurrentuser.fulfilled, (state, action) => {
            state.user = action.payload;
        })
    }
})


export const { setIsloggedIn, setUser } = authSlice.actions

export default authSlice.reducer