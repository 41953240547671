import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import * as _ from "lodash"
import moment from "moment"
import { withRouter } from "react-router-dom"

import * as func from '../../common/functions'

const PerformanceMonitoring = (props: any) => {
  const year = moment().format("YYYY")
  const [count, setCount] = useState(true)
  const [performanceReportaggre, setperformanceReportaggre] = useState({})

  function navigate(month) {
    const monthNumber = moment().month(month).format("MM")
    props.history.push(`/monthly-monitoring/${year}/${monthNumber}`)
  }

  // get usersList
  useEffect(() => {
      const finalreport = func.performanceReportMonthly(props.performanceReport, props.teams, year)
      props.updateReport(finalreport)
      setperformanceReportaggre(finalreport)
  }, [props.performanceReport])
  return (
    <React.Fragment>
      <tr className="table-warning" style={{ textAlign: "center" }}>
        <th  colSpan={12 + 1} scope="row">
          {/* Performance Monitoring */}
          Daily Reporting 
        </th>
      </tr>
      {props.teams &&
        props.teams.map((team, teamIndex) => {
          const find = _.find(props.userList, ["_id", team.userId])
          const report = performanceReportaggre[team.userId]
          const style: any = { textAlign: "center"  }
          if (find)
            return (
              <tr key={teamIndex}>
                <th scope="row" style={teamIndex === 0 ? style : {}}>
                  {find.name} ({find.role})
                  {/* {teamIndex === 0 ? (
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "green",
                      }}
                    >
                      Report
                    </button>
                  ) : (
                    ""
                  )} */}
                </th>
                {props.months.map((month, mIndex) => {
                  const monthNumber = moment(month, "YYYY-MMM").format("MM")
                  const date = `${year}-${monthNumber}`
                  let value = 0
                  if (report) {
                    value = report[date] ? report[date] : 0
                  }
                  return (
                    <td
                      key={mIndex}
                      style={{ cursor: "pointer" }}
                      onClick={e => navigate(month)}
                    >
                      {Math.ceil(value)}
                    </td>
                  )
                })}
              </tr>
            )
        })}
    </React.Fragment>
  )
}

export default withRouter(PerformanceMonitoring)
