import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"


//redux
import { useSelector, useDispatch } from "react-redux"
import { resetFactoryies } from "store/slice/factory.slice"

const Logout = (props: any) => {
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.removeItem('token');
    props.history.push('/login');
    dispatch(resetFactoryies())
  }, [dispatch])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
