import React from 'react';
import { Container } from 'reactstrap';
import logo1 from "assets/images/1.png"
import logo2 from "assets/images/2.png"
import logo3 from "assets/images/3.png"

export default function About() {

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <div className="container" style={{ paddingTop: '5%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <img src={logo1} style={{ height: 300, width: 600 }} />
                        <div>
                            <h2>Purpose</h2>
                            <p style={{ fontSize: '14px' }}>Online tool for factories to effectively manage their Social compliance team.</p>
                        </div>
                    </div>
                    <div className="container" style={{ paddingTop: '5%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <img src={logo2} style={{ height: 300, width: 600 }} />
                        <div>
                            <h2>How</h2>
                            <p style={{ fontSize: '14px' }}>Assign Job descriptions and monitoring checkpoints for the team members.</p>
                            <p style={{ fontSize: '14px' }}>Facilitate them to report through the online tool as assigned. </p>
                            <p style={{ fontSize: '14px' }}>Evaluate their performances anytime.</p>
                        </div>
                    </div>
                    <div className="container" style={{ paddingTop: '5%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <img src={logo3} style={{ height: 300, width: 600 }} />
                        <div>
                            <h2>Result</h2>
                            <p style={{ fontSize: '14px' }}>Evalutae and identify areas to improve your team performance.</p>
                            <p style={{ fontSize: '14px' }}>Check whether your team have completed their tasks within the scheduled time.</p>
                            <p style={{ fontSize: '14px' }}>Manage your team efficiently by tracking their time spent on designated tasks.</p>
                            <p style={{ fontSize: '14px' }}>Know your team{"'"}s preparedness for any suprise/ unannounced audits and inspections.</p>
                            <p style={{ fontSize: '14px' }}>Review whether your team gets support from other departments  to implement and manage systems.</p>
                        </div>
                    </div>
                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h1 style={{ color: '#495057' }}>Sustain</h1>
                        <h4 className="text-muted">Social Compliance Management</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 30, justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ color: '#495057' }}>Purpose</h1>
                            <h4 className="text-muted">To ensure HR & Compliance activities are executed by the team effectively & efficiently.</h4>
                        </div>
                        <div>
                            <h1 style={{ color: '#495057' }}>How</h1>
                            <h4 className="text-muted">All the team members are allotted clear job descriptions & monitoring checkpoints are deployed to self-assess. </h4>
                        </div>
                        <div>
                            <h1 style={{ color: '#495057' }}>Stages</h1>
                            <ul>
                                <li>
                                    <h5 className="text-muted">Team Job Allocation</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Set Monitoring Checkpoints</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Evalutae Performance Score</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 30, justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ color: '#495057' }}>Checkpoints</h1>
                            <ul>
                                <li>
                                    <h5 className="text-muted">Daily Work Report </h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Daily Monitoring Report</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Monthly Inspection Report</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Monthly Monitoring Report</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Performance Score</h5>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h1 style={{ color: '#495057' }}>Result</h1>
                            <ul>
                                <li>
                                    <h5 className="text-muted">Know your Compliance Status Anytime</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Evalutae & Improve HR Team Performance</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Track Time Spent on Compliance Activities</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Analyse Production Team Support</h5>
                                </li>
                                <li>
                                    <h5 className="text-muted">Prepared for Unannounced Buyer Audits</h5>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </Container>
            </div>
        </React.Fragment>
    );
}