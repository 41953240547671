import React, { useState, useEffect } from "react"
import { Container, Row, Card, CardBody, Col, Table } from "reactstrap"
import moment from "moment"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import MonthlyReportGroupSummary from "./MonthlyReportGroupSummary"
import * as func from "../../common/functions"
import _ from "lodash"
import store, { useAppSelector } from "store"
import { setMinDate, setCurrentDate } from "store/slice/dailymonitoring.slice"

const MonthlyReport = (props: any) => {
  const dispatch = useDispatch();
  const date = useAppSelector(state => state.dailymontoringSlice);
  const [first, setFirst] = useState(true)
  const [reportList, setReportList] = useState([])
  const [reportMerge, setReportMerge] = useState([])
  const [totalQuestionInDepart, setTotalQuestionInDepart] = useState<any>()
  const year = moment(date.currentDate, 'YYYY-MM').format("YYYY")
  const months = moment.monthsShort()
  const monthTC = date.months.length * 100

  function dayReportNavigate(month: any) {
    if (date.months.includes(month)) {
      const monthNumber = moment(date.currentDate, 'YYYY-MM').month(month).format("MM")
      props.history.push(`/daily-monitoring/${year}/${monthNumber}`)
    }
  }

  async function getMonthReport() {
    try {
      const startDate = `${year}-${"01"}-${'01'}`
      const endDate = `${year}-${12}-${31}`
      const { orgId, _id: facId } = props.selectedFactory
      const response = await backend.getdailymonitoringMonthReport(
        orgId,
        facId,
        startDate,
        endDate
      )

      const mergeReports = [].concat.apply([], _.map(response, "report"))
      setReportMerge(mergeReports)
      setReportList(response)
    } catch (e) {
      console.log(e)
    }
  }

  // getday report
  useEffect(() => {
    if (first && props.selectedFactory) {
      getMonthReport();
      dispatch(setMinDate(props.selectedFactory.createdAt))
      setFirst(false)
      let data = func.getTotalQuestionsindailyMonitoringReport(
        props.selectedFactory.dailyMonitoringForm,
        props.selectedFactory.departments
      )
      setTotalQuestionInDepart(data)
    }
  }, [props.selectedFactory, first])

  if (!props.selectedFactory) {
    return <></>
  }

  let FinalScoreObject: any = {}


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}>
                      <input
                        className="form-control"
                        type="month"
                        onChange={e => {
                          dispatch(setCurrentDate(e.target.value))
                        }}
                        style={{ width: "auto" }}
                        min={moment(date.minDate, 'YYYY-MM').format("YYYY-MM")}
                        value={moment(date.currentDate, 'YYYY-MM').format("YYYY-MM")}
                        id="example-month-input"
                      />
                    </div>
                    <Table
                      className="table table-hover mb-0 table-striped table-bordered"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr className="table-success">
                          <th rowSpan={2}>Department</th>
                          <th
                            style={{ textAlign: "center" }}
                            colSpan={months.length}
                          >
                            Daily Monitoring Report Summary - {year}
                          </th>
                          <th rowSpan={2}>Average Score</th>
                        </tr>
                        <tr className="table-success">
                          {months.map((m, mIndex) => (
                            <th
                              style={{ cursor: "pointer", opacity: (date.months.includes(m)) ? 1 : .5 }}
                              onClick={e => dayReportNavigate(m)}
                              key={mIndex}
                            >
                              {m}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          props.selectedFactory.departments.map(
                            (depart: any, deIndex: number) => {
                              let totalScore = 0
                              // const filter = _.filter(reportMerge, {
                              //   departmentId: depart["_id"],
                              //   type: "select",
                              // }).map(f =>
                              //   f.value ? Number(f.value.split(":")[1]) : 0
                              // )
                              // totalScore = _.sum(filter)

                              return (
                                <tr key={deIndex}>
                                  <th scope="row">{depart.name}</th>
                                  {months.map((m, mIndex) => {
                                    let value = 0
                                    const monthNumber = moment()
                                      .month(m)
                                      .format("MM")

                                    const daysNumber = moment(
                                      `${year}-${monthNumber}`,
                                      "YYYY-MM"
                                    ).daysInMonth()

                                    let totalSelectnumber = 0
                                    if (totalQuestionInDepart) {
                                      totalSelectnumber =
                                        totalQuestionInDepart[depart["_id"]]
                                          .total
                                    }

                                    const find = _.filter(reportList, (o: any) => {
                                      return (
                                        moment(o.date, "YYYY-MM-DD").format(
                                          "YYYY-MM"
                                        ) == `${year}-${monthNumber}`
                                      )
                                    })
                                    if (find.length > 0) {
                                      const report = [].concat.apply(
                                        [],
                                        _.map(find, "report")
                                      )
                                      const filter = _.filter(report, {
                                        departmentId: depart["_id"],
                                        type: "select",
                                      }).map((f: any) =>
                                        f.value
                                          ? Number(f.value.split(":")[1])
                                          : 0
                                      )
                                      // value = _.sum(filter)

                                      let sumValue = _.sum(filter)
                                      const reportDayCount: any[] = reportList.filter((item: any) => {
                                        return !item.remarks && moment(item.date, 'YYYY-MM-DD').format('MM') == monthNumber
                                      });
                                      // daysNumber
                                      let byValue =
                                      reportDayCount.length * totalSelectnumber
                                      let _v = Math.round(
                                        (sumValue / byValue) * 100
                                      )
                                      value = (_v) ? _v : 0

                                      FinalScoreObject[depart.name] = {
                                        ...FinalScoreObject[depart.name],
                                        [m]: (sumValue / byValue) * 100,
                                      }
                                    }

                                    totalScore = totalScore + value

                                    // console.log("FinalScoreObjectFinalScoreObjectFinalScoreObject",totalScore);
                                    return <th key={mIndex}>{value}</th>
                                  })}

                                  {(() => {
                                    FinalScoreObject[depart.name] = {
                                      ...FinalScoreObject[depart.name],
                                      finalScore: totalScore / monthTC * 100,
                                    }



                                    return <th>{Math.round(totalScore / monthTC * 100)}</th>
                                  })()}
                                </tr>
                              )
                            }
                          )}
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          [1].map((value, key) => {
                            let totalScore = 0
                            return (
                              <tr key={key}>
                                <th scope="row">Average Score</th>
                                {months.map((m, mIndex) => {
                                  let value = 0

                                  Object.keys(FinalScoreObject).map(keys => {


                                    if (FinalScoreObject[keys][m]) {
                                      value =
                                        value +
                                        Number(FinalScoreObject[keys][m])
                                    }
                                  })


                                  // const monthNumber = moment()
                                  //   .month(m)
                                  //   .format("MM")
                                  // const find = _.filter(reportList, o => {
                                  //   return (
                                  //     moment(o.date, "YYYY-MM-D").format(
                                  //       "YYYY-MM"
                                  //     ) == `${year}-${monthNumber}`
                                  //   )
                                  // })
                                  // if (find.length > 0) {
                                  //   const report = [].concat.apply(
                                  //     [],
                                  //     _.map(find, "report")
                                  //   )
                                  //   const filter = _.filter(report, {
                                  //     type: "select",
                                  //   }).map(f =>
                                  //     f.value
                                  //       ? Number(f.value.split(":")[1])
                                  //       : 0
                                  //   )
                                  //   value = _.sum(filter)
                                  // }
                                  // totalScore += value

                                  let NumberOfDepartments =
                                    props.selectedFactory.departments.length * 100
                                  let finalValue = Number((value / NumberOfDepartments) * 100)

                                  // console.log("valuevaluevaluevalue",value,finalValue,FinalScoreObject);


                                  return <th key={mIndex}>{Math.round(finalValue)}</th>
                                })}
                                {(() => {
                                  let NumberOfDepartments =
                                    props.selectedFactory.departments.length * 100
                                  Object.keys(FinalScoreObject).map(keys => {
                                    if (FinalScoreObject[keys].finalScore) {
                                      totalScore = totalScore + Number(FinalScoreObject[keys].finalScore)
                                    }
                                  })
                                  return <th>{Math.round((totalScore / NumberOfDepartments) * 100)}</th>
                                })()}
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* group view */}
          {/* {props.selectedFactory && reportList.length > 0 && (
            <MonthlyReportGroupSummary
              reportMerge={reportMerge}
              reportList={reportList}
              factories={props.selectedFactory}
              FinalScoreObject={FinalScoreObject}
            />
          )} */}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  const { selected: selectedFactory } = state.factory
  return { selectedFactory }
}

export default withRouter(connect(mapStateToProps, {})(MonthlyReport))
