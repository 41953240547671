import React, { useState, useEffect } from "react"
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Row,
  Col,
  Container,
} from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import FactoryDepartments from "./Departments" 
import FactoryTeams from "./Team"
import FactoryForms from "./Forms"
import * as backend from "../../helpers/backend_helper"
import Breadcrumb from "../../components/Common/Breadcrumb2"
import BackButton from "../../common/ui/backButton"
import LeftNavReport from "./Reports/Reports"

// Redux Store
import { setFactoryies } from "store/slice/factory.slice"

const FactoryDetails = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
      url: locationUrl,
    },
  } = props
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [factory, setFactory] = useState<any>()
  const [count, setCount] = useState(true)

  function toggleCustom(tab: any) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  async function getFactory() {
    try {
      const response = await backend.getFactoryByID(orgId, facId)
      props.saveUserFactory()
      setFactory(response[0])
    } catch (e) { }
  }

  useEffect(() => {
    if (count) {
      getFactory()
      setCount(false)
    }
  }, [count])
  // console.log("factoryfactoryfactoryfactory", factory)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={<BackButton history={props.history} />}
            breadcrumbItems={[
              { title: "Organization", to: "/orgs/list" },
              {
                title: "Factory",
                to:
                  locationUrl && locationUrl.split("factory")[0]
                    ? locationUrl.split("factory")[0] + "factory/list"
                    : "#",
              },
            ]}
          ></Breadcrumb>

          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1")
                }}
              >
                <span className="d-none d-sm-block">Departments</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-none d-sm-block">Teams</span>
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "4",
                })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-none d-sm-block">Statutory Report</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "5",
                })}
                onClick={() => {
                  toggleCustom("5")
                }}
              >
                <span className="d-none d-sm-block">
                  Health & Safety Report
                </span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2")
                }}
              >
                <span className="d-none d-sm-block">Daily Monitoring</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "4",
                })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-none d-sm-block">Monthly Monitoring</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <Row>
                <Col sm="12">
                  {factory && factory.departments && (
                    <FactoryDepartments
                      update={() => getFactory()}
                      departments={factory.departments}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Row>
                <Col sm="12">
                  {factory && customActiveTab == "2" && (
                    <FactoryForms
                      update={() => getFactory()}
                      factory={factory}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <Row>
                <Col sm="12">
                  {factory && factory.teams && (
                    <FactoryTeams
                      update={() => getFactory()}
                      teams={factory.teams}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4" className="p-3">
              <Row>
                <Col sm="12">
                  {factory && customActiveTab == "4" && factory.teams && (
                    <LeftNavReport
                      update={() => getFactory()}
                      factory={factory}
                      statutoryReport={factory.statutoryReport}
                      healthSafetyReport={factory.healthSafetyReport}
                      workReportScope={factory.workReportScope}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {}
}

export default withRouter<any, any>(
  connect(mapStateToProps, { saveUserFactory: setFactoryies })(FactoryDetails)
)
