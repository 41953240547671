import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Label,
    Button,
} from "reactstrap"
import { connect } from "react-redux"
import EditableColumn from "./EditableColumn"
import { withRouter } from "react-router-dom"
import Toaster from "../../components/Common/Toaster"
import * as backend from "../../helpers/backend_helper"

const WorkReportScope = (props: any) => {
    const {
        match: {
            params: { orgId, facId },
        },
    } = props

    const [reports, setReports] = useState<any[]>([])

    // add group
    function addGroup() {
        setReports([...reports, { name: null, lists: [] }])
    }

    useEffect(() => {
        if(props.factory.workReportScope)
        setReports(props.factory.workReportScope)
    }, [])

    // on submit
    async function submit() {
        try {
            await backend.updateFactory(orgId, facId, {
                workReportScope: reports,
            })
            Toaster.success("work scope Updated Success")
        } catch (e) {
            console.log("work scope error", e)
            Toaster.error("work scope Updated Failed")
        }
        // console.log(reports)
    }
    // remove group
    function removeGroup(idx: any) {
        const cloneList = [...reports]
        cloneList.splice(idx, 1)
        setReports([...cloneList])
    }

    function addSubList(id: any) {
        const cloneList = [...reports]
        cloneList[id].lists = [
            ...cloneList[id].lists,
            { name: null },
        ]
        setReports([...cloneList])
    }

    function updateGroupValue(e: any, key: any, idx: any) {
        const cloneList = [...reports]
        cloneList[idx][key] = e
        setReports([...cloneList])
    }

    function updateListValue(e: any, key: any, idx: any, liIndex: any) {
        const cloneList = [...reports]
        const list = cloneList[idx].lists[liIndex]
        if (list) {
            list[key] = e
        }
        setReports([...cloneList])
    }

    function onDelete(idd: any, subIndex: any) {
        const cloneList = [...reports]
        const list = cloneList[idd].lists
        list.splice(subIndex, 1)
        setReports([...cloneList])
    }

    const SubListRender = (sublist: any, subIndex: any, idd: any) => (
        <Row>
            <Col lg={9}>
                <EditableColumn
                    value={sublist.name}
                    update={(e: any) => updateListValue(e, "name", idd, subIndex)}
                />
            </Col>
            <Col lg={1}>
                <button
                    onClick={e => onDelete(idd, subIndex)}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                >
                    <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                </button>
            </Col>
        </Row>
    )
    return (
        <React.Fragment>
            <Container fluid>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <button
                        onClick={addGroup}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                    >
                        New Group
                    </button>
                </div>
                <br />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <ul
                                    className="list-unstyled"
                                    style={{ height: "60vh", overflow: "auto" }}
                                >
                                    {reports &&
                                        reports.map((report, idd) => (
                                            <li key={idd}>
                                                <div
                                                    className="factory-monitoring-group-list"
                                                    style={{ textAlign: "start" }}
                                                >
                                                    <Row>
                                                        <Col md={10}>
                                                            <EditableColumn
                                                                value={report.name}
                                                                update={(e: any) => updateGroupValue(e, "name", idd)}
                                                            />
                                                        </Col>
                                                        <Col md={1}>
                                                            <button
                                                                onClick={e => removeGroup(idd)}
                                                                type="button"
                                                                className="btn btn-danger waves-effect waves-light"
                                                            >
                                                                <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <ul className="list-unstyled">
                                                    {report.lists.map((sublist: any, subIndex: any) => (
                                                        <li key={subIndex} style={{ padding: "10px" }}>
                                                            {SubListRender(sublist, subIndex, idd)}
                                                        </li>
                                                    ))}
                                                </ul>

                                                <Col lg={10} md={10} style={{ textAlign: "center", padding: "24px 0px" }}>
                                                    <button
                                                        onClick={e => addSubList(idd)}
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light"
                                                    >
                                                        ADD
                                                    </button>
                                                </Col>
                                            </li>
                                        ))}
                                </ul>
                                {reports && reports.length > 0 && (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button onClick={submit} color="success">
                                            Submit
                                        </Button>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(WorkReportScope))
