import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input
} from "reactstrap"
import moment from "moment"
import * as _ from "lodash"
import { desOptions } from "./const"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toastr from "toastr"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import ReportPrint from "./printDailyWorkReport"
import ConformationAlert from "../../common/ui/customAlert"
import { removeSaveReport, saveLocalDailyworkReport } from "store/dailyWorkReport/action"

const Report = (props: any) => {
  const todayDate = moment().format("YYYY-MM-DD")
  const [date, setDate] = useState(todayDate)
  const [timeList, setTimeList] = useState<any[]>([])
  const [first, setFirst] = useState(true)
  const [submitedByuser, setSubmitedByUser] = useState<any>()
  const [submitedBy, setSubmitedBy] = useState<any>()
  const [showSubmit, setShowSubmit] = useState(false)
  const [userList, setuserList] = useState([])
  const [remarks, setRemarks] = useState({})
  const [SubmitAlertVisible, setSubmitAlertVisible] = useState(false)

  const onSubmit = async () => {
    if (props.isAdmin) {
      submit()
    } else {
      toggleAlert()
    }
  }

  const toggleAlert = () => {
    setSubmitAlertVisible(!SubmitAlertVisible)
  }

  async function getUserOrgById(userId) {
    const { orgId } = props.selectedFactory
    try {
      const response = await backend.getUserOrgById(orgId, userId)
      if (response) {
        setSubmitedByUser(response.name)
      }
    } catch (e) { }
  }

  // get Users
  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) { }
  }

  async function getReport(dateParam?, submitedByParam?) {
    // console.log("get report ", submitedByParam, submitedBy)
    if (!props.selectedFactory) return;
    const { orgId, _id: facId } = props.selectedFactory
    try {
      const response = await backend.getDailyworkReport(
        orgId,
        facId,
        dateParam ? dateParam : date,
        submitedByParam ? submitedByParam : submitedBy
      )
      if (response) {
        if (response.remarks) {
          let remarksUpdate = {
            ...remarks,
            [response.date]: response.remarks,
          }
          setRemarks(remarksUpdate)
        }
        if (response.timeList) {
          setTimeList(response.timeList)
        }
        if (response.submitedBy) {
          getUserOrgById(response.submitedBy)
          setShowSubmit(false)
        } else {
          setShowSubmit(true)
        }
        if (props.isAdmin) {
          setShowSubmit(true)
        }
      } else {
        setShowSubmit(true)
        setTimeList([{}])
        setSubmitedByUser(null)
        RetriveSaveDate(dateParam)
      }
    } catch (e) {
      // console.log("error report", e)
      setTimeList([{}])
      setSubmitedByUser(null)
    }
  }

  useEffect(() => {
    if (first && props.selectedFactory) {
      getReport()
      getUsers()
      setTimeList([{}])
      setFirst(false)
    }
  }, [first, props.selectedFactory])

  const _getReport = async() => {
    await getReport(null, props.isAdmin ? submitedBy : props.user._id)
  }

  useEffect(() => {
    // console.log("triggerrrrrrr", props)
    _getReport();
    // await getReport(null, props.isAdmin ? submitedBy : props.user._id)
  }, [props.selectedFactory])

  // console.log("this.props", props)
  useEffect( () => {
    if (!props.isAdmin) {
      setSubmitedBy(props.user._id)
    }
    _getReport();
    // await getReport(null, props.user._id)
    RetriveSaveDate(date)
  }, [])

  const RetriveSaveDate = currentActivedate => {
    if (!props.selectedFactory) return;
    const { _id: facId } = props.selectedFactory
    if (
      props.dailyWorkReport.dailyWorkReportSave[facId] &&
      props.dailyWorkReport.dailyWorkReportSave[facId][currentActivedate] &&
      props.dailyWorkReport.dailyWorkReportSave[facId][currentActivedate]
        .date === currentActivedate
    ) {
      setTimeList(
        props.dailyWorkReport.dailyWorkReportSave[facId][currentActivedate].list
      )
    }
  }

  function handleAddRow() {
    setTimeList([...timeList, {}])
  }

  function updateListValue(value, key, listIndex) {
    const findindex = _.findIndex(
      timeList,
      (value, index) => index == listIndex
    )
    const ltimeList = [...timeList]
    if (findindex > -1) {
      ltimeList[findindex][key] = value

      if (ltimeList[findindex].startDate && ltimeList[findindex].endDate) {
        // console.log(
        //   "ltimeList[findindex]",
        //   ltimeList[findindex],
        //   moment
        //     .duration(
        //       ltimeList[findindex].startDate,
        //       ltimeList[findindex].endDate
        //     )
        //     .hours()
        // )
        let start = moment(`01/01/2021 ${ltimeList[findindex].startDate}:00`)
        let end = moment(`01/01/2021 ${ltimeList[findindex].endDate}:00`)

        var duration = moment.duration(end.diff(moment(start)))
        var hours = duration.hours()
        if (hours < 0) {
          // console.log("hours", hours, "") 
          alert("time less then start time")
          ltimeList[findindex].endDate = ltimeList[findindex].startDate;
          let start = moment(`01/01/2021 ${ltimeList[findindex].startDate}:00`)
          let end = moment(`01/01/2021 ${ltimeList[findindex].endDate}:00`)

          var duration = moment.duration(end.diff(moment(start)))
          var hours = duration.hours()
        }
        var minutes = parseInt(duration.asMinutes().toString()) % 60
        let min = {
          15: "25",
          30: "50",
          45: "75",
          0: "00",
        }

        ltimeList[findindex].duration = `${hours}.${min[minutes]}`
        // console.log("hourssss", ltimeList[findindex])
        // moment
        //   .duration(
        //     `${ltimeList[findindex].startDate}:00`,
        //     `${ltimeList[findindex].endDate}:00`
        //   )
        //   .hours()
      }

      setTimeList([...ltimeList])
    }
  }

  async function submit() {
    const { orgId, _id: facId } = props.selectedFactory

    if (!props.isAdmin) {
      toggleAlert()
    }

    if (!submitedBy) {
      alert("select name")
      return 0
    }
    try {
      const data = {
        list: timeList.filter((item) => item.startDate && item.endDate && item.duration && item.scope && item.desc),
        submitedBy: submitedBy,
        remarks: remarks[date]
      }
      await backend.saveDailyworkReport(orgId, facId, date, data)
      const Removedata = {
        facId: facId,
        date: date,
      }
      props.removeSaveReport(Removedata)
      getReport()
      Toaster.success("work submited successfully")
    } catch (e) {
      console.log("eeeeee", e);

      Toaster.error("work submited failed")
    }
  }

  async function save() {
    const { orgId, _id: facId } = props.selectedFactory
    if (!submitedBy) {
      alert("select name")
      return 0
    }
    try {
      const data = {
        facId: facId,
        list: timeList,
        submitedBy: submitedBy,
        date: date,
      }
      // console.log("propssssssss", props)

      props.saveLocalDailyworkReport(data)

      Toaster.success("work saved successfully")
    } catch (e) {
      Toaster.error("work saved failed")
    }
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current!,
  })
  if (!props.selectedFactory) {
    return <></>
  }
  let aboutDay = {
    W: "Weekly Off",
    H: "Holiday",
    A: "Audit",
  }
  // console.log("remarksremarksremarks", remarks, remarks[date])
  // let date= `${year}-${month}-${day}`

  // console.log(
  //   "todayDatetodayDatetodayDate",
  //   todayDate,
  //   date,
  //   date === todayDate
  // )
  let isToday = date === todayDate

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {!showSubmit ||
            (props.isAdmin && (
              <div style={{ display: "none" }}>
                <ReportPrint
                  // date={`${year}-${month}-${day}`}
                  ref={componentRef}
                  Props={props}
                  submitedBy={submitedBy}
                  userList={userList}
                  date={date}
                  timeList={timeList}
                  scopeList={(props.selectedFactory && props.selectedFactory.workReportScope) ? props.selectedFactory.workReportScope : []}
                />
              </div>
            ))}
          <Row>
            {/* <Col lg={2}></Col> */}
            <Col lg={10}>
              <Card>
                <CardBody>
                  {!showSubmit ||
                    (props.isAdmin && (
                      <React.Fragment>
                        <Button
                          onClick={handlePrint}
                          style={{ margin: "12px 0px", width: "90px" }}
                          color="success"
                        >
                          Print
                        </Button>
                      </React.Fragment>
                    ))}

                  {/* <CardTitle className="mb-4">Horizontal form layout</CardTitle> */}
                  {/* <FormGroup className="col-lg-3" style={{float:"right"}}>
                    <Input
                      onChange={e => {
                        console.log("get eventeeeeeee",e.target.value);
                        
                      }}
                      type="select"
                      name="selectMulti"
                      // value={ ""}
                      placeholder={"Select here"}
                    >
                      <option></option>

                      {Object.keys(aboutDay).map((key, tIndex) => {
                        return (
                          <option value={key} key={tIndex}>
                            {aboutDay[key]}
                          </option>
                        )
                      })}
                    </Input>
                  </FormGroup> */}
                  <Form>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-firstname-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Name
                      </Label>
                      <Col sm={9}>
                        {/* {props.user && <p>{(submitedByuser) ?  submitedByuser : props.user.name}</p>} */}
                        {props.isAdmin ? (
                          <Input
                            onChange={e => {
                              setSubmitedBy(e.target.value)
                              getReport(null, e.target.value)
                            }}
                            type="select"
                            name="selectMulti"
                            value={submitedBy ? submitedBy : ""}
                            placeholder={"Select here"}
                          >
                            <option></option>
                            {props.selectedFactory &&
                              props.selectedFactory.teams &&
                              props.selectedFactory.teams.map(
                                (team, tIndex) => {
                                  const find: any = _.find(userList, [
                                    "_id",
                                    team.userId,
                                  ])
                                  if (find) {
                                    return (
                                      <option value={team.userId} key={tIndex}>
                                        {find && find.name} ({find.role})
                                      </option>
                                    )
                                  }
                                }
                              )}
                          </Input>
                        ) : (
                          <Input
                            readOnly
                            type="text"
                            // name="selectMulti"
                            value={`${props.user.name} (${props.user.role})`}
                            placeholder={"Select here"}
                          ></Input>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Factory Name
                      </Label>
                      <Col sm={9}>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={props.selectedFactory.name}
                          id="example-date-input"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Date
                      </Label>
                      <Col sm={3}>
                        <input
                          className="form-control"
                          type="date"
                          onChange={e => {
                            setDate(e.target.value)
                            getReport(e.target.value)
                          }}
                          value={date}
                          id="example-date-input"
                        />
                      </Col>
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-1 col-form-Label"
                        style={{ marginTop: "7px" }}
                      >
                        Remarks
                      </Label>
                      <Col sm={5}>
                        <Input
                          onChange={e => {
                            let remarksUpdate = {
                              ...remarks,
                              [date]: e.target.value ? e.target.value : "",
                            }

                            setRemarks(remarksUpdate)
                            setTimeList([{}])
                            console.log(
                              "get eventeeeeeee",
                              e.target.value,
                              remarks
                            )
                          }}
                          type="select"
                          name="selectMulti"
                          value={remarks[date] ? remarks[date] : ""}
                          placeholder={"Select here"}
                        >
                          <option value={""}></option>
                          {Object.keys(aboutDay).map((key, tIndex) => {
                            return (
                              <option value={key} key={tIndex}>
                                {aboutDay[key]}
                              </option>
                            )
                          })}
                        </Input>
                      </Col>
                    </FormGroup>
                    {!remarks[date] && (
                      <React.Fragment>
                        <Row>
                          <Col lg={3}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">Time</Label>
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">Scope</Label>
                            </FormGroup>
                          </Col>
                          <Col lg={5}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                Work Description
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        {timeList.map((time, tIndex) => {
                          const findIndex = _.findIndex(
                            timeList,
                            (value, index) => index == tIndex
                          )
                          let find,
                            desList = []
                          if (findIndex > -1) {
                            find = timeList[findIndex]
                            if (find.scope && props.selectedFactory && props.selectedFactory.workReportScope) {
                              const _filterScope = _.find(props.selectedFactory.workReportScope, ['name', find.scope]);
                              desList = (_filterScope) ? _filterScope.lists : []//desOptions[find.scope]
                            }
                          }
                          // console.log("findfindfind", find && find.startDate)

                          return (
                            <Row key={tIndex}>
                              <Col lg={3}>
                                <div style={{ display: "flex" }}>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="start time"
                                    style={{ margin: "2px" }}
                                    value={
                                      find && find.startDate
                                        ? find.startDate
                                        : ""
                                    }
                                    onChange={async e => {
                                      let minit = moment(e[0])
                                        .format("HH:mm")
                                        .split(":")[1]
                                      if (
                                        ["15", "30", "45", "00"].includes(minit)
                                      ) {
                                        updateListValue(
                                          moment(e[0]).format("HH:mm"),
                                          "startDate",
                                          tIndex
                                        )

                                      } else {
                                        updateListValue("", "startDate", tIndex)
                                        toastr.options = {
                                          positionClass: "toast-top-right",
                                          timeOut: 5000,
                                          extendedTimeOut: 3000,
                                          closeButton: true,
                                        }
                                        toastr.error(
                                          "minute must be 15,30,45,00"
                                        )
                                      }
                                    }}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      minuteIncrement: 15,
                                      defaultHour: 9,
                                      dateFormat: "H:i",
                                      allowInput: false,
                                    }}
                                  />
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="end time"
                                    value={
                                      find && find.endDate ? find.endDate : ""
                                    }
                                    style={{ margin: "2px" }}
                                    onChange={e => {
                                      // console.log(
                                      //   "moment(e[0]).format()",
                                      //   moment(e[0])
                                      //     .format("HH:mm")
                                      //     .split(":")[1]
                                      // )
                                      let minit = moment(e[0])
                                        .format("HH:mm")
                                        .split(":")[1]
                                      if (
                                        ["15", "30", "45", "00"].includes(minit)
                                      ) {
                                        updateListValue(
                                          moment(e[0]).format("HH:mm"),
                                          "endDate",
                                          tIndex
                                        )
                                      } else {
                                        updateListValue("", "endDate", tIndex)
                                        toastr.options = {
                                          positionClass: "toast-top-right",
                                          timeOut: 5000,
                                          extendedTimeOut: 3000,
                                          closeButton: true,
                                        }
                                        toastr.error(
                                          "minute must be 15,30,45,00"
                                        )
                                      }
                                    }}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      minuteIncrement: 15,
                                      defaultHour: 9,
                                      dateFormat: "H:i",
                                      allowInput: false,
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <Input
                                  onChange={e =>
                                    updateListValue(
                                      e.target.value,
                                      "scope",
                                      tIndex
                                    )
                                  }
                                  type="select"
                                  name="selectMulti"
                                  value={find && find.scope ? find.scope : ""}
                                  placeholder={"Select here"}
                                >
                                  <option></option>
                                  {props.selectedFactory && props.selectedFactory.workReportScope &&
                                    props.selectedFactory.workReportScope.map((op, index) => (
                                      <option value={op.name} key={index}>
                                        {op.name}
                                      </option>
                                    ))}
                                </Input>
                              </Col>
                              <Col lg={5}>
                                {
                                  <Input
                                    onChange={e =>
                                      updateListValue(
                                        e.target.value,
                                        "desc",
                                        tIndex
                                      )
                                    }
                                    type="select"
                                    value={find && find.desc ? find.desc : ""}
                                    name="selectMulti1"
                                    placeholder={"Select here"}
                                  >
                                    <option></option>
                                    {desList &&
                                      desList.map((op: any, index) => (
                                        <option value={op.name} key={index}>
                                          {op.name}
                                        </option>
                                      ))}
                                  </Input>
                                }
                              </Col>
                            </Row>
                          )
                        })}
                      </React.Fragment>
                    )}
                    {showSubmit && !remarks[date] && (
                      <Button
                        onClick={() => {
                          handleAddRow()
                        }}
                        color="success"
                        className="mt-1"
                      >
                        Add
                      </Button>
                    )}
                  </Form>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "12px",
                    }}
                  >
                    {(isToday || props.isAdmin) && (
                      <React.Fragment>
                        {!props.isAdmin && showSubmit && !remarks[date] && (
                          <Button
                            onClick={save}
                            color="success"
                            style={{ marginRight: "20px" }}
                          >
                            save
                          </Button>
                        )}
                        {showSubmit && (
                          <Button onClick={onSubmit} color="primary">
                            Submit
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg={2}></Col> */}
          </Row>
        </Container>
        <ConformationAlert
          visible={SubmitAlertVisible}
          Title={"Alert"}
          Message={"If you submit you can't do any changes in the report"}
          toggleAlert={toggleAlert}
          Submit={submit}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // console.log("state", state)
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false

  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
    dailyWorkReport: state.dailyWorkReport,
  }
}

export default withRouter(
  connect(mapStateToProps, { saveLocalDailyworkReport, removeSaveReport })(
    Report
  )
)
