import React, { useState, useEffect } from "react"
import { Container, Row, Card, CardBody, Col, Table } from "reactstrap"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb2"
import * as backend from "../../helpers/backend_helper"
import DailyReportGroupSummary from "./DailyReportGroupSummary"
import BackButton from "../../common/ui/backButton"
import * as func from "../../common/functions"
import _ from "lodash"

const DailyReport = (props: any) => {
  const [first, setFirst] = useState(true)
  const [reportList, setReportList] = useState([])
  const [reportMerge, setReportMerge] = useState([])
  const [totalQuestionInDepart, setTotalQuestionInDepart] = useState<any>()
  const [tableBodyDataForFinalScore, settableBodyDataForFinalScore] = useState<any>()


  const {
    match: {
      params: { month, year },
    },
  } = props
  const daysNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
  // const days = Array(daysNumber)
  //   .fill(null)
  //   .map((data, idx) => idx + 1)
  const days = Array(daysNumber)
    .fill(null)
    .map((data, idx) => {
      // console.log("dateadata", data)
      //prepand zero id digit is 1
      let date = (idx + 1).toLocaleString()
      return date.padStart(2, "0")
    })

  function ReportNavigate(day: string | number) {
    props.history.push(`/daily-monitoring/${year}/${month}/${day}`)
  }

  // getday report function
  async function getDayReport(totalnumberofQuestions: any) {
    const daysNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
    const startDate = `${year}-${month}-${'01'}`
    const endDate = `${year}-${month}-${daysNumber}`

    const { orgId, _id: facId } = props.selectedFactory
    try {
      const response = await backend.getdailymonitoringDayReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      // console.log("responseresponse", response)

      const mergeReports = [].concat.apply([], _.map(response, "report"))
      setReportMerge(mergeReports)
      setReportList(response)

      getfinalscore(response, totalnumberofQuestions, mergeReports)
    } catch (e) {
      console.log(e)
    }
  }

  // getday report
  useEffect(() => {
    if (first && props.selectedFactory) {
      let data = func.getTotalQuestionsindailyMonitoringReport(
        props.selectedFactory.dailyMonitoringForm,
        props.selectedFactory.departments
      )
      setTotalQuestionInDepart(data)
      getDayReport(data)
      setFirst(false)
    }
  }, [props.selectedFactory, first])

  if (!props.selectedFactory) {
    return <></>
  }

  const getfinalscore = (reportList: any, totalQuestionInDepart: any, reportMerge: any) => {



    let finalScore = 0
    let tableBodyDataForDepartmentScore: any = {}
    {
      props.selectedFactory &&
        props.selectedFactory &&
        props.selectedFactory.departments &&
        props.selectedFactory.departments.map(
          (depart: any, deIndex: number) => {
            let totalScore = 0

            const filter = _.filter(reportMerge, {
              departmentId: depart["_id"],
              type: "select",
            }).map(f =>
              f.value ? Number(f.value.split(":")[1]) : 0
            )

            let sumtotalScore = _.sum(filter)
            if (totalQuestionInDepart) {
              let depvalue =
                totalQuestionInDepart[depart["_id"]]
              if (depvalue) {
                // totalScore = (
                //   depvalue.divide * sumtotalScore
                // ).toFixed(1)
                // console.log("days", days)
                let NumberOfDays = days.length * 10
                let finalTotalScore =
                  depvalue.divide * sumtotalScore
                totalScore = Number((
                  (finalTotalScore / NumberOfDays) *
                  10
                ).toFixed(1))
              }
            }
            finalScore = finalScore + Number(totalScore)
            // console.log(
            //   "finalScorefinalScorefinalScorefinalScore",
            //   finalScore
            // )

            // tableBodyDataForDepartmentScore[depart.name]={}
            // return (
            //   <tr key={deIndex}>
            //     <th scope="row">{depart.name}</th>
            {
              days.map((m, mIndex) => {
                let value = 0
                const find = _.find(reportList, o => {

                  // console.log("ttttttttttttt",moment(o.date, "YYYY-MM-DD").format(
                  //   "YYYY-MM-DD"
                  // ),`${year}-${month}-${m}`);

                  return (
                    moment(o.date, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    ) == `${year}-${month}-${m}`
                  )
                })
                // console.log(
                //   "findfindfind",
                //   find,
                //   reportList
                // )
                if (find) {
                  if (
                    find.remarks &&
                    aboutDay[find.remarks]
                  ) {
                    value = find.remarks
                  } else {
                    const filter = _.filter(find.report, {
                      departmentId: depart["_id"],
                      type: "select",
                    }).map(f =>
                      f.value
                        ? Number(f.value.split(":")[1])
                        : 0
                    )
                    let sumvalue = _.sum(filter)
                    if (totalQuestionInDepart) {
                      let depvalue =
                        totalQuestionInDepart[depart["_id"]]
                      if (depvalue) {
                        value = Number(
                          (
                            depvalue.divide * sumvalue
                          ).toFixed(1)
                        )
                      }
                    }
                    // console.log(
                    //   "filterfilterfilter",
                    //   filter
                    // )
                  }
                }
                tableBodyDataForDepartmentScore[depart.name] = {
                  ...tableBodyDataForDepartmentScore[depart.name],
                  [m]: typeof value === "string"
                    ? value
                    : Math.round(value)
                }

              })
            }
            tableBodyDataForDepartmentScore[depart.name] = {
              ...tableBodyDataForDepartmentScore[depart.name],
              finalScore: Math.round(totalScore)
            }


            //     <th>{Math.round(totalScore)}</th>
            //   </tr>
            // )
          }
        )
    }
    // console.log("tableBodyDataForDepartmentScore",tableBodyDataForDepartmentScore);

    let tableBodyDataForFinalScore: any = {}
    // console.log("reportListreportListreportList",reportList);

    {
      props.selectedFactory &&
        props.selectedFactory &&
        props.selectedFactory.departments &&
        [1].map((value, key) => {
          let finalValue = 0

          let NumberOfDepartments =
            props.selectedFactory.departments.length * 10
          finalValue = Number((finalScore / NumberOfDepartments) * 100)

          {
            days.map((m, mIndex) => {
              let value = 0
              props.selectedFactory.departments.map((depart: any) => {
                const find = _.find(reportList, o => {
                  return (
                    moment(o.date, "YYYY-MM-DD").format("YYYY-MM-DD") ==
                    `${year}-${month}-${m}`
                  )
                })
                if (find) {
                  if (find.remarks && aboutDay[find.remarks]) {
                    value = find.remarks
                  } else {
                    const filter = _.filter(find.report, {
                      departmentId: depart["_id"],
                      type: "select",
                    }).map(f => (f.value ? Number(f.value.split(":")[1]) : 0))
                    let sumvalue = _.sum(filter)
                    if (totalQuestionInDepart) {
                      let depvalue = totalQuestionInDepart[depart["_id"]]
                      if (depvalue) {
                        value =
                          value +
                          Math.round(
                            Number((depvalue.divide * sumvalue).toFixed(1))
                          )
                        // console.log("valuehhhhhhhhhhhhh", value)
                      }
                    }
                    // console.log(
                    //   "filterfilterfilter",
                    //   filter
                    // )
                  }
                }
              })
              if (typeof value !== "string") {
                let sumvalue = value
                let NumberOfDepartments =
                  props.selectedFactory.departments.length * 10

                tableBodyDataForFinalScore[m] = Math.round(Number(
                  ((sumvalue / NumberOfDepartments) * 100).toFixed(1)
                ))
              } else {
                tableBodyDataForFinalScore[m] = value
              }
              // console.log(
              //   "value123456765432",
              //   value,
              //   typeof value === "string",
              //   Math.round(value)
              // )
            })
          }
          tableBodyDataForFinalScore[`finalValue`] = Math.round(finalValue)
        })
    }
    settableBodyDataForFinalScore(tableBodyDataForFinalScore)
    // console.log("tableBodyDataForFinalScoretableBodyDataForFinalScore", tableBodyDataForFinalScore);

  }

  let aboutDay: any = {
    W: "Weekly Off (W)",
    H: "Holiday (H)",
    A: "Audit (A)",
  }
  let finalScore = 0
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={<BackButton history={props.history} />}
          breadcrumbItems={[
            { title: year, to: "/daily-monitoring" },
            { title: moment(month, "MM").format("MMMM") },
          ]}
        ></Breadcrumb>
        <Container fluid>
          {/* <div style={{ display: "flex", padding: "12px 0px" }}>
            <h4
              onClick={() => {
                props.history.goBack()
              }}
              style={{ cursor: "pointer" }}
            >
              <i
                style={{ paddingRight: "12px" }}
                className="bx bx-arrow-back"
              ></i>
              Back
            </h4>
          </div> */}

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table
                      className="table table-hover mb-0 table-striped table-bordered"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr className="table-success">
                          <th rowSpan={2}>Department</th>
                          <th
                            style={{ textAlign: "center" }}
                            colSpan={days.length}
                          >
                            Daily Monitoring Report Score for the Period{" "}
                            {moment(month, "MM").format("MMMM")}
                          </th>
                          <th rowSpan={2}>Average Score</th>
                        </tr>
                        <tr className="table-success">
                          {days.map((m, mIndex) => (
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={e => ReportNavigate(m)}
                              key={mIndex}
                            >
                              {m}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          props.selectedFactory.departments.map(
                            (depart: any, deIndex: number) => {
                              let totalScore = 0

                              const filter = _.filter(reportMerge, {
                                departmentId: depart["_id"],
                                type: "select",
                              }).map((f: any) =>
                                f.value ? Number(f.value.split(":")[1]) : 0
                              )

                              let sumtotalScore = _.sum(filter)
                              if (totalQuestionInDepart) {
                                let depvalue =
                                  totalQuestionInDepart[depart["_id"]]
                                if (depvalue) {
                                  const reportDayCount: any[] = reportList.filter((item: any) => !item.remarks);
                                  // totalScore = (
                                  //   depvalue.divide * sumtotalScore
                                  // ).toFixed(1)
                                  let NumberOfDays = reportDayCount.length * 10
                                  let finalTotalScore =
                                    depvalue.divide * sumtotalScore;

                                  const _t = Number((
                                    (finalTotalScore / NumberOfDays) *
                                    100
                                  ).toFixed(1))
                                  totalScore = (_t) ? _t : 0
                                }
                              }
                              finalScore = finalScore + Number(totalScore)
                              // console.log(
                              //   "finalScorefinalScorefinalScorefinalScore",
                              //   finalScore
                              // )

                              return (
                                <tr key={deIndex}>
                                  <th scope="row">{depart.name}</th>
                                  {days.map((m, mIndex) => {
                                    let value = 0
                                    const find = _.find(reportList, (o: any) => {
                                      return (
                                        moment(o.date, "YYYY-MM-DD").format(
                                          "YYYY-MM-DD"
                                        ) == `${year}-${month}-${m}`
                                      )
                                    })
                                    // console.log(
                                    //   "findfindfind",
                                    //   find,
                                    //   reportList
                                    // )
                                    if (find) {
                                      if (
                                        find.remarks &&
                                        aboutDay[find.remarks]
                                      ) {
                                        value = find.remarks
                                      } else {
                                        const filter = _.filter(find.report, {
                                          departmentId: depart["_id"],
                                          type: "select",
                                        }).map(f =>
                                          f.value
                                            ? Number(f.value.split(":")[1])
                                            : 0
                                        )
                                        let sumvalue = _.sum(filter)
                                        if (totalQuestionInDepart) {
                                          let depvalue =
                                            totalQuestionInDepart[depart["_id"]]
                                          if (depvalue) {
                                            value = Number(
                                              (
                                                depvalue.divide * sumvalue
                                              ).toFixed(1)
                                            )
                                          }
                                        }
                                        // console.log(
                                        //   "filterfilterfilter",
                                        //   filter
                                        // )
                                      }
                                    }
                                    // console.log("valuevaluevaluevalue", value);

                                    return (
                                      <th key={mIndex}>
                                        {typeof value === "string"
                                          ? value
                                          : Math.round(value)}
                                      </th>
                                    )
                                  })}

                                  <th>{Math.round(totalScore)}</th>
                                </tr>
                              )
                            }
                          )}
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          [1].map((value, key) => {
                            let finalValue = 0

                            let NumberOfDepartments =
                              props.selectedFactory.departments.length * 100
                            finalValue = Number(
                              (finalScore / NumberOfDepartments) * 100
                            )

                            return (
                              <tr key={key}>
                                <th scope="row">Average Score</th>
                                {days.map((m, mIndex) => {
                                  let value = 0
                                  props.selectedFactory.departments.map(
                                    (depart: any) => {
                                      const find = _.find(reportList, (o: any) => {
                                        return (
                                          moment(o.date, "YYYY-MM-DD").format(
                                            "YYYY-MM-DD"
                                          ) == `${year}-${month}-${m}`
                                        )
                                      })
                                      if (find) {
                                        if (
                                          find.remarks &&
                                          aboutDay[find.remarks]
                                        ) {
                                          value = find.remarks
                                        } else {
                                          const filter = _.filter(find.report, {
                                            departmentId: depart["_id"],
                                            type: "select",
                                          }).map(f =>
                                            f.value
                                              ? Number(f.value.split(":")[1])
                                              : 0
                                          )
                                          let sumvalue = _.sum(filter)
                                          if (totalQuestionInDepart) {
                                            let depvalue =
                                              totalQuestionInDepart[
                                              depart["_id"]
                                              ]
                                            if (depvalue) {
                                              value =
                                                value +
                                                Math.round(
                                                  Number(
                                                    (
                                                      depvalue.divide * sumvalue
                                                    ).toFixed(1)
                                                  )
                                                )
                                              // console.log("value", value)
                                            }
                                          }
                                          // console.log(
                                          //   "filterfilterfilter",
                                          //   filter
                                          // )
                                        }
                                      }
                                    }
                                  )
                                  if (typeof value !== "string") {
                                    let sumvalue = value

                                    let NumberOfDepartments =
                                      props.selectedFactory.departments.length *
                                      10

                                    value = Number(
                                      (
                                        (sumvalue / NumberOfDepartments) *
                                        100
                                      ).toFixed(1)
                                    )
                                  }
                                  // console.log(
                                  //   "value123456765432",
                                  //   value,
                                  //   typeof value === "string",
                                  //   Math.round(value)
                                  // )
                                  return (
                                    <th key={mIndex}>
                                      {" "}
                                      {typeof value === "string"
                                        ? value
                                        : Math.round(value)}
                                    </th>
                                  )
                                })}
                                <th>{Math.round(finalValue)}</th>
                              </tr>
                            )
                            // let totalScore = 0
                            // return (
                            //   <tr key={key}>
                            //     <th scope="row">Score111</th>
                            //     {days.map((m, mIndex) => {
                            //       let value = 0

                            //       const find = _.find(reportList, o => {
                            //         return (
                            //           moment(o.date, "YYYY-MM-D").format(
                            //             "YYYY-MM-D"
                            //           ) == `${year}-${month}-${m}`
                            //         )
                            //       })
                            //       if (find) {
                            //         const filter = _.filter(find.report, {
                            //           type: "select",
                            //         }).map(f =>
                            //           f.value
                            //             ? Number(f.value.split(":")[1])
                            //             : 0
                            //         )
                            //         // value = _.sum(filter)

                            //         console.log(
                            //           "totalQuestionInDepart",
                            //           totalQuestionInDepart
                            //         )
                            //         // let sumvalue = _.sum(filter)

                            //         // let NumberOfDepartments =
                            //         //   props.selectedFactory.departments.length *
                            //         //   10

                            //         // value = (
                            //         //   (sumvalue / NumberOfDepartments) *
                            //         //   100
                            //         // ).toFixed(1)

                            //         let sumvalue = _.sum(filter)
                            //         value = sumvalue
                            //         // if (totalQuestionInDepart) {
                            //         //   let depvalue =
                            //         //     totalQuestionInDepart[
                            //         //       props.selectedFactory.departments[0]
                            //         //         ._id
                            //         //     ]
                            //         //   if (depvalue) {
                            //         //     // value = (
                            //         //     //   depvalue.divide * sumvalue
                            //         //     // ).toFixed(1)

                            //         //     let totalvalue = (
                            //         //       depvalue.divide * sumvalue
                            //         //     ).toFixed(1)

                            //         //     let NumberOfDepartments =
                            //         //       props.selectedFactory.departments
                            //         //         .length * 10

                            //         //     value = (
                            //         //       (totalvalue / NumberOfDepartments) *
                            //         //       100
                            //         //     ).toFixed(1)
                            //         //   }
                            //         // }
                            //       }
                            //       console.log(
                            //         "valuevaluevaluevaluevalue",
                            //         value
                            //       )

                            //       totalScore += Number(value)
                            //       return (
                            //         <th key={mIndex}>{Math.round(value)}</th>
                            //       )
                            //     })}
                            //     <th>{Math.round(totalScore)}</th>
                            //   </tr>
                            // )
                          })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* group summary */}
          {/* {props.selectedFactory && reportList.length > 0 && (
            <DailyReportGroupSummary
              reportList={reportList}
              match={props.match}
              reportMerge={reportMerge}
              factories={props.selectedFactory}
              tableBodyDataForFinalScore={tableBodyDataForFinalScore}

            />
          )} */}
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state: any) => {
  // console.log("state in dailyreport", state)

  const { selected: selectedFactory } = state.factory
  return { selectedFactory }
}

export default withRouter(connect(mapStateToProps, {})(DailyReport))
