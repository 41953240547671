import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  Input,
  CardSubtitle,
  CardTitle,
} from "reactstrap"
import { useReactToPrint } from "react-to-print"

import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import ReportPrint from "./PrintReport"
import * as _ from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  saveSatutoryReport,
  removeSaveReport,
} from "../../store/satutoryReport/action"
import ImageViewer from "../../common/ui/ImageViewer"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import style from './index.module.css'

const StatutoryReport = (props: any) => {
  const thisMonth = moment().format("YYYY-MM")

  const [date, setDate] = useState(thisMonth)
  const [reports, setReport] = useState<any[]>([])
  const [submitedBy, setSubmitedBy] = useState<any>({})
  const componentRef = useRef()
  const [imageViewerData, setimageViewerData] = useState<any>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  })

  useEffect(() => {
    if (props && props.selectedFactory && props.selectedFactory.statutoryReport)
      getReport()
  }, [props && props.selectedFactory && props.selectedFactory.statutoryReport])

  const getReport = async (newdate?) => {
    if (props.selectedFactory.statutoryReport) {
      const { orgId, _id: facId } = props.selectedFactory
      let data = await backend.getSatutoryReport(
        orgId,
        facId,
        newdate ? newdate : date
      )

      if (data) {
        var clonedArray = JSON.parse(
          JSON.stringify(props.selectedFactory.statutoryReport)
        )
        // console.log("clonedArray first", clonedArray)
        data.lists.map(respList => {
          clonedArray.map((cArr, i) => {
            if (respList.name === cArr.name) {
              clonedArray.splice(i, 1)
            }
          })
        })
        // console.log("clonedArray", clonedArray, data.lists)
        setReport([...data.lists, ...clonedArray])
        setSubmitedBy(data.submitedBy)
        getLocalSaveData(newdate ? newdate : date)
      } else {
        // console.log(
        //   "(props.selectedFactory.statutoryReport",
        //   props.selectedFactory.statutoryReport
        // )

        var clonedArray = JSON.parse(
          JSON.stringify(props.selectedFactory.statutoryReport)
        )
        setReport(clonedArray)
        setSubmitedBy("")
        getLocalSaveData(newdate ? newdate : date)
      }
    }
  }

  function getLocalSaveData(currentActivedate) {
    const { _id: facId } = props.selectedFactory

    if (
      props.savedSatutoryReport[facId] &&
      props.savedSatutoryReport[facId][currentActivedate] &&
      props.savedSatutoryReport[facId][currentActivedate].date ===
      currentActivedate
    ) {
      // console.log(
      //   "prossin get saved",
      //   props.savedSatutoryReport[facId][currentActivedate]
      // )
      // setTimeList(
      //   props.dailyWorkReport.dailyWorkReportSave[facId][currentActivedate].list
      // )

      var clonedArraySave = JSON.parse(
        JSON.stringify(
          props.savedSatutoryReport[facId][currentActivedate].reports
        )
      )

      setReport(clonedArraySave)
    }
  }

  function setValue(oId, idx, e, key) {
    // console.log("oId, idx, e", oId, idx, e, reports)
    let cloneReports = [...reports]
    const list = cloneReports[oId].lists[idx]
    if (list) {
      list[key] = e
    }
    setReport([...cloneReports])
    // console.log("cloneReportscloneReports", cloneReports)
  }

  async function uploadFile(file, oId, idx, key) {
    try {
      const formData = new FormData()
      formData.append("image", file);
      let data = await backend.fileuploadAPI(formData);
      if (data.data.data && data.data.data[0]) {
        const _data = data.data.data[0];
        setValue(oId, idx, _data.id, key);
        setValue(oId, idx, _data.mimetype, 'mimeType');

      }
      console.log("uploaddata", data);
    } catch (err) {
      console.log("uploadFile", err);
    }
  }
  async function deleteFile(id) {
    try {
      let data = await backend.fileuploaddeleteAPI(id);
    } catch (err) {
      console.log("uploadFile", err);
    }
  }
  async function getFile(id) {
    return await backend.fileAPI(id);
  }


  async function submit() {
    const { orgId, _id: facId } = props.selectedFactory
    // console.log("selectedFactoryselectedFactory", props.selectedFactory)
    let data = {
      reports: reports,
      submitedBy: { name: props.user.name, id: props.user["_id"] },
    }
    try {
      await backend.saveSatutoryReport(orgId, facId, date, data)
      Toaster.success("Report Update Success")

      const removeData = {
        facId: facId,
        // reports: reports,
        // submitedBy: submitedBy,
        date: date,
      }

      props.removeSaveReport(removeData)

      getReport(date)
    } catch (e) {
      console.log("statutoryReport error", e)
      Toaster.error("Report Updated Failed")
    }
  }

  function onSaveClick() {
    // console.log("dataaaaaa", reports);
    const { _id: facId } = props.selectedFactory
    const data = {
      facId: facId,
      reports: reports,
      // submitedBy: submitedBy,
      date: date,
    }

    console.log("propssssssss", data)

    props.saveSatutoryReport(data)
    Toaster.success("Report Saved Successfully")
  }

  // console.log("reportjhsdhsbdhsj", reports)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <input
              className="form-control"
              type="month"
              onChange={e => {
                console.log("e", e.target.value)
                setDate(e.target.value)
                getReport(e.target.value)
              }}
              style={{ width: "20%" }}
              value={date}
              id="example-month-input"
            />
          </div>
          <Row>
            <Col md={4} style={{ marginLeft: "12px" }}>
              {submitedBy.name && (
                <span style={{ display: "flex" }}>
                  <h4>Submit By :</h4>{" "}
                  <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                    {submitedBy.name}
                  </p>{" "}
                </span>
              )}
              {/* {submitedBy && (
                <Button
                  onClick={handlePrint}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                  color="success"
                >
                  Print
                </Button>
              )} */}
            </Col>
          </Row>
          {submitedBy.id && (
            <div style={{ display: "none" }}>
              <ReportPrint
                date={date}
                ref={componentRef}
                selectedFactory={props.selectedFactory}
                reports={reports}
                submitedBy={submitedBy}
                reportName={`Statutory Report`}
              />
            </div>
          )}
          <div style={{ marginLeft: "12px", display: "flex", flexDirection: 'row' }}>
            {reports.length > 0 && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  marginRight: "2px",
                }}
              >
                <Button onClick={submit} color="success">
                  Submit
                </Button>
              </div>
            )}
            {submitedBy.id && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  marginRight: "2px",
                }}
              >
                <Button
                  onClick={handlePrint}
                  style={{
                    marginLeft: "2px",
                    marginRight: "2px",
                    // marginBottom: "20px",
                  }}
                  color="success"
                >
                  Print
                </Button>
              </div>
            )}
            {reports.length > 0 && (
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <Button onClick={onSaveClick} style={{
                  marginLeft: "2px",
                  marginRight: "2px",
                  // marginBottom: "20px",
                }} color="success">
                  Save
                </Button>
              </div>
            )}
          </div>
          {/* <Row> */}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {reports &&
              reports.map((report, oId) => {
                let addFiles = {}
                // var zip = new JSZip();
                // var zipfolder = zip.folder(report.name);
                // console.log("reporttttttttttt", report)
                let sno = 0
                return (
                  <Col lg={12} key={oId} style={{ marginBottom: "24px" }}>
                    <Card style={{ height: "100%" }}>
                      <CardBody>
                        <CardTitle
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {report.name}
                        </CardTitle>
                        <div className="table-responsive">
                          <Table className="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>S#</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Exp Date</th>
                                <th>Remarks</th>
                                <th>Documents</th>
                              </tr>
                            </thead>
                            <tbody>
                              {report.lists.map((list, idx) => {
                                if (!list.isHeader) {
                                  sno = sno + 1
                                }
                                // console.log("listasfkgnfsd", list)
                                let mimeType = ""
                                if (list.image) {
                                  // console.log("listimage", list.image)
                                  mimeType = list.mimeType;
                                  if(mimeType)
                                  addFiles[`${list.name}.${mimeType.split('/').pop()}`] = getFile(list.image);
                                  // mimeType = list.image.match(
                                  //   /[^:/]\w+(?=;|,)/
                                  // )[0]
                                  // const imgeSplit = list.image.split('base64,')
                                  // if (imgeSplit)
                                  //   zipfolder!.file(`${list.name}.${mimeType}`, imgeSplit[1], { base64: true });
                                }
                                const isExpired = list && list.expDate && moment(list.expDate, 'YYYY-MM-DD').isAfter(moment().subtract(30, 'd'))
                                return (
                                  <React.Fragment key={idx}>
                                    {!list.isHeader ? (
                                      <tr className={(isExpired) ? `${style.isExpired} ` : ''}>
                                        <th scope="row">{sno}</th>
                                        <td>{list.name}</td>
                                        {list.type === "select" ? (
                                          <React.Fragment>
                                            <td>
                                              <select
                                                onChange={e => {
                                                  setValue(
                                                    oId,
                                                    idx,
                                                    e.target.value,
                                                    "value"
                                                  )
                                                  if (e.target.value === "no") {
                                                    setValue(
                                                      oId,
                                                      idx,
                                                      "",
                                                      "image"
                                                    )
                                                  }
                                                }}
                                                value={
                                                  list.value ? list.value : ""
                                                }
                                                style={{
                                                  background:
                                                    list.value &&
                                                      list.value === "no"
                                                      ? "#e4e41a"
                                                      : "#FFFF",
                                                }}
                                                id="ddlCreditCardType"
                                                name="ddlCreditCardType"
                                                className="form-control"
                                              >
                                                <option
                                                  style={{
                                                    background: "#FFFF",
                                                  }}
                                                  value=""
                                                ></option>
                                                <option
                                                  style={{
                                                    background: "#FFFF",
                                                  }}
                                                  value="yes"
                                                >
                                                  Yes
                                                </option>
                                                <option
                                                  style={{
                                                    background: "#FFFF",
                                                  }}
                                                  value="no"
                                                >
                                                  No
                                                </option>
                                                {/* <option
                                                  style={{
                                                    background: "#FFFF",
                                                  }}
                                                  value="n/a"
                                                >
                                                  N/A
                                                </option> */}
                                              </select>
                                            </td>
                                          </React.Fragment>
                                        ) : (
                                          <td>
                                            <Input
                                              onChange={e =>
                                                setValue(
                                                  oId,
                                                  idx,
                                                  e.target.value,
                                                  "value"
                                                )
                                              }
                                              value={
                                                list.value ? list.value : ""
                                              }
                                              type="text"
                                              // className="form-control"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                            ></Input>
                                          </td>
                                        )}
                                        <td>
                                          <Input type="date" value={
                                            list.expDate ? list.expDate : ""
                                          } onChange={e => {
                                            setValue(
                                              oId,
                                              idx,
                                              e.target.value,
                                              "expDate"
                                            )
                                          }} className="" />
                                        </td>
                                        <td>
                                            <Input
                                              onChange={e =>
                                                setValue(
                                                  oId,
                                                  idx,
                                                  e.target.value,
                                                  "remarks"
                                                )
                                              }
                                              value={
                                                list.remarks ? list.remarks : ""
                                              }
                                              type="text"
                                              // className="form-control"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                            ></Input>
                                          </td>
                                        <td>
                                          {list.value === "yes" && (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {list.image ? (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-around",
                                                  }}
                                                >
                                                  <div
                                                    onClick={() => {
                                                      // console.log("list.image", list.image)
                                                      setimageViewerData(
                                                        { image: list.image, mimeType: list.mimeType }
                                                      )
                                                    }}
                                                  >
                                                    {mimeType !== "application/pdf" ? (
                                                      <img
                                                        src={list.image}
                                                        alt="none"
                                                        width="50"
                                                        height="50"
                                                      ></img>
                                                    ) : (
                                                      <i
                                                        style={{
                                                          fontSize: "41px",
                                                          color: "green",
                                                        }}
                                                        className="bx bxs-file-pdf"
                                                      ></i>
                                                    )}
                                                  </div>
                                                  <div
                                                    style={{
                                                      padding: "10px 10px",
                                                    }}
                                                  >
                                                    {/* <i
                                                    className="bx bx-edit-alt"
                                                    style={{
                                                      cursor:"pointer",
                                                      paddingRight: "10px",
                                                    }}
                                                    // onClick={e => onEdit(e, list)}
                                                  ></i> */}
                                                    <i
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "red",
                                                        fontSize: "21px",
                                                      }}
                                                      className="bx bx-trash-alt"
                                                      onClick={e => {
                                                        deleteFile(list.image)
                                                        setValue(
                                                          oId,
                                                          idx,
                                                          "",
                                                          "image"
                                                        )
                                                      }}
                                                    ></i>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  <input
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    accept="image/jpeg, image/png,.pdf,.doc"
                                                    className="custom-file-input"
                                                    id={`customFile-${oId}-${idx}`}
                                                    onChange={(e: any) => {
                                                      const file =
                                                        e.target.files[0]

                                                      const fsize = file.size
                                                      const files = Math.round(
                                                        fsize / 1024
                                                      )
                                                      // The size of the file.
                                                      if (files >= 4096) {
                                                        console.log("grater the 4 mb", files);

                                                        // alert(
                                                        //   "File too Big, please select a file less than 1mb"
                                                        // )
                                                        toastr.options = {
                                                          positionClass: "toast-top-right",
                                                          timeOut: 5000,
                                                          extendedTimeOut: 3000,
                                                          closeButton: true,
                                                        }
                                                        toastr.error("File too Big, please select a file less than 4 mb",)
                                                      } else {
                                                        console.log("lesss the one mb", files);
                                                        console.log(
                                                          "type134",
                                                          file,
                                                          // reader.result,
                                                          oId,
                                                          idx,
                                                          e
                                                        );
                                                        uploadFile(file, oId, idx, 'image')
                                                        // encode the file using the FileReader API
                                                        // const reader = new FileReader()
                                                        // reader.onloadend = () => {
                                                        //   // log to console
                                                        //   // logs data:<type>;base64,wL2dvYWwgbW9yZ...
                                                        //   setValue(
                                                        //     oId,
                                                        //     idx,
                                                        //     reader.result,
                                                        //     "image"
                                                        //   )
                                                        // }
                                                        // reader.readAsDataURL(
                                                        //   file
                                                        // )
                                                        // setformModal_data({
                                                        //   ...formModal_data,
                                                        //   file: event.target.files,
                                                        // })
                                                      }
                                                    }}
                                                  />

                                                  <label
                                                    // className="custom-file-label"
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "6px",
                                                      borderRadius: "5px",
                                                    }}
                                                    htmlFor={`customFile-${oId}-${idx}`}
                                                  >
                                                    Choose file
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        className="table-info"
                                        style={{ textAlign: "center" }}
                                      >
                                        <th colSpan={12 + 1} scope="row">
                                          {list.name}
                                        </th>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <button onClick={() => {
                          const keys = Object.keys(addFiles)
                          Promise.all(Object.values(addFiles)).then((files) => {
                            var zip = new JSZip();
                            var zipfolder = zip.folder(report.name);
                            files.forEach((file: any, index) => {
                              zipfolder!.file(keys[index], file.data, { base64: false });
                            })
                            zipfolder!.generateAsync({ type: "blob" }).then(function (content) {
                              // see FileSaver.js
                              saveAs(content, report.name);
                            });
                          })

                        }} style={{ marginTop: 10 }} className="btn btn-primary">Download</button>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}
            {imageViewerData && (
              <ImageViewer
                filedata={imageViewerData}
                close={() => {
                  setimageViewerData("")
                }}
              />
            )}
            {/* </Row> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

// export default

const mapStateToProps = (state, props) => {
  // console.log("state.factory", state.factory, state)

  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
    factories: state.factory.factories,
    savedSatutoryReport: state.satutoryReport.saveSatutoryReport,
  }
}
StatutoryReport.displayName = "StatutoryReport"

export default withRouter(
  connect(mapStateToProps, {
    saveSatutoryReport,
    removeSaveReport,
  })(StatutoryReport)
)
