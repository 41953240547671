import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap"
import classnames from "classnames"
import Analysis from "./analysis"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import Report from "./Report"

const DailyWorkReport = (props: any) => {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  // console.log("DailyWorkReport", props, props.isAdmin)

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <Row style={{ marginTop: "88px" }}>
        {props.isAdmin ? (
          <Container fluid>
            <Row>
              <Col md="2">
                <Nav vertical tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block">Report</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block">Analysis</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="10">
                <TabContent activeTab={customActiveTab}>
                  <TabPane tabId="1" className="p-3">
                    <Report />
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Analysis />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        ) : (
          <Col md="10" style={{paddingLeft:"50px"}}>
            <Report />
          </Col>
        )}
      </Row>
      {/* </div> */}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // console.log("state", state)
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false

  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
  }
}

export default withRouter(connect(mapStateToProps, {})(DailyWorkReport))
