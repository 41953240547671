import React, { useState, useEffect } from "react"
import { Row, Col, Input } from "reactstrap"

const EditableColumn = (props: any) => {
  const [editable, setEditable] = useState(false)
  const [count, setCount] = useState(true)

  useEffect(() => {
    if (count) {
      setEditable(props.value ? false : true)
      setCount(false)
    }
  }, [props.value, count])

  function onKeyDown(e) {
    if (e.keyCode == 13 && props.value) {
      setEditable(false);
      props.keyPress && props.keyPress(e);
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {!editable && (
            <span onClick={e => setEditable(true)}>{props.value}</span>
          )}
          {editable && (
            <React.Fragment>
              {props.type == "text" || props.type == "input" || !props.type ? (
                <Input
                  type="text"
                  onKeyDown={onKeyDown}
                  onChange={e => props.update(e.target.value)}
                  value={props.value ? props.value : ""}
                  className="inner form-control"
                  disabled={props.disabled ? props.disabled : false}
                  placeholder={(props.placeholder) ? props.placeholder : "Enter here"}
                />
              ) : props.type == "select" ? (
                <Input
                  style={props.disabled ? { backgroundColor: "#dddddd" }
                    : {}}
                  onChange={e => props.update(e.target.value)}
                  value={props.value ? props.value : " "}
                  type="select"
                  name="selectMulti"
                  disabled={props.disabled ? props.disabled : false}
                  placeholder={(props.placeholder) ? props.placeholder : "Enter here"}
                >
                  <option></option>
                  {props.options &&
                    props.options.map((op, index) => (
                      <option value={op.value} key={index}>
                        {op.label}
                      </option>
                    ))}
                </Input>
              ) : (
                <></>
              )}
            </React.Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default EditableColumn
