import { type } from "./actionTypes"

const initialState = {
  dailyWorkReportSave: {},
}

const dailyWorkReport = (state = initialState, action) => {
  switch (action.type) {
    case type.SAVE_LOCAL_DAILY_WORK_REPORT:
      console.log("action.data", action.payload.value)
      let facId = action.payload.value.facId
      let date = action.payload.value.date

      return {
        ...state,
        dailyWorkReportSave: {
          ...state.dailyWorkReportSave,
          [facId]: {
            ...state.dailyWorkReportSave[facId],
            [date]: action.payload.value,
          },
        },
      }
    case type.REMOVE_SAVE_REPORT:
      // console.log(
      //   "action",
      //   action,
      //   state.dailyWorkReportSave,
      //   action.paylaod.value.facId
      // )

      let dailyWorkReportSave = state.dailyWorkReportSave

      let finalValue = dailyWorkReportSave[action.paylaod.value.facId]
      if(finalValue){

        delete finalValue[action.paylaod.value.date]
      }
    //  let enddate = { ...dailyWorkReportSave,finalValue }
      // console.log("finalValue", finalValue,dailyWorkReportSave)

      return {
        ...state,
        dailyWorkReportSave: { ...dailyWorkReportSave },
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default dailyWorkReport
