import * as url from "./url_helper"
import { post, del, get, put, axiosApi } from "./api_helper"

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}
//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null && getAccessToken() !== null
}

//Get access token from localstorage
export const getAccessToken = () => {
  const token = localStorage.getItem("token")
  if (token) return token
  return null
}

// Login Method
export const postJwtLogin = (data: any) => {
  return post(url.LOGIN, data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// sign Up method
export const signUp = (data: any) => {
  return post(url.SIGNUP, data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// get current user
export const getUser = () => {
  return get(url.GET_USER)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
/** ORGS APIS */

// Save orgs
export const saveOrgs = (data: any) => {
  return post(url.SAVE_ORGS, data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// get Orgs
export const getOrgs = () => {
  return get(url.GET_ORGS)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
// udpate orgs
export const updateOrgs = (orgId: string, data: any) => {
  return put(url.UPDATE_ORGS + orgId, data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//delete orgs
export const deleteOrgs = (orgId: string) => {
  return del(url.DELETE_ORGS + orgId)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// save user for org
export const saveUserOrg = (orgId: string, data: any) => {
  return post(url.SAVE_ORG_USERS(orgId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// get user for org
export const getUserOrg = (orgId: string) => {
  return get(url.GET_ORG_USERS(orgId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
// get user for org by id
export const getUserOrgById = (orgId: string, userId: string) => {
  return get(url.GET_ORG_USERS_BYID(orgId, userId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// update user for org
export const updateUserOrg = (orgId: string, userId: string, data: any) => {
  return put(url.UPDATE_ORG_USERS(orgId, userId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

/** Factory APIS */

// get all factory by Users
export const getAllFactoryByUsers = () => {
  return get(url.GET_ALL_FACTORY_BYUSER)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//save factory
export const saveFactory = (orgId: string, data: any) => {
  return post(url.SAVE_FACTORY(orgId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//get factory
export const getFactory = (orgId: string) => {
  return get(url.GET_FACTORY(orgId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//get factory By ID
export const getFactoryByID = (orgId: string, facId: string) => {
  return get(url.GET_FACTORY_BYID(orgId, facId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const cloneFactory = (orgId: string, facId: string, data: any) => {
  return post(url.CLONE_FACTORY_BYID(orgId, facId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//update factory
export const updateFactory = (orgId: string, facId: string, data: any) => {
  return put(url.UPDATE_FACTORY(orgId, facId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//update factory
export const deleteFactory = (orgId: string, facId: string) => {
  return del(url.DELETE_FACTORY(orgId, facId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// add user for factory
export const addUserFactory = (orgId: string, facId: string, userId: string, permissions: string[]) => {
  return post(url.ADD_FACTORY_USER(orgId, facId, userId), { permissions: permissions })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//remove user for factory
export const removeUserFactory = (orgId: string, facId: string, userId: string) => {
  return del(url.DELETE_FACTORY_USER(orgId, facId, userId))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
//update user Activity for factory
export const updateUserActivityRole = (orgId: string, facId: string, userId: string, status: boolean) => {
  return post(url.UPDATE_FACTORY_USER_ACTIVITY(orgId, facId, userId), { status: status })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

/**
 * file upload
 */
export const fileuploadAPI = (data: any) => {
  return axiosApi({
    method: "post",
    url: url.FILE_UPLOAD(),
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      // authorization: "basic " + getAccessToken(),
    },
  })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
export const fileAPI = (id: any) => {
  return axiosApi({
    method: "get",
    responseType: 'arraybuffer',
    url: url.FILE_UPLOAD_DELETE(id)
  })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
export const fileuploaddeleteAPI = (id: any) => {
  return axiosApi({
    method: "delete",
    url: url.FILE_UPLOAD_DELETE(id)
  })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
/**monthly monitoring report */

// upload images
export const uploadMonthlyMonitoringReport = (orgId: string, facId: string, data: any) => {
  return axiosApi({
    method: "post",
    url: url.DAILY_MONITORING_REPORT(orgId, facId),
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      // authorization: "basic " + getAccessToken(),
    },
  })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// get dailymonitoring report
export const getdailymonitoringReport = (orgId: string, facId: string, date: any) => {
  return get(url.GET_DAILY_MONITORING_REPORT(orgId, facId, date))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const savedailymonitoringReport = (orgId: string, facId: string, date: any, data: any) => {
  return post(url.SAVE_DAILY_MONITORING_REPORT(orgId, facId, date), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const getdailymonitoringDayReport = (
  orgId: string,
  facId: string,
  startDate: string,
  endDate: string,
) => {
  return get(
    url.GET_DAILY_MONITORING_DAY_REPORT(orgId, facId, startDate, endDate)
  )
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const getdailymonitoringMonthReport = (
  orgId: string,
  facId: string,
  startDate: string,
  endDate: string,
) => {
  return get(
    url.GET_DAILY_MONITORING_MONTH_REPORT(orgId, facId, startDate, endDate)
  )
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

/**monthy Moniroring */
// save report
export const savemonthlyMonitoringReport = (orgId: string, facId: string, date: any, data: any) => {
  return post(url.SAVE_MONTHLY_MONITROING(orgId, facId, date), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
// get_report
export const getmonthlyMonitoringReport = (orgId: string, facId: string, date: any) => {
  return get(url.GET_MONTHLY_MONITROING(orgId, facId, date))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
// get Performance Report
export const getmonthlyperformaceReport = (
  orgId: string,
  facId: string,
  startDate: string,
  endDate: string,
) => {
  return get(url.GET_PERFORMANCE_REPORT(orgId, facId, startDate, endDate))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

/**work report */
// save report
export const saveDailyworkReport = (orgId: string, facId: string, date: any, data: any) => {
  return post(url.SAVE_DAILY_WORK_REPORT(orgId, facId, date), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// get reports
export const getDailyworkReport = (orgId: string, facId: string, date: any, submitedBy: any) => {
  return get(url.GET_DAILY_WORK_REPORT(orgId, facId, date, submitedBy))
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

//delete Images
export const deleteImage = (orgId: string, facId: string, data: any) => {
  return post(url.DELETE_IMAGES(orgId, facId), data)
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const saveSatutoryReport = (orgId: string, facId: string, date: any, data: any) => {
  return post(url.SAVE_SATUTORY_REPORT(orgId, facId, date), { data: data })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const getSatutoryReport = (orgId: string, facId: string, date: any) => {
  return get(url.SAVE_SATUTORY_REPORT(orgId, facId, date))
    .then(response => response)
    .catch(e => {
      console.log("e", e)

      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const HealthAndSaftyReport = (orgId: string, facId: string, date: any, data: any) => {
  return post(url.HEALTH_AND_SAFTY_REPORT(orgId, facId, date), { data: data })
    .then(response => response)
    .catch(e => {
      console.log("e", e)

      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const gethealthandsaftyreport = (orgId: string, facId: string, date: any) => {
  return get(url.HEALTH_AND_SAFTY_REPORT(orgId, facId, date))
    .then(response => response)
    .catch(e => {
      console.log("e", e)
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

// upload images
export const uploadHealthAndSaftyImage = (orgId: string, facId: string, data: any) => {
  return axiosApi({
    method: "post",
    url: url.UPLOAD_HEALTH_AND_SAFTY_IMAGE(orgId, facId),
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    },
  })
    .then(response => response)
    .catch(e => {
      console.log("e", e)

      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const deleteHealthAndSaftyImage = (orgId: string, facId: string, data: any) => {
  return del(url.DELETE_HEALTH_AND_SAFTY_IMAGES(orgId, facId), { data: data })
    .then(response => response)
    .catch(e => {
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const getAllMonthlyInspection = (orgId: string, facId: string, year: any) => {
  return get(url.GET_ALL_MONTHLY_INSPECTION_REPORT(orgId, facId, year))
    .then(response => response)
    .catch(e => {
      console.log("e", e)
      const data = e.response.data
      throw data.message ? data.message : data
    })
}

export const getDailyWorkReportAnalysis = (
  orgId: string,
  facId: string,
  startDate: string,
  endDate: string,
) => {
  return get(
    url.GET_DAILY_WORK_REPORT_ANALYSIS(orgId, facId, startDate, endDate)
  )
    .then(response => response)
    .catch(e => {
      console.log("e", e)
      const data = e.response.data
      throw data.message ? data.message : data
    })
}
