
import {type} from './actionTypes'

export function saveSatutoryReport(data) {
    console.log("dataaaaaaaaaa in action",data);
    
    return {
        type: type.SAVE_SATUTORY_REPORT,
        payload:{value:data}
    }
}

export function removeSaveReport(data){

    return {
        type:type.REMOVE_SAVE_SATUTORY_REPORT,
        paylaod:{value:data}
    }
}