import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap"
import classnames from "classnames"
// import Analysis from "./analysis"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import TeamPerfomaceScore from "./teamPerfomaceScore"
import DepartmentComplineScore from "./departmentComplineScore"

const PerformaceScore = props => {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  // console.log("DailyWorkReport", props, props.isAdmin)

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <Row style={{ marginTop: "88px" }}>
        <Container fluid>
          <Row>
            <Col md="2">
              <Nav vertical tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Team Performance Score
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Departments Compliance Score
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10">
              <TabContent activeTab={customActiveTab}>
                <TabPane tabId="1" className="p-3">
                  <TeamPerfomaceScore />
                </TabPane>
                <TabPane tabId="2" className="p-3">
                  <DepartmentComplineScore />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </Row>
      {/* </div> */}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // console.log("state", state)

  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false

  return {
    selectedFactory,
    user,
    isAdmin: isAdmin,
  }
}

export default withRouter(connect(mapStateToProps, {})(PerformaceScore))

// import React, { useState, useEffect } from "react"
// import {
//   Container,
//   Row,
//   Card,
//   CardBody,
//   Col,
//   Table,
//   Button,
//   Modal,
//   CardTitle,
// } from "reactstrap"
// import * as moment from "moment"
// import { connect } from "react-redux"
// import { withRouter } from "react-router-dom"
// import Flatpickr from "react-flatpickr"

// import * as backend from "../../helpers/backend_helper"
// import Toaster from "../../components/Common/Toaster"
// import * as func from "../../common/functions"

// const PerformaceScore = props => {
//   const today = moment().format("YYYY-MM")
//   console.log("today", today)

//   const [userList, setuserList] = useState([])
//   const [count, setCount] = useState(true)
//   const [date] = useState(today)
//   const [range, setRange] = useState([
//     `${date}-01`,
//     moment().format("YYYY-MM-DD"),
//   ])
//   const [score, setScore] = useState()
//   const [reportList, setReportList] = useState([])
//   const [performanceReport, setperformanceReport] = useState()
//   const [totalQuestionInDepart, setTotalQuestionInDepart] = useState()

//   async function getUsers() {
//     try {
//       const orgId = props.selectedFactory["orgId"]
//       const response = await backend.getUserOrg(orgId)
//       setuserList(response)
//     } catch (e) {}
//   }

//   async function getMonthlyReport() {
//     const { orgId, _id: facId } = props.selectedFactory
//     const year = moment(date, "YYYY-MM").format("YYYY")
//     try {
//       let response = await backend.getmonthlyMonitoringReport(
//         orgId,
//         facId,
//         year
//       )
//       if (response && response.report) {
//         scoreCalc(response.report)
//       }
//     } catch (e) {}
//   }

//   async function getMonthReportforDaily() {
//     try {
//       const year = moment(date, "YYYY-MM").format("YYYY")
//       const startDate = `${year}-${"01"}-${"01"}`
//       const endDate = `${year}-${12}-${31}`
//       const { orgId, _id: facId } = props.selectedFactory
//       const response = await backend.getdailymonitoringMonthReport(
//         orgId,
//         facId,
//         startDate,
//         endDate
//       )
//       console.log("responseresponse", response)

//       setReportList(response)
//     } catch (e) {
//       console.log(e)
//     }
//   }

//   // get reports
//   async function getmonthlyperformaceReport() {
//     const year = moment(date, "YYYY-MM").format("YYYY")
//     const { orgId, _id: facId, teams } = props.selectedFactory
//     const startDate = `${year}-${"01"}-${"01"}`
//     const endDate = `${year}-${12}-${31}`
//     try {
//       let response = await backend.getmonthlyperformaceReport(
//         orgId,
//         facId,
//         startDate,
//         endDate
//       )
//       if (response) {
//         const finalreport = func.performanceReportMonthly(response, teams, year)
//         console.log("finalreport", finalreport)
//         setperformanceReport(finalreport)
//       }
//     } catch (e) {
//       console.log("get performance report", e)
//     }
//   }

//   async function scoreCalc(reportsP) {
//     const year = moment(date, "YYYY-MM").format("YYYY")
//     const lreports = reportsP
//     const op = func.monthlyMonitoringScoreCalc(
//       props.selectedFactory.monthlyMonitoringForm,
//       props.selectedFactory.responsibility,
//       props.selectedFactory.teams,
//       lreports,
//       year
//     )
//     console.log("ooppppp", op)

//     setScore(op)
//   }

//   // get usersList
//   useEffect(() => {
//     if (count && props.selectedFactory) {
//       getUsers()
//       getMonthlyReport()
//       getmonthlyperformaceReport()
//       getMonthReportforDaily()
//       setCount(false)
//       let data = func.getTotalQuestionsindailyMonitoringReport(
//         props.selectedFactory.dailyMonitoringForm,
//         props.selectedFactory.departments
//       )
//       setTotalQuestionInDepart(data)
//     }
//   }, [count, userList, props.selectedFactory])
//   let totalDepartValue = 0
//   let totalResValue = 0

//   if (!props.selectedFactory) {
//     return <></>
//   }
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             {/* <input
//               className="form-control"
//               type="month"
//               onChange={e => {
//                 setDate(e.target.value)
//               }}
//               style={{ width: "20%" }}
//               value={date}
//               id="example-month-input"
//             /> */}
//             <Flatpickr
//               className="form-control d-block"
//               placeholder="start time"
//               // style={{ margin: "2px" }}
//               style={{ width: "20%" }}
//               value={range}
//               onChange={e => {
//                 // updateListValue(
//                 //   moment(e[0]).format("HH:mm"),
//                 //   "startDate",
//                 //   tIndex
//                 // )

//                 if (e.length === 2) {
//                   console.log("range picker", e)
//                   console.log("loggg", [
//                     moment(e[0]).format("YYYY-MM-DD"),
//                     moment(e[1]).format("YYYY-MM-DD"),
//                   ])
//                   let startdate = moment(e[0]).format("YYYY-MM-DD")
//                   let endDate = moment(e[1]).format("YYYY-MM-DD")

//                   setRange([startdate, endDate])
//                 }
//               }}
//               options={{
//                 // enableTime: true,
//                 // noCalendar: true,
//                 // dateFormat: "H:i",
//                 mode: "range",
//                 dateFormat: "Y-m-d",
//                 defaultDate: ["2016-10-10", "2016-10-20"],
//               }}
//             />
//           </div>
//           <br />
//           <Row>
//             <Col lg={6}>
//               <Card>
//                 <CardBody className="text-center">
//                   <CardTitle className="mt-0">Team Performance Score</CardTitle>
//                   <div className="table-responsive">
//                     <Table className="table table-hover table-striped table-bordered mb-0">
//                       <thead>
//                         <tr className="table-success">
//                           <th>Name (Designation)</th>
//                           <th>Average Score</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {props.selectedFactory &&
//                           props.selectedFactory.teams.map((team, mIndex) => {
//                             const divide =
//                               100 / (props.selectedFactory.teams.length * 100)
//                             const find = _.find(userList, ["_id", team.userId])
//                             const month = moment(date, "YYYY-MM").format("MMM")
//                             let value = 0
//                             if (score && performanceReport) {
//                               const user = score[team.userId]
//                               const preport = performanceReport[team.userId]

//                               console.log("useruser", user, preport)

//                               if (user) {
//                                 value += user[month] ? user[month] : 0
//                                 value += preport[date] ? preport[date] : 0
//                                 totalResValue += value
//                               }
//                             }
//                             if (find) {
//                               // console.log("findfindfind",find);

//                               return (
//                                 <React.Fragment>
//                                   <tr key={mIndex}>
//                                     <th key={mIndex}>
//                                       {find.name} ({find.role})
//                                     </th>
//                                     <td>{Math.round(value)}</td>
//                                   </tr>
//                                   {props.selectedFactory.teams.length ==
//                                     mIndex + 1 && (
//                                     <tr
//                                       className="table-success"
//                                       key={mIndex + 1}
//                                     >
//                                       <th>Average Score</th>
//                                       <td>
//                                         {Math.round(
//                                           (divide * totalResValue).toFixed(1)
//                                         )}
//                                       </td>
//                                     </tr>
//                                   )}
//                                 </React.Fragment>
//                               )
//                             }
//                           })}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col lg={6}>
//               <Card>
//                 <CardBody className="text-center">
//                   <CardTitle className="mt-0">
//                     Departments Compliance Score
//                   </CardTitle>
//                   <div className="table-responsive">
//                     <Table className="table table-hover table-striped table-bordered mb-0">
//                       <thead>
//                         <tr className="table-success">
//                           <th>Departments</th>
//                           <th>Name</th>
//                           <th> Average Score</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {props.selectedFactory &&
//                           props.selectedFactory.departments.map(
//                             (depart, mIndex) => {
//                               let value = 0
//                               const find = _.filter(reportList, o => {
//                                 console.log("e.date", o.date)

//                                 return moment(o.date).isBetween(
//                                   range[0],
//                                   range[1],
//                                   undefined,
//                                   "[]"
//                                 ) //true
//                                 // moment(o.date, "YYYY-MM-DD").format(
//                                 //   "YYYY-MM"
//                                 // ) == date
//                               })
//                               if (find.length > 0) {
//                                 const report = [].concat.apply(
//                                   [],
//                                   _.map(find, "report")
//                                 )
//                                 const filter = _.filter(report, {
//                                   departmentId: depart["_id"],
//                                   type: "select",
//                                 })
//                                   .map(f =>
//                                     f.value ? Number(f.value.split(":")[1]) : 0
//                                   )
//                                   .filter(o => o == 1)
//                                 if (totalQuestionInDepart) {
//                                   let depvalue =
//                                     totalQuestionInDepart[depart["_id"]]
//                                   if (depvalue) {
//                                     value = (
//                                       depvalue.divide * filter.length
//                                     ).toFixed(1)
//                                     // let totalDeptValue = 0

//                                     // totalDeptValue += Number(value)
//                                     //  console.log("depart",depart,totalDeptValue/  totalDept);

//                                     totalDepartValue += Number(value)
//                                   }
//                                 }
//                               }
//                               let totalDept =
//                                 props.selectedFactory.departments.length
//                               return (
//                                 <React.Fragment>
//                                   <tr key={mIndex}>
//                                     <th>{depart.name}</th>
//                                     <th>{depart.holderName}</th>
//                                     <td>{Math.round(value)}</td>
//                                   </tr>
//                                   {props.selectedFactory.departments.length ==
//                                     mIndex + 1 && (
//                                     <tr
//                                       className="table-success"
//                                       key={mIndex + 1}
//                                     >
//                                       <th></th>
//                                       <th>Average Score</th>
//                                       <td>
//                                         {Math.round(
//                                           totalDepartValue / totalDept
//                                         )}
//                                       </td>
//                                     </tr>
//                                   )}
//                                 </React.Fragment>
//                               )
//                             }
//                           )}
//                         {/* totalQuestionInDepart */}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// const mapStateToProps = (state, props) => {
//   const { user } = state.user
//   const { selected: selectedFactory } = state.factory
//   const isAdmin =
//     selectedFactory && selectedFactory.permission.includes("ADMIN")
//       ? true
//       : false
//   return { selectedFactory, user, isAdmin: isAdmin }
// }

// export default withRouter(connect(mapStateToProps, {})(PerformaceScore))
