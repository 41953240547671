
import {type} from './actionTypes'

export function saveHealthAndSafety(data) {
    console.log("dataaaaaaaaaa in action",data);
    
    return {
        type: type.SAVE_HEALTH_AND_SAFETY_REPORT,
        payload:{value:data}
    }
}

export function removeHealthAndSafety(data){

    console.log("data",data);
    

    return {
        type:type.REMOVE_HEALTH_AND_SAFETY_REPORT,
        paylaod:{value:data}
    }
}