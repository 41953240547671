import React, { useState } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import * as _ from "lodash"
import "../../assets/scss/theme.scss"
import { fileUrl } from "service"

const ReportPrint = React.forwardRef((props: any, ref: any) => {
  // console.log("props", props)

  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h4 style={{ textAlign: "center" }}>
                          {props.reportName}
                        </h4>{" "}
                        {/* <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.submitedBy}
                          
                        </p>{" "} */}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Factory Name :</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.selectedFactory && props.selectedFactory.name}
                        </p>{" "}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Submit By :</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.submitedBy}
                        </p>{" "}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Date:</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.date}
                        </p>{" "}
                      </span>
                    </Col>
                    <Col md={4}>
                      {/* <span style={{ display: "flex" }}>
                        <h4>Fire Hydrant Pressure Level :</h4>{" "}
                        <span style={{ padding: "2px 8px" }}>
                          <p>{props.pressureLevel}</p>
                        </span>
                      </span> */}
                      {/* <span style={{ display: "flex" }}>
                        <h4>Total Worker :</h4>{" "}
                        <span style={{ padding: "2px 8px" }}>
                          {props.totalworker}
                        </span>
                      </span> */}
                    </Col>
                    <Col md={4}>
                      <span
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <h4>Score:</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.overallscore}
                        </p>{" "}
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-hover table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>CheckPoints</th>
                          {props.selectedFactory &&
                            props.selectedFactory &&
                            props.selectedFactory.departments &&
                            props.selectedFactory.departments.map(
                              (depart, deIndex) => {
                                return <th key={deIndex}>{depart.name}</th>
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          props.selectedFactory.dailyMonitoringForm &&
                          props.selectedFactory.dailyMonitoringForm.map(
                            (group, groupIndex) => {
                              return (
                                <React.Fragment key={groupIndex}>
                                  <tr
                                    key={groupIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    <th
                                      colSpan={
                                        props.selectedFactory.departments
                                          .length + 1
                                      }
                                      scope="row"
                                    >
                                      {group.name}
                                    </th>
                                  </tr>
                                  {group.lists.map((question, qIndex) => {
                                    return (
                                      <tr key={qIndex}>
                                        <th scope="row">{question.name}</th>
                                        {props.selectedFactory.departments.map(
                                          (depart, deIndex) => {
                                            const obj = {
                                              groupId: group["_id"],
                                              questionId: question["_id"],
                                              departmentId: depart["_id"],
                                            }
                                            const find = _.find(
                                              props.reports,
                                              obj
                                            )
                                            return (
                                              <td key={deIndex}>
                                                <p>
                                                  {find && find.value
                                                    ? find.value.split(":")[0]
                                                    : null}
                                                </p>
                                              </td>
                                            )
                                          }
                                        )}
                                      </tr>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            }
                          )}
                        <tr>
                          <th scope="row">Score</th>
                          {/* {props.selectedFactory &&
                            props.selectedFactory &&
                            props.selectedFactory.departments &&
                            props.selectedFactory.departments.map(
                              (depart, index) => {
                                const dep = _.filter(props.reports, {
                                  departmentId: depart["_id"],
                                  type: "select",
                                })
                                  .map(d => d.value.split(":")[1])
                                  .filter(o => o)
                                  .map(o => Number(o))
                                const depScore = _.sum(dep)
                                return <td key={index}>{depScore}</td>
                              }
                            )} */}
                          {props.selectedFactory &&
                            props.selectedFactory &&
                            props.selectedFactory.departments &&
                            props.selectedFactory.departments.map(
                              (depart, index) => {
                                const dep = _.filter(props.reports, {
                                  departmentId: depart["_id"],
                                  type: "select",
                                })
                                  .map(d => d.value.split(":")[1])
                                  .filter(o => o == "1")
                                  .map(o => Number(o))
                                let depScore = 0
                                if (props.totalQuestionInDepart) {
                                  let depvalue =
                                    props.totalQuestionInDepart[depart["_id"]]
                                  // console.log("depvaluedepvalue",depvalue,totalQuestionInDepart);

                                  if (depvalue) {
                                    depScore = Math.round(
                                      Number((depvalue.divide * dep.length).toFixed(1))
                                    )
                                  }
                                }
                                return <td key={index}>{depScore}</td>
                              }
                            )}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* here Show Images start */}
          <Row>
            <Col md={12}>
              <Card>
                <CardTitle>Remarks (Enclose Remarks)</CardTitle>
                <CardBody>
                  <div
                    className="popup-gallery"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {props.selectedFactory &&
                      props.selectedFactory &&
                      props.selectedFactory.departments &&
                      props.selectedFactory.departments.map((depart, key) => {
                        // console.log("departdepartdepart", depart)
                        const find1 = _.find(props.images, {
                          departmentId: depart._id,
                        })
                        return (
                          <React.Fragment key={key}>
                            <div>
                              {props.images.length > 0 && find1 && (
                                <div
                                  style={{ paddingLeft: "8px" }}
                                  className="mt-0 card-title"
                                >
                                  {depart.name}
                                </div>
                              )}
                              <Row>
                                {props.images &&
                                  props.images.map((image, key) => {
                                   
                                    const find = _.find([image], {
                                      departmentId: depart._id,
                                    })
                                    // console.log("findfind", find)

                                    if (find) {
                                      return (
                                        <div
                                          className="img-fluid float-left"
                                          style={{
                                            padding: "10px",
                                            margin: "12px",
                                            height: "300px",
                                            width: "280px",
                                            // backgroundColor: "rgb(250, 250, 250)",
                                            // border: "1px dashed black",
                                          }}
                                          key={key}
                                        >
                                          {find.img && (
                                            <div>
                                              <img
                                                width="150px"
                                                height="150px"
                                                src={find.img.id ? fileUrl(find.img.id) : `data:${find.img.contentType};base64, ${find.img.data}`}
                                                onClick={() => {
                                                  // setisGallery(true)
                                                  // setphotoIndex(0)
                                                }}
                                                alt=""
                                                // width="120"
                                              />
                                              {find.desc && (
                                                <p> {find.desc} </p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )
                                    }
                                  })}
                              </Row>
                            </div>
                          </React.Fragment>
                        )
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* here Show Images stop */}
        </Container>
      </div>
    </React.Fragment>
  )
})
ReportPrint.displayName= 'ReportPrint'
export default ReportPrint
