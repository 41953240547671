import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Flatpickr1 from "react-flatpickr"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import _ from "lodash"

const Flatpickr: any = Flatpickr1;

const PerformaceScore = (props: any) => {
  const today = moment().format("YYYY-MM")
  // console.log("today", today)

  const [userList, setuserList] = useState([])
  const [count, setCount] = useState(true)
  const [date] = useState(today)
  const [range, setRange] = useState([
    `${date}-01`,
    moment().format("YYYY-MM-DD"),
  ])
  const [score, setScore] = useState<any>()
  const [reportList, setReportList] = useState<any>([])
  const [performanceReport, setperformanceReport] = useState<any>()
  const [totalQuestionInDepart, setTotalQuestionInDepart] = useState<any>()

  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) { }
  }

  async function getMonthlyReport() {
    const { orgId, _id: facId } = props.selectedFactory
    const year = moment(date, "YYYY-MM").format("YYYY")
    try {
      let response = await backend.getmonthlyMonitoringReport(
        orgId,
        facId,
        year
      )
      if (response && response.report) {
        scoreCalc(response.report)
      }
    } catch (e) { }
  }

  async function getMonthReportforDaily() {
    try {
      const year = moment(date, "YYYY-MM").format("YYYY")
      const startDate = `${year}-${"01"}-${"01"}`
      const endDate = `${year}-${12}-${31}`
      const { orgId, _id: facId } = props.selectedFactory
      const response = await backend.getdailymonitoringMonthReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      // console.log("responseresponse", response)

      setReportList(response)
    } catch (e) {
      console.log(e)
    }
  }

  // get reports
  async function getmonthlyperformaceReport() {
    const year = moment(date, "YYYY-MM").format("YYYY")
    const { orgId, _id: facId, teams } = props.selectedFactory
    const startDate = `${year}-${"01"}-${"01"}`
    const endDate = `${year}-${12}-${31}`
    try {
      let response = await backend.getmonthlyperformaceReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      if (response) {
        const finalreport = func.performanceReportMonthly(response, teams, year)
        // console.log("finalreport", finalreport)
        setperformanceReport(finalreport)
      }
    } catch (e) {
      console.log("get performance report", e)
    }
  }

  async function scoreCalc(reportsP: any) {
    const year = moment(date, "YYYY-MM").format("YYYY")
    const lreports = reportsP
    const op = func.monthlyMonitoringScoreCalc(
      props.selectedFactory.monthlyMonitoringForm,
      props.selectedFactory.responsibility,
      props.selectedFactory.teams,
      lreports,
      year
    )
    // console.log("ooppppp", op)

    setScore(op)
  }

  // get usersList
  useEffect(() => {
    if (count && props.selectedFactory) {
      getUsers()
      getMonthlyReport()
      getmonthlyperformaceReport()
      getMonthReportforDaily()
      setCount(false)
      let data = func.getTotalQuestionsindailyMonitoringReport(
        props.selectedFactory.dailyMonitoringForm,
        props.selectedFactory.departments
      )
      setTotalQuestionInDepart(data)
    }
  }, [count, userList, props.selectedFactory])
  let totalDepartValue = 0
  let totalResValue = 0

  if (!props.selectedFactory) {
    return <></>
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: "0px" }}>
        <Container fluid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <input
              className="form-control"
              type="month"
              onChange={e => {
                setDate(e.target.value)
              }}
              style={{ width: "20%" }}
              value={date}
              id="example-month-input"
            /> */}
            <Flatpickr
              className="form-control d-block"
              placeholder="start time"
              // style={{ margin: "2px" }}
              style={{ width: "20%" }}
              value={range}
              onChange={(e: any) => {
                // updateListValue(
                //   moment(e[0]).format("HH:mm"),
                //   "startDate",
                //   tIndex
                // )

                if (e.length === 2) {
                  // console.log("range picker", e)
                  // console.log("loggg", [
                  //   moment(e[0]).format("YYYY-MM-DD"),
                  //   moment(e[1]).format("YYYY-MM-DD"),
                  // ])
                  let startdate = moment(e[0]).format("YYYY-MM-DD")
                  let endDate = moment(e[1]).format("YYYY-MM-DD")

                  setRange([startdate, endDate])
                }
              }}
              options={{
                // enableTime: true,
                // noCalendar: true,
                // dateFormat: "H:i",
                mode: "range",
                dateFormat: "Y-m-d",
                defaultDate: ["2016-10-10", "2016-10-20"],
              }}
            />
          </div>
          <br />
          <Row style={{ justifyContent: "center" }}>
            <Col lg={6}>
              <Card>
                <CardBody className="text-center">
                  <CardTitle className="mt-0">
                    Departments Compliance Score
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-hover table-striped table-bordered mb-0">
                      <thead>
                        <tr className="table-success">
                          <th>Departments</th>
                          <th>Name</th>
                          <th> Average Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory.departments.map(
                            (depart: any, mIndex: any) => {
                              let value = 0
                              const find = _.filter(reportList, (o: any) => {
                                // console.log("e.date", o.date)

                                return moment(o.date).isBetween(
                                  range[0],
                                  range[1],
                                  undefined,
                                  "[]"
                                ) //true
                                // moment(o.date, "YYYY-MM-DD").format(
                                //   "YYYY-MM"
                                // ) == date
                              })
                              let totalScore = 0
                              const daysNumber = moment(
                                range[0],
                                "YYYY-MM"
                              ).daysInMonth();
                              let totalSelectnumber = 0
                              if (totalQuestionInDepart) {
                                totalSelectnumber =
                                  totalQuestionInDepart[depart["_id"]]
                                    .total
                              }
                              if (find.length > 0) {
                                const report = [].concat.apply(
                                  [],
                                  _.map(find, "report")
                                )
                                const filter = _.filter(report, {
                                  departmentId: depart["_id"],
                                  type: "select",
                                })
                                  .map((f: any) =>
                                    f.value ? Number(f.value.split(":")[1]) : 0
                                  )
                                  .filter(o => o == 1)
                                let sumValue = _.sum(filter)
                                const reportDayCount: any[] = find.filter((item: any) => !item.remarks);
                                // daysNumber
                                let byValue =
                                  reportDayCount.length * totalSelectnumber

                                value = Math.round(
                                  (sumValue / byValue) * 100
                                )
                                totalScore = totalScore + value
                                // if (totalQuestionInDepart) {
                                //   let depvalue =
                                //     totalQuestionInDepart[depart["_id"]]
                                //   if (depvalue) {
                                //     value = (
                                //       depvalue.divide * filter.length
                                //     ).toFixed(1)
                                //     // let totalDeptValue = 0

                                //     // totalDeptValue += Number(value)
                                //     //  console.log("depart",depart,totalDeptValue/  totalDept);


                                //   }
                                // }
                              }
                              // console.log("value", value)
                              totalDepartValue += Number(totalScore);
                              let totalDept =
                                props.selectedFactory.departments.length
                              return (
                                <React.Fragment key={mIndex}>
                                  <tr key={mIndex}>
                                    <th>{depart.name}</th>
                                    <th>{depart.holderName}</th>
                                    <td>{Math.round(value)}</td>
                                  </tr>
                                  {props.selectedFactory.departments.length ==
                                    mIndex + 1 && (
                                      <tr
                                        className="table-success"
                                        key={mIndex + 1}
                                      >
                                        <th></th>
                                        <th>Average Score</th>
                                        <td>
                                          {Math.round(
                                            (totalDepartValue / (totalDept * 100)) * 100
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                </React.Fragment>
                              )
                            }
                          )}
                        {/* totalQuestionInDepart */}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any, props: any) => {
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return { selectedFactory, user, isAdmin: isAdmin }
}

export default withRouter(connect(mapStateToProps, {})(PerformaceScore))
