import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Organization from "pages/Organization"
import OrganizationList from "pages/Organization/List";
import UsersList from "pages/Organization/UsersList"
import FactoryList from "pages/Factory/List";
import FactoryDetails from "pages/Factory/Details";
import DailyMonitoringMonthlyReport from "../pages/DailyMonitoring/MonthlyReport";
import DailyMonitoringDailyReport from "../pages/DailyMonitoring/DailyReport";
import DailyMonitoringReport from "../pages/DailyMonitoring/Report";
import MonthlyMonitoringReport from "../pages/MonthlyMonitoring";
import PerformanceReport from "../pages/MonthlyMonitoring/PerformanceReport";
import DailyWorkReport from "../pages/DailyWorkReport";
import PerformaceScore from "../pages/PerformaceScore";
import StatutoryReport from '../pages/statutoryReport'
import HealthAndSaftyReport from '../pages/HealthAndSafety'
import PerformanceReview from "pages/PerformanceReview"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/orgs", component: Organization },
  { path: "/orgs/list", component: OrganizationList },
  { path: "/orgs/list/:orgId/users", component: UsersList },
  { path: "/orgs/list/:orgId/factory/list", component: FactoryList },
  { path: "/orgs/list/:orgId/factory/:facId", component: FactoryDetails },
  { path: '/daily-monitoring', component: DailyMonitoringMonthlyReport },
  { path: '/daily-monitoring/:year/:month', component: DailyMonitoringDailyReport },
  { path: '/daily-monitoring/:year/:month/:day', component: DailyMonitoringReport },
  { path: '/monthly-monitoring', component: MonthlyMonitoringReport },
  { path: '/monthly-monitoring/:year/:month', component: PerformanceReport },
  { path: '/daily-work-report', component: DailyWorkReport },
  { path: '/performance-score', component: PerformaceScore },
  { path: '/statutory-report', component: StatutoryReport },
  { path: '/Health-Safety', component: HealthAndSaftyReport },
  { path: '/monthly-monitoring/performanceReview', component: MonthlyMonitoringReport },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
