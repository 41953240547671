import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import * as backend from "../../helpers/backend_helper";

// First, create the thunk
export const fetchFactories = createAsyncThunk(
    'factories/fetchFactoryies',
    async () => {
        const response = await backend.getAllFactoryByUsers();
        return response
    }
)

const initialState: any = {
    factories: [],
    selected: null,
}


export const FactorySlice = createSlice({
    name: 'factories',
    initialState: initialState,
    reducers: {
        setFactoryies: (state, payload: PayloadAction<any>) => {
            state.factories = payload.payload;
            if (!state.selected && payload.payload[0]) {
                state.selected = payload.payload[0];
            }
        },
        setSelectedFactory: (state, payload: PayloadAction<any>) => {
            state.selected = payload.payload;
        },
        resetFactoryies: (state) => {
            state.factories = []
            state.selected = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFactories.fulfilled, (state, action) => {
            state.factories = action.payload;
            if (!state.selected && action.payload[0]) {
                state.selected = action.payload[0];
            }
        })
    }
})


export const { setFactoryies, setSelectedFactory, resetFactoryies } = FactorySlice.actions

export default FactorySlice.reducer