import { type } from "./actionTypes"

const initialState = {
  saveHealthAndSafety: {},
}

const HealthAndSafety = (state = initialState, action) => {
  switch (action.type) {
    case type.SAVE_HEALTH_AND_SAFETY_REPORT:
      console.log("action.data", action.payload.value)
      let facId = action.payload.value.facId
      let date = action.payload.value.date

      return {
        ...state,
        saveHealthAndSafety: {
          ...state.saveHealthAndSafety,
          [facId]: {
            ...state.saveHealthAndSafety[facId],
            [date]: action.payload.value,
          },
        },
      }
    case type.REMOVE_HEALTH_AND_SAFETY_REPORT:
      console.log(
        "action",
        action,
        state.saveHealthAndSafety,
        action.paylaod.value.facId
      )


      let saveHealthAndSafety = state.saveHealthAndSafety

      let finalValue = saveHealthAndSafety[action.paylaod.value.facId]
      if(finalValue){

        delete finalValue[action.paylaod.value.date]
      }
    //  let enddate = { ...dailyWorkReportSave,finalValue }
      // console.log("finalValue", finalValue,dailyWorkReportSave)
 
      return {
        ...state,
        saveHealthAndSafety: { ...saveHealthAndSafety },
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default HealthAndSafety
