import * as _ from "lodash"
import * as moment from "moment"

export function Percentage(totalValue, currentValue, percentage = 100) {
  return ((currentValue / totalValue) * percentage).toFixed(1)
}

export function performanceReportMonthly(performanceReport, teams, year) {
  const months = moment.monthsShort()
  let teamGroup = {}
  let workGroup = {}
  let finalreport = {}
  if (performanceReport) {
    if (performanceReport.monitoring) {
      const monMap = Object.entries(
        performanceReport.monitoring
      ).map(([key, value]) => ({ key, value }))
      const group = _.groupBy(monMap, (value, key) => {
        return moment(value.key, "YYYY-MM-DD").format("YYYY-MM")
      })
      _.each(group, (value, key) => {
        const date = key.split("~")[0]
        const daysNumber = moment(date).daysInMonth()
        const divide = 30 / daysNumber
        const totalValue = (divide * value.length).toFixed(0)
        workGroup[key] = Number(totalValue)
      })
    }
    if (performanceReport.work) {
      let group = _.groupBy(performanceReport.work, (data, key) => {
        return (
          moment(data.date, "YYYY-MM-DD").format("YYYY-MM") +
          "~" +
          data.submitedBy
        )
      })
      _.each(group, (value, key) => {
        const date = key.split("~")[0]
        const daysNumber = moment(date).daysInMonth()
        const divide = 30 / daysNumber
        const totalValue = (divide * value.length).toFixed(0)
        teamGroup[key] = Number(totalValue)
      })
    }
    _.each(months, (month, mIndex) => {
      const monthNumber = moment(month, "YYYY-MMM").format("MM")
      const workGroupKey = workGroup[`${year}-${monthNumber}`]
        ? workGroup[`${year}-${monthNumber}`]
        : 0
      _.each(teams, team => {
        const teamGroupkey = teamGroup[`${year}-${monthNumber}~${team.userId}`]
          ? teamGroup[`${year}-${monthNumber}~${team.userId}`]
          : 0
        const month = `${year}-${monthNumber}`
        const value = (workGroupKey + teamGroupkey) / 2
        if (finalreport[team.userId]) {
          finalreport[team.userId][month] = value
        } else {
          finalreport[team.userId] = {
            [month]: value,
          }
        }
      })
    })
  }
  return finalreport
}

function countQuestions(teams, form, responsibility, report, year) {
  let totalNumberOfQuestions = {}
  const months = moment.monthsShort()
  _.each(teams, team => {
    if (!totalNumberOfQuestions[team.userId])
      totalNumberOfQuestions[team.userId] = {}
    _.each(form, group => {
      const findRes = _.find(responsibility, {
        groupId: group["_id"],
        userId: team.userId,
      })
      if (findRes && findRes.status) {
        _.each(group.lists, question => {
          const lmonth = _.map(question.months, "value")
          _.each(months, m => {
            const filterReport = _.filter(report, r => {
              const value = Number(r.value.split(":")[1])
              return (
                r.type == "select" &&
                r.month == m &&
                value == 1 &&
                r.groupId == group["_id"]
              )
            })
            if (!totalNumberOfQuestions[team.userId][m])
              totalNumberOfQuestions[team.userId][m] = {}
            if (!totalNumberOfQuestions[team.userId][m][group["_id"]]) {
              totalNumberOfQuestions[team.userId][m][group["_id"]] = {
                value: 0,
                selected: filterReport.length,
              }
            }
            const isMonth = lmonth.includes(m)
            if (isMonth) {
              totalNumberOfQuestions[team.userId][m][group["_id"]]["value"] += 1
            }
          })
        })
      }
    })
  })
  return totalNumberOfQuestions
}

export function monthlyMonitoringScoreCalc(
  form,
  responsibility,
  teams,
  report,
  year
) {
  let totalNumberOfQuestions = countQuestions(
    teams,
    form,
    responsibility,
    report,
    year
  )
  let result = {}
  _.each(totalNumberOfQuestions, (userId, userKey) => {
    result[userKey] = {}
    _.each(userId, (monthValue, monthKey) => {
      result[userKey][monthKey] = 0
      const mapTotalValues = _.sum(
        Object.values(
          _.mapValues(monthValue, function (o) {
            return o.value
          })
        )
      )
      const mapSelectedValues = _.sum(
        Object.values(
          _.mapValues(monthValue, function (o) {
            return o.selected
          })
        )
      )
      const divide = 20 / mapTotalValues
      const values = (divide * mapSelectedValues).toFixed(1)
      result[userKey][monthKey] = Number(values)
    })
  })
  return result
}

export function monthlyScoreOverall(performanceReportaggre, score, userId, year) {
  const months = moment.monthsShort()

  let value = 0.0
  let pReport, lscore
  if (performanceReportaggre) pReport = performanceReportaggre[userId]
  if (score) lscore = score[userId]
  if (lscore && pReport) {
    _.each(months, month => {
      const monthNumber = moment(month, "YYYY-MMM").format("MM")
      const rep = pReport[`${year}-${monthNumber}`]
      const sc = lscore[month] || 0;
      value += rep + sc
    })
  }

  return value
}


export function getTotalQuestionsindailyMonitoringReport(form, departments) {
  // console.log(form, departments);
  let final = {}
  const mergeReports = [].concat.apply([], _.map(form, "lists")).filter((o) => o.type== "select")
  _.each(departments, (depart) => {
    final[depart['_id']] = {
      total: mergeReports.length,
      divide: 10/mergeReports.length
    };
  })
  return final;
}