import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { connect } from "react-redux"
import BackButton from "../../common/ui/backButton"
import Breadcrumb from "../../components/Common/Breadcrumb2"
import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import _ from "lodash"

const PerformanceReport = (props: any) => {
  const {
    match: {
      params: { month, year },
    },
  } = props
  const daysNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()

  const days = Array(daysNumber)
    .fill(null)
    .map((data, idx) => {
      //prepand zero id digit is 1
      let date = (idx + 1).toLocaleString()
      return date.padStart(2, "0")
    })
  const [userList, setuserList] = useState([])
  const [count, setCount] = useState(true)
  const [monitoring, setMonitoring] = useState()
  const [work, setWork] = useState()

  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) {}
  }

  // get reports
  async function getmonthlyperformaceReport() {
    const { orgId, _id: facId } = props.selectedFactory
    const daysNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
    const startDate = `${year}-${month}-${"01"}`
    const endDate = `${year}-${month}-${daysNumber}`
    try {
      let response = await backend.getmonthlyperformaceReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      // console.log("responseresponseresponse", response)

      if (response) {
        if (response.monitoring) setMonitoring(response.monitoring)
        if (response.work) setWork(response.work)
      }
    } catch (e) {
      console.log("get performance report", e)
    }
  }

  // get usersList
  useEffect(() => {
    if (count) {
      getUsers()
      getmonthlyperformaceReport()
      setCount(false)
    }
  }, [count, userList])

  return (
    <React.Fragment>
      <div className="page-content">
        <BackButton history={props.history} />
        <Breadcrumb
          title={
            <div style={{ paddingLeft: "12px" }}>
              {" "}
              <h4 className="mb-0 font-size-18">Performance Monitoring</h4>{" "}
              <p style={{ fontSize: "12px", marginTop: "3px" }}>
                {/* (Total Days Eligible for Salary) */}
              </p>
            </div>
          }
          breadcrumbItems={[
            { title: year, to: "/monthly-monitoring" },
            { title: moment(month, "MM").format("MMMM") },
          ]}
        ></Breadcrumb>
        <Container fluid>
          {/* <div > <h4 className="mb-0 font-size-18">Performance Monitoring</h4> <p style={{fontSize:"12px",marginTop:"3px"}}>(Total Days Eligible for Salary)</p></div> */}
          {props.selectedFactory &&
            props.selectedFactory.teams &&
            props.selectedFactory.teams.map((team, tIndex) => {
              const currentUser =  props.user['_id'] === team['userId'];
              if(!currentUser && !props.isAdmin) {
                return null;
              }
              const find: any = _.find(userList, ["_id", team.userId])
              const divide = 30 / daysNumber
              let totalmonitoringValue = 0
              let totalworkValue = 0
              return (
                <Row key={tIndex}>
                  <Col lg={12}>
                    <Card style={{ padding: "10px" }}>
                      {find && (
                        <CardTitle className="mb-4">
                          {find && find.name} ({find.role})
                        </CardTitle>
                      )}
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table table-hover  mb-0">
                            <thead>
                              <tr>
                                <th>Description</th>
                                {days.map((m, mIndex) => {
                                  return <th key={mIndex}>{m}</th>
                                })}
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ textAlign: "center" }}>
                                <th scope="row">Onsite Inspection</th>
                                {days.map((m, mIndex) => {
                                  let value = 0
                                  if (monitoring) {
                                    const date = `${year}-${month}-${m}`
                                    if (
                                      (monitoring[date] &&
                                        monitoring[date] === "H") ||
                                      monitoring[date] === "W" ||
                                      monitoring[date] === "A"
                                    ) {
                                      value = monitoring[date]
                                    } else {
                                      value = monitoring[date] ? 1 : 0
                                    }
                                    if (typeof value === "number") {
                                      totalmonitoringValue += value
                                    }
                                  }
                                  return <td key={mIndex}>{value}</td>
                                })}
                                <td>
                                  {(divide * totalmonitoringValue).toFixed(0)}
                                </td>
                              </tr>
                              <tr style={{ textAlign: "center" }}>
                                <th scope="row">Work Report</th>
                                {days.map((m, mIndex) => {
                                  let value = 0
                                  if (work) {
                                    // console.log("Daily Work Report", work)
                                    const _work = _.map(work, (d: any) => {
                                      const date = moment(
                                        d.date,
                                        "YYYY-MM-DD"
                                      ).format("YYYY-MM-DD");
                                      return {...d, date: date}
                                    })
                                    const date = `${year}-${month}-${m}`
                                    const day = moment(
                                      date,
                                      "YYYY-MM-DD"
                                    ).format("YYYY-MM-DD")
                                    const find = _.find(_work, {
                                      submitedBy: team.userId,
                                      date: day,
                                    })
                                    if (find) {
                                      // console.log("findin monthlyreport", find)
                                      if (
                                        (find.remarks &&
                                          find.remarks === "W") ||
                                        find.remarks === "H" ||
                                        find.remarks === "A"
                                      ) {
                                        value = find.remarks
                                      } else {
                                        value = 1
                                        totalworkValue += value
                                      }
                                    }
                                  }
                                  return <td key={mIndex}>{value}</td>
                                })}
                                <td>{(divide * totalworkValue).toFixed(0)}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )
            })}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state, props) => {
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return { selectedFactory, user, isAdmin: isAdmin }
}

export default withRouter(connect(mapStateToProps, {})(PerformanceReport))
