import { combineReducers } from "redux"
import dailymonitroing from "./daily-monitoring/reducers"
import dailyWorkReport from "./dailyWorkReport/reducers"

// Front
import Layout from "./layout/reducer"
import authSlice from "./slice/authSlice"
import dailymontoringSlice from "./slice/dailymonitoring.slice"
import FactorySlice from "./slice/factory.slice"
import satutoryReport from './satutoryReport/reducers'

import healthAndSafety from './healthAndSafety/reducers'

const rootReducer = combineReducers({
  // public
  Layout,
  factory: FactorySlice,
  user: authSlice,
  dailymontoringSlice: dailymontoringSlice,
  dailymonitroing,
  dailyWorkReport,
  satutoryReport,
  healthAndSafety,
})

export default rootReducer
