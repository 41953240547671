import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form,
  Input
} from "reactstrap"
import * as moment from "moment"
import * as _ from "lodash"
import { desOptions } from "./const"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// import { withRouter } from "react-router-dom"
// import { connect } from "react-redux"

// import * as backend from "../../helpers/backend_helper"
// import Toaster from "../../components/Common/Toaster"
// import { saveLocalDailyworkReport } from "../../store/actions"

const ReportPrint = React.forwardRef((props: any, ref: any) => {
  // console.log("props", props)
  let { submitedBy, userList, date, timeList, scopeList, Props } = props
  return (
    <React.Fragment>
       <div  className="page-content" ref={ref} >
        <Container fluid>
         
          <Row>
            <Col lg={2}></Col>
            <Col lg={8}>
              <Card>
                <CardBody>

                  <Form>
                    <FormGroup className="row mb-4" >
                      <Label
                        for="horizontal-firstname-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Name
                      </Label>
                      <Col sm={9}>
                      
                        {Props.isAdmin ? (
                          <Input
                            onChange={e => {
                              // setSubmitedBy(e.target.value)
                              // getReport(null, e.target.value)
                            }}
                            type="select"
                            name="selectMulti"
                            value={submitedBy ? submitedBy : ""}
                            placeholder={"Select here"}
                          >
                            <option></option>
                            {Props.selectedFactory &&
                              Props.selectedFactory.teams &&
                              Props.selectedFactory.teams.map(
                                (team, tIndex) => {
                                  const find = _.find(userList, [
                                    "_id",
                                    team.userId,
                                  ])
                                  if (find) {
                                    return (
                                      <option value={team.userId} key={tIndex}>
                                        {find && find.name}({find.role})
                                      </option>
                                    )
                                  }
                                }
                              )}
                          </Input>
                        ) : (
                          <Input
                            readOnly
                            type="text"
                            
                            value={`${Props.user.name}(${Props.user.role})`}
                            placeholder={"Select here"}
                          ></Input>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Date
                      </Label>
                      <Col sm={9}>
                        <input
                          className="form-control"
                          type="date"
                          onChange={e => {
                            // setDate(e.target.value)
                            // getReport(e.target.value)
                          }}
                          value={date}
                          id="example-date-input"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row mb-4">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-3 col-form-Label"
                      >
                        Factory Name
                      </Label>
                      <Col sm={9}>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={Props.selectedFactory.name}
                          id="example-date-input"
                        />
                      </Col>
                    </FormGroup>
                    <Row>
                      
                      <Col lg={5}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">Time</Label>
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">Scope</Label>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            Work Description
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {timeList.map((time, tIndex) => {
                      const findIndex = _.findIndex(
                        timeList,
                        (value, index) => index == tIndex
                      )
                      let find,
                        desList = []
                      if (findIndex > -1) {
                        find = timeList[findIndex]
                         if (find.scope && scopeList && scopeList) {
                              const _filterScope = _.find(scopeList, ['name', find.scope]);
                              desList = (_filterScope) ? _filterScope.lists : []//desOptions[find.scope]
                            }
                      }
                      return (
                        <Row key={tIndex}>
                          <Col lg={5}>
                            <div style={{ display: "flex" }}>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="start time"
                                style={{ margin: "2px" }}
                                value={
                                  find && find.startDate ? find.startDate : ""
                                }
                                onChange={e => {}
                                  // updateListValue(
                                  //   moment(e[0]).format("HH:mm"),
                                  //   "startDate",
                                  //   tIndex
                                  // )
                                }
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                }}
                              />
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="end time"
                                value={find && find.endDate ? find.endDate : ""}
                                style={{ margin: "2px" }}
                                onChange={e => { }
                                  // updateListValue(
                                  //   moment(e[0]).format("HH:mm"),
                                  //   "endDate",
                                  //   tIndex
                                  // )
                                }
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <Input
                              onChange={e => {}
                                // updateListValue(e.target.value, "scope", tIndex)
                              }
                              type="select"
                              name="selectMulti"
                              value={find && find.scope ? find.scope : ""}
                              placeholder={"Select here"}
                            >
                              <option></option>
                              {scopeList &&
                                scopeList.map((op, index) => (
                                  <option value={op.name} key={index}>
                                    {op.name}
                                  </option>
                                ))}
                            </Input>
                          </Col>
                          <Col lg={3}>
                            {
                              <Input
                                onChange={e => { }
                                  // updateListValue(
                                  //   e.target.value,
                                  //   "desc",
                                  //   tIndex
                                  // )
                                }
                                type="select"
                                value={find && find.desc ? find.desc : ""}
                                name="selectMulti1"
                                placeholder={"Select here"}
                              >
                                <option></option>
                                {desList &&
                                  desList.map((op: any, index) => (
                                    <option value={op.name} key={index}>
                                      {op.name}
                                    </option>
                                  ))}
                              </Input>
                            }
                          </Col>
                        </Row>
                      )
                    })}
                   
                  </Form>

                
                </CardBody>
              </Card>
            </Col>
            <Col lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
})
ReportPrint.displayName="ReportPrint"
export default ReportPrint
