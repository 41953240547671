import { type } from "./actionTypes"

const initialState = {
  saveSatutoryReport: {},
}

const SatutoryReport = (state = initialState, action) => {
  switch (action.type) {
    case type.SAVE_SATUTORY_REPORT:
      console.log("action.data", action.payload.value)
      let facId = action.payload.value.facId
      let date = action.payload.value.date

      return {
        ...state,
        saveSatutoryReport: {
          ...state.saveSatutoryReport,
          [facId]: {
            ...state.saveSatutoryReport[facId],
            [date]: action.payload.value,
          },
        },
      }
    case type.REMOVE_SAVE_SATUTORY_REPORT:
      // console.log(
      //   "action",
      //   action,
      //   state.dailyWorkReportSave,
      //   action.paylaod.value.facId
      // )

      let saveSatutoryReport = state.saveSatutoryReport

      let finalValue = saveSatutoryReport[action.paylaod.value.facId]
      if(finalValue){
        delete finalValue[action.paylaod.value.date]
      }
    //  let enddate = { ...dailyWorkReportSave,finalValue }
      // console.log("finalValue", finalValue,dailyWorkReportSave)
 
      return {
        ...state,
        saveSatutoryReport: { ...saveSatutoryReport },
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default SatutoryReport
