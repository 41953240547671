import React, { useState, useEffect } from "react"
import { Form, Modal } from "reactstrap"

const DeleteAlert = (props: any) => {
  return (
    <Modal
      isOpen={props.visible}
      toggle={() => {
        props.toggleAlert()
      }}
    >
      <Form
        className="form-horizontal"
        onSubmit={() => {
          props.Submit()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.Title}
          </h5>
          <button
            type="button"
            onClick={() => {
              props.toggleAlert()
           
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
         {props.Message}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.toggleAlert()
           
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Ok
          </button>
        </div>
      </Form>
    </Modal>
  )
}

export default DeleteAlert
