import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import  moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import _ from "lodash"

const PerformaceScore = (props: any) => {
  const today = moment().format("YYYY-MM")
  const [userList, setuserList] = useState([])
  const [count, setCount] = useState(true)
  const [date, setDate] = useState(today)
  const [score, setScore] = useState<any>()
  const [reportList, setReportList] = useState([])
  const [performanceReport, setperformanceReport] = useState<any>()
  const [totalQuestionInDepart, setTotalQuestionInDepart] = useState<any>()
  async function getUsers() {
    try {
      const orgId = props.selectedFactory["orgId"]
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) {}
  }

  async function getMonthlyReport() {
    const { orgId, _id: facId } = props.selectedFactory
    const year = moment(date, "YYYY-MM").format("YYYY")
    try {
      let response = await backend.getmonthlyMonitoringReport(
        orgId,
        facId,
        year
      )
      if (response && response.report) {
        scoreCalc(response.report)
      }
    } catch (e) {}
  }

  async function getMonthReportforDaily() {
    try {
      const year = moment(date, "YYYY-MM").format("YYYY")
      const startDate = `${year}-${"01"}-${"01"}`
      const endDate = `${year}-${12}-${31}`
      const { orgId, _id: facId } = props.selectedFactory
      const response = await backend.getdailymonitoringMonthReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      setReportList(response)
    } catch (e) {
      console.log(e)
    }
  }

  // get reports
  async function getmonthlyperformaceReport() {
    const year = moment(date, "YYYY-MM").format("YYYY")
    const { orgId, _id: facId, teams } = props.selectedFactory
    const startDate = `${year}-${"01"}-${"01"}`
    const endDate = `${year}-${12}-${31}`
    try {
      let response = await backend.getmonthlyperformaceReport(
        orgId,
        facId,
        startDate,
        endDate
      )
      if (response) {
        const finalreport = func.performanceReportMonthly(response, teams, year)
        setperformanceReport(finalreport)
      }
    } catch (e) {
      console.log("get performance report", e)
    }
  }

  async function scoreCalc(reportsP: any) {
    const year = moment(date, "YYYY-MM").format("YYYY")
    const lreports = reportsP
    const op = func.monthlyMonitoringScoreCalc(
      props.selectedFactory.monthlyMonitoringForm,
      props.selectedFactory.responsibility,
      props.selectedFactory.teams,
      lreports,
      year
    )
    setScore(op)
  }

  // get usersList
  useEffect(() => {
    if (count && props.selectedFactory) {
      getUsers()
      getMonthlyReport()
      getmonthlyperformaceReport()
      getMonthReportforDaily()
      setCount(false)
      let data = func.getTotalQuestionsindailyMonitoringReport(
        props.selectedFactory.dailyMonitoringForm,
        props.selectedFactory.departments
      )
      setTotalQuestionInDepart(data)
    }
  }, [count, userList, props.selectedFactory])
  let totalDepartValue = 0
  let totalResValue = 0

  if (!props.selectedFactory) {
    return <></>
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{padding:"0px"}}>
        <Container fluid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              className="form-control"
              type="month"
              onChange={e => {
                setDate(e.target.value)
              }}
              style={{ width: "auto" }}
              value={date}
              id="example-month-input"
            />
          </div>
          <br />
          <Row style={{justifyContent:"center"}}>
            <Col lg={6}>
              <Card>
                <CardBody className="text-center">
                  <CardTitle className="mt-0">Team Performance Score</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-hover table-striped table-bordered mb-0">
                      <thead>
                        <tr className="table-success">
                          <th>Name (Designation)</th>
                          <th>Average Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory.teams.map((team: any, mIndex: number) => {
                            const divide =
                              100 / (props.selectedFactory.teams.length * 100)
                            const find: any = _.find(userList, ["_id", team.userId])
                            const month = moment(date, "YYYY-MM").format("MMM")
                            let value = 0
                            if (score && performanceReport) {
                              const user = score[team.userId]
                              const preport = performanceReport[team.userId]
                              if (user) {
                                value += user[month] ? user[month] : 0
                                value += preport[date] ? preport[date] : 0
                                totalResValue += value
                              }
                            }
                            if (find && (team.userId === props.user['_id'] || props.isAdmin)) {
                              // console.log("findfindfind",find);
                              return (
                                <React.Fragment key={mIndex}>
                                  <tr key={mIndex}>
                                    <th key={mIndex}>
                                      {find.name} ({find.role})
                                    </th>
                                    <td>{Math.round(value)}</td>
                                  </tr>
                                  {props.selectedFactory.teams.length ==
                                    mIndex + 1 && (
                                    <tr
                                      className="table-success"
                                      key={mIndex + 1}
                                    >
                                      <th>Average Score</th>
                                      <td>
                                        {Math.round(
                                          Number((divide * totalResValue).toFixed(1))
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              )
                            } else {
                              return null;
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any, props: any) => {
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return { selectedFactory, user, isAdmin: isAdmin }
}

export default withRouter(connect(mapStateToProps, {})(PerformaceScore))
