import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import moment from "moment"
import _ from "lodash"

const ExternalMonitoring = (props: any) => {
  return (
    <React.Fragment>
      <tr className="table-info" style={{ textAlign: "center" }}>
        <th colSpan={12 + 1} scope="row">
          Performance Score
        </th>
      </tr>
      {props.teams &&
        props.teams.map((team, teamIndex) => {
          const find = _.find(props.userList, ["_id", team.userId])
          let pReport,
            score
          if (props.performanceReport)
            pReport = props.performanceReport[team.userId]
          if (props.score) score = props.score[team.userId]
          // if (find)
          // console.log("find",find,props);
          
            return (
              <tr key={teamIndex}>
                <th scope="row">
                  {find.name} ({find.role})
                </th>
                {props.months.map((month, mIndex) => {
                  const monthNumber = moment(month, "YYYY-MMM").format("MM")
                  let value = 0
                  // console.log("pReport && score",pReport , score);
                  if(pReport && score && Object.keys(score).length>0) {
                    
                    const rep = pReport[`${props.year}-${monthNumber}`]
                    const sc = score[month]
                    value = rep + sc
                  }
                  if (props.monthlyInspection) {
                    props.monthlyInspection.map((data, i) => {
                      let values: any = Object.values(data)[0];
                      const monthNumber = moment(month, "YYYY-MMM").format("MM")
                      const date = `${props.year}-${monthNumber}-${`01`}`
                      let report = values.filter(findData => {
                        return (
                          moment(findData.date).format("YYYY-MM-DD") ===
                          moment(date).format("YYYY-MM-DD")
                        )
                      })
                      if (report.length > 0) {
                        value += 25
                      } else {
                        if (
                          moment(date).format("YYYY-MM-DD") <
                          moment().format("YYYY-MM-DD")
                        ) {
                          value += 0
                        }
                      }
                    })
                  }
                  return <td key={mIndex}>{Math.ceil(value)}</td>
                })}
              </tr>
            )
        })}
    </React.Fragment>
  )
}

export default ExternalMonitoring
