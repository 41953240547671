import React, { useState, useEffect } from "react"
import { Col, Modal, Container, Row, Table, Form, Alert, Input } from "reactstrap"
import { connect } from "react-redux"
import { map } from "lodash"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import Breadcrumb from "../../components/Common/Breadcrumb2"
import BackButton from "../../common/ui/backButton"
import DeleteAlert from "../../common/ui/customAlert"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { setFactoryies } from "store/slice/factory.slice"

const FactoryList = (props: any) => {
  const {
    match: {
      params: { orgId },
    },
  } = props
  const [modal_standard, setmodal_standard] = useState(false)
  const [lists, setList] = useState<any[]>([])
  const [edit, setEdit] = useState<any>(null)
  const [count, setCount] = useState(true)
  const [duplicate, setDuplicate] = useState<any>(null)

  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false)
  const [deleteValue, setDeleteValue] = useState({})

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  // handleValidSubmit
  const handleValidSubmit = async (values) => {
    if (edit) {
      update(values)
    } else if (duplicate) {
      onClone(duplicate, values.name)
    } else {
      save(values)
    }
  }

  const update = async values => {
    try {
      await backend.updateFactory(orgId, edit["_id"], values)
      props.saveUserFactory()
      getList()
    } catch (e) {
      console.log(e)
    } finally {
      tog_standard()
    }
  }

  const onClone = async (value, name) => {
    try {
      // await backend.cloneFactory(orgId, value['_id'], {name: value.name + '- clone'})
      await backend.cloneFactory(orgId, value["_id"], { name:value.name===name? value.name + '- clone' :name})
      getList();
    } catch (e) {
      console.log(e)
    } finally {
      tog_standard()
    }
  }

  const save = async values => {
    try {
      await backend.saveFactory(orgId, values)
      props.saveUserFactory()
      getList()
    } catch (e) {
      console.log(e)
    } finally {
      tog_standard()
    }
  }

  const getList = async () => {
    try {
      const response = await backend.getFactory(orgId)
      setList(response)
    } catch (e) {}
  }

  useEffect(() => {
    if (lists.length == 0 && count) {
      getList()
      setCount(false)
    }
  }, [lists, count])

  //on edit
  const onEdit = (e, value) => {
    e.stopPropagation()
    setEdit(value)
    validation.setValues(value);
    setDuplicate(null)
    tog_standard()
  }

  //rowClick
  const rowClick = async (e, value) => {
    if (value && value.permission.includes("ADMIN"))
      props.history.push(`/orgs/list/${orgId}/factory/${value["_id"]}`)
  }
  // on onDelete
  const DeleteOrgApi = async value => {
    // e.stopPropagation();
    try {
      await backend.deleteFactory(orgId, value["_id"])
      props.saveUserFactory()
      setDeleteValue({})
      getList()
    } catch (e) {}
  }
  // on onDelete
  const onDelete = async (e, value) => {
    e.stopPropagation()

    toggleDeleteAlert()
    setDeleteValue(value)
  }

  const Submit = () => {
    DeleteOrgApi(deleteValue)
    toggleDeleteAlert()
  }

  const toggleDeleteAlert = () => {
    setDeleteAlertVisible(!deleteAlertVisible)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
    }),
    onSubmit: (values) => {
      handleValidSubmit(values);
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={<BackButton history={props.history} />}
            breadcrumbItems={[
              { title: "Organization", to: "/orgs/list" },
              { title: "Factory" },
            ]}
          ></Breadcrumb>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Factory</h4>
            {props.user && !props.user.orgId && (
              <button
                type="button"
                onClick={() => {
                  setEdit(null)
                  setDuplicate(null)
                  validation.resetForm();
                  tog_standard()
                }}
                className="btn btn-primary waves-effect waves-light"
                data-toggle="modal"
                data-target="#myModal"
              >
                Create New
              </button>
            )}
          </div>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th scope="col">Name</th>
                        {/* <th scope="col">Due Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Team</th> */}
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(lists, (list, index) => (
                        <tr
                          onClick={e => rowClick(e, list)}
                          key={index}
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <td>{list.name}</td>
                          <td>
                            {list.permission.includes("ADMIN") && (
                              <div className="org_action">
                                <i
                                  className="dripicons-copy"
                                  onClick={e => {
                                    e.stopPropagation()
                                    setEdit(null)
                                    setDuplicate(list)
                                    validation.resetForm();
                                    tog_standard()
                                  }}
                                ></i>
                                <i
                                  className="bx bx-cog"
                                  onClick={e => rowClick(e, list)}
                                ></i>
                                {/* <i
                                  className="bx bx-collection"
                                  onClick={e => onClone(e, list)}
                                ></i> */}
                                <i
                                  className="bx bx-edit-alt"
                                  onClick={e => onEdit(e, list)}
                                ></i>
                                <i
                                  className="bx bx-trash-alt"
                                  onClick={e => onDelete(e, list)}
                                ></i>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteAlert
        visible={deleteAlertVisible}
        Title={"Delete Factory"}
        Message={"Do you Want to Delete Factory?"}
        toggleAlert={toggleDeleteAlert}
        Submit={Submit}
      />

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {duplicate
                ? `Duplicate of "${duplicate.name}"`
                : edit
                ? "Edit Factory"
                : "Create New Factory"}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.error && typeof props.error === "string" ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="form-group">
              <Input
                name="name"
                label="Name"
                value={validation.values.name}
                onChange={validation.handleChange}
                className="form-control"
                placeholder="Enter Org Name"
                type="text"
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.user
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { saveUserFactory: setFactoryies })(FactoryList)
)
