import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Input, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Select from "react-select"
import moment from 'moment';

import * as backend from "../../helpers/backend_helper"
import EditableColumn from "./EditableColumn"
import Toaster from "../../components/Common/Toaster"

const MonthlyMonitoring = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
    },
  } = props
  const months = moment.monthsShort().map(m => {
    return { label: m, value: m }
  })
  // console.log("monthsmonthsmonths",months);
  
  const [monthly, setMonthly] = useState<any[]>([])
  // add group
  function addGroup() {
    setMonthly([...monthly, { name: null, lists: [] }])
  }

  // remove group
  function removeGroup(idx) {
    const cloneList = [...monthly]
    cloneList.splice(idx, 1)
    setMonthly([...cloneList])
  }

  // add question list
  function addSubList(id) {
    const cloneList = [...monthly]
    cloneList[id].lists = [
      ...cloneList[id].lists,
      { name: null, type: "select", months: months },
    ]
    setMonthly([...cloneList])
  }
  // update group value
  function updateGroupValue(e, key, idx) {
    const cloneList = [...monthly]
    cloneList[idx][key] = e
    setMonthly([...cloneList])
  }

  // update sub list
  function updateListValue(e, key, idx, liIndex) {
    const cloneList = [...monthly]
    const list = cloneList[idx].lists[liIndex]
    if (list) {
      list[key] = e
    }
    setMonthly([...cloneList])
  }

  //deleteListValue
  function deleteListValue(idx, liIndex) {
    const cloneList = [...monthly]
    const list = cloneList[idx].lists
    list.splice(liIndex, 1)
    setMonthly([...cloneList])
  }

  // on submit
  async function submit() {
    try {
      await backend.updateFactory(orgId, facId, {
        monthlyMonitoringForm: monthly,
      })
      props.update()
      Toaster.success("Monthly Monitoring Updated Success")
    } catch (e) {
      console.log("monthly monitoring error", e)
      Toaster.error("Monthly Monitoring Updated Failed")
    }
  }

  // update from props
  useEffect(() => {
    setMonthly(props.form)
  }, [props.form])

  return (
    <React.Fragment>
      <Container fluid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>Monthly Monitoring</h4>
          <button
            onClick={addGroup}
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            New Group
          </button>
        </div>
        <br />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <ul
                  className="list-unstyled"
                  style={{ height: "60vh", overflow: "auto" }}
                >
                  {monthly.map((month, idd) => (
                    <li key={idd}>
                      <div
                        className="factory-monitoring-group-list"
                        style={{ textAlign: "start" }}
                      >
                        <Row>
                          <Col md={5}>
                            <EditableColumn
                              value={month.name}
                              update={e => updateGroupValue(e, "name", idd)}
                            />
                          </Col>
                          <Col md={5}>
                            <Input
                              onChange={e =>
                                updateGroupValue(
                                  e.target.value,
                                  "resIdentifier",
                                  idd
                                )
                              }
                              value={
                                month.resIdentifier ? month.resIdentifier : ""
                              }
                              placeholder="Responsiblity Identifier"
                              type="text"
                              name="text"
                            ></Input>
                          </Col>

                          <Col lg={1} md={1}>
                            <button
                              onClick={e => addSubList(idd)}
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              ADD
                            </button>
                          </Col>
                          <Col md={1}>
                            <button
                              onClick={e => removeGroup(idd)}
                              type="button"
                              className="btn btn-danger waves-effect waves-light"
                            >
                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <ul className="list-unstyled">
                        {month.lists.map((sublist, subIndex) => (
                          <li key={subIndex} style={{ padding: "10px", border: '1px solid', margin: '2px' }}>
                            <Row>
                              <Col lg={4}>
                                <EditableColumn
                                  value={sublist.name ? sublist.name : ""}
                                  update={e =>
                                    updateListValue(e, "name", idd, subIndex)
                                  }
                                />
                              </Col>
                              <Col lg={3}>
                                <Input
                                  onChange={e =>
                                    updateListValue(
                                      e.target.value,
                                      "date",
                                      idd,
                                      subIndex
                                    )
                                  }
                                  value={sublist.date ? sublist.date : ""}
                                  placeholder="date"
                                  max={31}
                                  type="number"
                                  name="number"
                                ></Input>
                              </Col>
                              <Col lg={4}>
                                <Input
                                  onChange={e =>
                                    updateListValue(
                                      e.target.value,
                                      "type",
                                      idd,
                                      subIndex
                                    )
                                  }
                                  value={sublist.type}
                                  type="select"
                                  name="selectMulti"
                                  id="exampleSelectMulti"
                                >
                                  <option></option>
                                  <option value="input"> Input </option>
                                  <option value="select"> Select </option>
                                </Input>
                              </Col>
                              <Col lg={1}>
                                <button
                                  onClick={e => deleteListValue(idd, subIndex)}
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                </button>
                              </Col>
                              <Col lg={4}></Col>
                              <Col lg={5}>
                                <Select
                                  value={sublist.months}
                                  isMulti={true}
                                  onChange={(e) => {
                                    updateListValue(
                                      e,
                                      "months",
                                      idd,
                                      subIndex
                                    )
                                  }}
                                  options={months}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                {monthly.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="success">
                      Submit
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

export default withRouter<any, any>(connect<any>(mapStateToProps, {})(MonthlyMonitoring))
