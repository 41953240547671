
import {type} from './actionTypes'

export function saveLocalDailyworkReport(data) {
    console.log("dataaaaaaaaaa in action",data);
    
    return {
        type: type.SAVE_LOCAL_DAILY_WORK_REPORT,
        payload: {value: data}
    }
}

export function removeSaveReport(data){

    return {
        type:type.REMOVE_SAVE_REPORT,
        paylaod:{value:data}
    }
}