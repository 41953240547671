import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap"
import classnames from "classnames"

import DailyMonitoring from "./DailyMonitoring"
import MonthlyMonitoring from "./MonthlyMonitoring"
import Responsibility from "./Responsibility"
import WorkReportScope from "./WorkReportScope"

const FactoryForms = (props: any) => {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  // const [monthlyActiveTab, setmonthlyActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col md="2">
            <Nav vertical tabs className="nav-tabs-custom">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                >
                  <span className="d-none d-sm-block">Onsite Inspection</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2")
                  }}
                >
                  <span className="d-none d-sm-block">Work Report</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="10">
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="1" className="p-3">
                <Row>
                  <Col sm="12">
                    {props.factory && props.factory.dailyMonitoringForm && (
                      <DailyMonitoring
                        update={() => props.update()}
                        form={props.factory.dailyMonitoringForm}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                {customActiveTab == "2" && (
                  <Row>
                    <Col sm="12">
                      <WorkReportScope {...props} />
                    </Col>
                  </Row>
                )}
                
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default FactoryForms
