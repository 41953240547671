import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap"
import StatutoryReport from "../StatutoryReport"
import HealthSafetyReport from "../HealthSafetyReport"
import classnames from "classnames"
import WorkReportScope from '../WorkReportScope';
import EditableColumn from "../EditableColumn"
import Toaster from "../../../components/Common/Toaster"
import * as backend from "../../../helpers/backend_helper"
import { withRouter } from "react-router"
import { useDispatch } from "react-redux"
import { fetchFactories } from "store/slice/factory.slice"
import MonthlyMonitoring from "../MonthlyMonitoring"
import Responsibility from "../Responsibility"

const LeftNavReport = (props: any) => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { orgId, facId },
    },
  } = props
  const [customActiveTab, setcustomActiveTab] = useState("3");
  const [statutoryName, setstatutoryName] = useState((props.factory && props.factory.statutoryName) ? props.factory && props.factory.statutoryName : 'Documents Review')
  const [healthSafetyName, sethealthSafety] = useState((props.factory && props.factory.healthSafetyName) ? props.factory && props.factory.healthSafetyName : 'Onsite Inspection')
  const [monthlyActiveTab, setmonthlyActiveTab] = useState("1")
  function toggleCustom(tab: any) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  function toggleMonthly(tab) {
    if (monthlyActiveTab !== tab) {
      setmonthlyActiveTab(tab)
    }
  }

  async function submit(payload: any) {
    try {
      await backend.updateFactory(orgId, facId, payload);
      dispatch(fetchFactories())
      Toaster.success("Name Updated Success")
    } catch (e) {
      console.log("Name error", e)
      Toaster.error("Name Updated Failed")
    }
    // console.log(reports)
  }
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col md="2">
            <Nav vertical tabs className="nav-tabs-custom">

              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2")
                  }}
                >
                  {/* <EditableColumn
                    keyPress={() => {
                      submit({
                        healthSafetyName: healthSafetyName
                      })
                    }}
                    value={healthSafetyName}
                    update={(e: any) => {
                      sethealthSafety(e);
                    }}
                  /> */}
                  <span className="d-none d-sm-block">Onsite Inspection</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                >
                  {/* <EditableColumn
                    keyPress={() => {
                      submit({
                        statutoryName: statutoryName
                      })
                    }}
                    value={statutoryName}
                    update={(e: any) => {
                      setstatutoryName(e);
                    }}
                  /> */}
                  <span className="d-none d-sm-block">Document Review</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3")
                  }}
                >
                  <span className="d-none d-sm-block">Work Report</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="10">
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="1" className="p-3">
                <Row>
                  <Col sm="12">
                    <StatutoryReport {...props} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                {customActiveTab == "2" && (
                  <Row>
                    <Col sm="12">
                      <HealthSafetyReport {...props} />
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="3" className="p-3">
                {customActiveTab == '3' && <Row>
                  <Col sm="12">
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: monthlyActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleMonthly("1")
                          }}
                        >
                          <span className="d-none d-sm-block">Report</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: monthlyActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleMonthly("2")
                          }}
                        >
                          <span className="d-none d-sm-block">
                            Responsibility
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={monthlyActiveTab}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            {props.factory && monthlyActiveTab == '1' &&
                              props.factory.monthlyMonitoringForm && (
                                <MonthlyMonitoring
                                  update={() => props.update()}
                                  form={props.factory.monthlyMonitoringForm}
                                />
                              )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <Row>
                          <Col sm="12">
                            {props.factory && props.factory.responsibility && (
                              <Responsibility
                                update={() => props.update()}
                                teams={props.factory.teams}
                                responsibility={props.factory.responsibility}
                                form={props.factory.monthlyMonitoringForm}
                              />
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
                }
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(LeftNavReport)
