//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

/**Sustain end points */

//LOGIN

export const LOGIN = "/auth/login"
export const SIGNUP = "/auth/signup"
export const GET_USER = "/user"

// ORGS

export const SAVE_ORGS = "/org"
export const GET_ORGS = "/orgs"
export const UPDATE_ORGS = "/org/"
export const DELETE_ORGS = "/org/"
export const SAVE_ORG_USERS = (orgId) => `/org/${orgId}/user`
export const GET_ORG_USERS = (orgId) => `/org/${orgId}/user`
export const GET_ORG_USERS_BYID = (orgId, userId) => `/org/${orgId}/user/${userId}`
export const UPDATE_ORG_USERS = (orgId,userId) => `/org/${orgId}/user/${userId}`



// Factory
export const SAVE_FACTORY = (orgId) => `org/${orgId}/factory`
export const GET_FACTORY = (orgId) => `org/${orgId}/factory`
export const GET_ALL_FACTORY_BYUSER = `factory`
export const GET_FACTORY_BYID = (orgId, facId) => `org/${orgId}/factory/${facId}`
export const CLONE_FACTORY_BYID = (orgId, facId) => `org/${orgId}/factory/${facId}/clone`
export const UPDATE_FACTORY = (orgId, facId) => `org/${orgId}/factory/${facId}`
export const DELETE_FACTORY = (orgId, facId) => `org/${orgId}/factory/${facId}`
export const DELETE_FACTORY_USER = (orgId, facId, userId) => `org/${orgId}/factory/${facId}/user/${userId}`
export const UPDATE_FACTORY_USER_ACTIVITY = (orgId, facId, userId) => `org/${orgId}/factory/${facId}/user/${userId}/mode`

export const ADD_FACTORY_USER = (orgId, facId, userId) => `org/${orgId}/factory/${facId}/user/${userId}`

export const FILE_UPLOAD = () => `/file`;
export const FILE_UPLOAD_DELETE = (id) => `/file/${id}`

// daily monitoring report
export const DAILY_MONITORING_REPORT = (orgId, facId) => `/org/${orgId}/factory/${facId}/dailymonitoring/image`
export const GET_DAILY_MONITORING_REPORT =  (orgId, facId, date) => `/org/${orgId}/factory/${facId}/dailymonitoring/${date}`
export const SAVE_DAILY_MONITORING_REPORT =  (orgId, facId, date) => `/org/${orgId}/factory/${facId}/dailymonitoring/${date}`
export const GET_DAILY_MONITORING_DAY_REPORT = (orgId, facId, startDate, endDate) => `/org/${orgId}/factory/${facId}/dailymonitoring/dayreport?startDate=${startDate}&endDate=${endDate}`
export const GET_DAILY_MONITORING_MONTH_REPORT = (orgId, facId, startDate, endDate) => `/org/${orgId}/factory/${facId}/dailymonitoring/monthreport?startDate=${startDate}&endDate=${endDate}`
export const DELETE_IMAGES=(orgId, facId)=>`/org/${orgId}/factory/${facId}/dailymonitoring/imageDelete`

// daily work reports
export const GET_DAILY_WORK_REPORT = (orgId, facId, date, submitedBy) => `/org/${orgId}/factory/${facId}/dailyworkreport/${date}?submitedBy=${submitedBy}`
export const SAVE_DAILY_WORK_REPORT = (orgId, facId, date) => `/org/${orgId}/factory/${facId}/dailyworkreport/${date}`
export const  GET_DAILY_WORK_REPORT_ANALYSIS=(orgId,
    facId,
    startDate,
    endDate)=>`/org/${orgId}/factory/${facId}/dailyworkreportanalysis?startDate=${startDate}&endDate=${endDate}`

// monthly Monitorining
export const SAVE_MONTHLY_MONITROING = (orgId, facId, date)  => `/org/${orgId}/factory/${facId}/monthlymonitoring/${date}`
export const GET_MONTHLY_MONITROING = (orgId, facId, date)  => `/org/${orgId}/factory/${facId}/monthlymonitoring/${date}`
export const GET_PERFORMANCE_REPORT = (orgId, facId, startDate, endDate) => `/org/${orgId}/factory/${facId}/monthlymonitoring/performenceReport?startDate=${startDate}&endDate=${endDate}`


//SAVE_SATUTORY_REPORT

export const SAVE_SATUTORY_REPORT=(orgId, facId, date)=>`/org/${orgId}/factory/${facId}/satutoryreport/${date}`


//health and safty report
export  const HEALTH_AND_SAFTY_REPORT=(orgId, facId, date)=>`/org/${orgId}/factory/${facId}/healthandsaftyreport/${date}`
export const  UPLOAD_HEALTH_AND_SAFTY_IMAGE=(orgId, facId)=>`/org/${orgId}/factory/${facId}/healthandsaftyreport/data/images`
export const DELETE_HEALTH_AND_SAFTY_IMAGES=(orgId, facId)=>`/org/${orgId}/factory/${facId}/healthandsaftyreport/imageDelete`

export const GET_ALL_MONTHLY_INSPECTION_REPORT=(orgId,facId,year)=>`/org/${orgId}/factory/${facId}/getallmonthlyinspection/${year}`