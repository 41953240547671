import React, { useState } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  Input,
  CardTitle,
} from "reactstrap"
import * as _ from "lodash"
import "../../assets/scss/theme.scss"

const ReportPrint = React.forwardRef((props: any, ref: any) => {
  // console.log("props", props)

  let { values, images,departments ,reports} = props
  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h4 style={{ textAlign: "center" }}>
                          {props.reportName}
                        </h4>{" "}
                        {/* <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.submitedBy}
                          
                        </p>{" "} */}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Factory Name :</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.selectedFactory && props.selectedFactory.name}
                        </p>{" "}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Submit By :</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.submitedBy.name}
                        </p>{" "}
                      </span>
                      <span style={{ display: "flex" }}>
                        <h4>Date:</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.date}
                        </p>{" "}
                      </span>
                    </Col>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <CardTitle>Health And Safety Report</CardTitle>
                            {/* <CardSubtitle className="mb-3">
                    Use one of two modifier classNamees to make{" "}
                    <code>&lt;thead&gt;</code>s appear light or dark gray.
                  </CardSubtitle> */}

                            <div className="table-responsive">
                              <Table className="table mb-0 table-bordered table">
                                <thead className="thead-light">
                                  <tr>
                                    <th>Check Points</th>
                                    {departments.map((department, key) => {
                                      return <th key={key}>{department.name}</th>
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {reports.map((report, key) => {
                                    return (
                                      <React.Fragment key={key}>
                                        <tr
                                          className="table-info"
                                          style={{ textAlign: "center" }}
                                        >
                                          <th colSpan={12 + 1} scope="row">
                                            {report.name}
                                          </th>
                                        </tr>
                                        {report.lists.map((que, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <th scope="row">{que.name}</th>

                                                {departments.map((dept, key) => {
                                                  let specificValue = _.find(
                                                    values,
                                                    value => {
                                                      return (
                                                        value.groupId ===
                                                          report._id &&
                                                        value.questionId ===
                                                          que._id &&
                                                        value.departmentId ===
                                                          dept._id
                                                      )
                                                    }
                                                  )

                                                  return (
                                                    <React.Fragment key={key}>
                                                      {que.type === "select" ? (
                                                        <td>
                                                          <select
                                                            onChange={e => {
                                                             
                                                            }}
                                                            //   setValue(oId, idx, e)
                                                            // }
                                                            value={
                                                              specificValue &&
                                                              specificValue.value
                                                                ? specificValue.value
                                                                : ""
                                                            }
                                                            id="ddlCreditCardType"
                                                            name="ddlCreditCardType"
                                                            className="form-control"
                                                          >
                                                            <option value=""></option>
                                                            <option value="yes">
                                                              Yes
                                                            </option>
                                                            <option value="no">
                                                              No
                                                            </option>
                                                            <option value="n/a">
                                                              N/A
                                                            </option>
                                                          </select>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <Input
                                                            onChange={e => {
                                                             
                                                            }}
                                                            value={
                                                              specificValue &&
                                                              specificValue.value
                                                                ? specificValue.value
                                                                : ""
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                            id="ddlCreditCardType"
                                                            name="ddlCreditCardType"
                                                          ></Input>
                                                        </td>
                                                      )}
                                                    </React.Fragment>
                                                  )
                                                })}
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })}
                                      </React.Fragment>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <CardTitle style={{ paddingLeft: "8px" }}>
                              Remarks (Enclose Evidences)
                            </CardTitle>
                            <div
                              className="popup-gallery"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {departments &&
                                departments.map((depart, key) => {
                                  const find1 = _.find(images, {
                                    departmentId: depart._id,
                                  })

                                  // console.log("departdepartdepart12345",find1 && find1.departmentId===depart._id,find1 ,depart._id)
                                  return (
                                    <React.Fragment key={key}>
                                      <div key={key}>
                                        {images.length > 0 && find1 && (
                                          <div
                                            style={{ paddingLeft: "8px" }}
                                            className="mt-0 card-title"
                                          >
                                            {depart.name}
                                          </div>
                                        )}
                                            <Row>
                                        {images &&
                                          images.map((image, key) => {
                                           
                                            const find = _.find([image], {
                                              departmentId: depart._id,
                                            })

                                            if (find) {
                                              let data = _.find(
                                                values,
                                                value => {
                                                  return (
                                                    value.groupId ===
                                                      find.groupId &&
                                                    value.questionId ===
                                                      find.questionId &&
                                                    value.departmentId ===
                                                      find.departmentId
                                                  )
                                                }
                                              )

                                              if (data) {
                                                return (
                                                  <div
                                                    className="img-fluid float-left"
                                                    style={{
                                                      padding: "10px",
                                                      margin: "12px",
                                                      height: "245px",
                                                      width: "180px",
                                                      backgroundColor:
                                                        "rgb(250, 250, 250)",
                                                      border:
                                                        "1px dashed black",
                                                    }}
                                                    key={key}
                                                  >
                                                    {find.img && (
                                                      <React.Fragment>
                                                        <div
                                                          onClick={e => {
                                                            // deleteImage(
                                                            //   e,
                                                            //   image
                                                            // )
                                                          }}
                                                          style={{
                                                            float: "right",
                                                            margin: "-2px",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <i className="mdi mdi-close-thick"></i>
                                                        </div>
                                                        <img
                                                          width="150px"
                                                          height="150px"
                                                          src={`data:${find.img.contentType};base64, ${find.img.data}`}
                                                          onClick={() => {
                                                            // setisGallery(true)
                                                            // setphotoIndex(0)
                                                          }}
                                                          alt=""
                                                          // width="120"
                                                        />
                                                        {find.desc && (
                                                          <p
                                                            style={{
                                                              overflow: "auto",
                                                              height: "10vh",
                                                            }}
                                                          >
                                                            {" "}
                                                            {find.desc}{" "}
                                                          </p>
                                                        )}
                                                      </React.Fragment>
                                                    )}
                                                  </div>
                                                )
                                              }
                                            }
                                          })}
                                          </Row>
                                      </div>
                                    </React.Fragment>
                                  )
                                })}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* here Show Images stop */}
        </Container>
      </div>
    </React.Fragment>
  )
})
ReportPrint.displayName = 'ReportPrint'
export default ReportPrint
