import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { map, find, each, findIndex } from "lodash"

import * as backend from "../../helpers/backend_helper"
import EditableColumn from "./EditableColumn"
import Toaster from '../../components/Common/Toaster';

const Responsibility = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
    },
  } = props
  const [responsiblity, setResponsiblity] = useState<any[]>([])
  const [responsiblityStatus, setResponsiblityStatus] = useState(false)

  const [userList, setuserList] = useState<any[]>([])
  const [count, setCount] = useState(true)

  async function getUsers() {
    try {
      const response = await backend.getUserOrg(orgId)
      setuserList(response)
    } catch (e) {}
  }

  useEffect(() => {
    if (count) {
      getUsers()
      setCount(false)
    }
  }, [count])

  // update from responsiblity
  useEffect(() => {
    if (!responsiblityStatus) {
      setResponsiblity(props.responsibility)
      setResponsiblityStatus(true)
    }
  }, [props.responsibility, responsiblityStatus])

  // create Responsiblity
  useEffect(() => {
    const rablity = [...responsiblity]
    if (props.teams && props.form && responsiblityStatus) {
      each(props.teams, (team, tIndex) => {
        each(props.form, (fm, fmIndex) => {
          const findObj = { groupId: fm["_id"], userId: team["userId"] }
          const findRablity = findIndex(rablity, findObj)
          if (findRablity < 0) rablity.push({ ...findObj, status: false })
        })
      })
      setResponsiblity([...rablity])
    }
  }, [props.form, props.teams, responsiblityStatus])

  // check update
  function checkUpdate(e, obj) {
    const rablity = [...responsiblity]
    const findRablity = findIndex(rablity, obj)
    if (findRablity > -1) {
      rablity[findRablity].status = e.target.checked
    }
    setResponsiblity([...rablity])
  }

  // submit
  async function submit() {
    try {
      await backend.updateFactory(orgId, facId, {
        responsibility: responsiblity,
      })
      props.update()
      Toaster.success("Responsibility Updated Success")
    } catch (e) {
      console.log("Responsibility ", e)
      Toaster.error("Responsibility Updated Failed")
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Designation</th>
                        {props.form &&
                          map(props.form, (fm, idx) => {
                            return (
                              <th scope="col" key={idx}>
                                {fm.resIdentifier}
                              </th>
                            )
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {props.teams &&
                        userList.length > 0 &&
                        map(props.teams, (team, teamIndex) => {
                          const findUser = find(userList, ["_id", team.userId])
                          return findUser ? (
                            <tr key={teamIndex}>
                              <td>
                                {findUser.email}({findUser.name})
                              </td>
                              {props.form &&
                                map(props.form, (fm, formidx) => {
                                  const findObj = {
                                    groupId: fm["_id"],
                                    userId: team.userId,
                                  }
                                  const findRablity = find(
                                    responsiblity,
                                    findObj
                                  )
                                  return findRablity ? (
                                    <td key={formidx}>
                                      <div className="custom-control custom-checkbox custom-checkbox-outline custom-checkbox-primary mb-3">
                                        <input
                                          type="checkbox"
                                          onChange={e =>
                                            checkUpdate(e, findObj)
                                          }
                                          defaultChecked={findRablity.status}
                                          className="custom-control-input"
                                          id={
                                            "custom" + teamIndex + "" + formidx
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={
                                            "custom" + teamIndex + "" + formidx
                                          }
                                        ></label>
                                      </div>
                                    </td>
                                  ) : (
                                    <td key={formidx}></td>
                                  )
                                })}
                            </tr>
                          ) : (
                            <tr key={teamIndex}></tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
                {responsiblity.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="success">
                      Submit
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

export default withRouter<any, any>(connect<any>(mapStateToProps, {})(Responsibility))
