import axios from "axios";
import store from "../store";

export const API_URL = (process.env.NODE_ENV === "production") ? "https://api.socialcompliance.in/api/v1" : 'http://localhost:3002/api/v1';


export const fileUrl = (id: string) => {
    let token = 'Bearer ' + localStorage.getItem("token")
    return API_URL + '/file/' + id + '?authorization='+ token;
}

const instance = axios.create({
    baseURL: API_URL
    // timeout: 1000,
    // headers: { 'X-Custom-Header': 'foobar' }
}); 

// Add a request interceptor
instance.interceptors.request.use(async function (config) {
    // Do something before request is sent
    let token = localStorage.getItem("token")

    if (token) {
        const headers = (config.headers) ? config.headers : {};
        config.headers = { 'Authorization': 'Bearer ' + token, ...headers, };
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default instance;