import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from 'react-to-print';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import * as _ from "lodash"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Breadcrumb from "../../components/Common/Breadcrumb2"
import EditableColumn from "../Factory/EditableColumn"
import ReportPrint from "./ReportPrint"
import { saveDailyMonitoringReport } from "../../store/daily-monitoring/action"
import Toaster from "../../components/Common/Toaster"
import * as backend from "../../helpers/backend_helper"
import * as func from "../../common/functions"
import BackButton from "../../common/ui/backButton"
import ConformationAlert from "../../common/ui/customAlert"
import ImageViewer from "../../common/ui/ImageViewer"
import { fileUrl } from "service";

const Options = [
  { label: "Yes", value: "yes:1" },
  { label: "No", value: "no:0" },
  { label: "N/A", value: "na:1" },
]
const worker = "Workers Present"

const Report = (props: any) => {
  const [showSubmit, setShowSubmit] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_data, setmodal_data] = useState<any>()
  const [formModal_data, setformModal_data] = useState<any>({})
  const [reports, setReports] = useState<any[]>([])
  const [images, setImages] = useState([])
  const [pressureLevel, setPressureLevel] = useState("0")
  const [totalworker, settotalworker] = useState(0)
  const [wokerpresentId, setwokerpresentId] = useState()
  const [first, setFirst] = useState(true)
  const [submitedBy, setSubmitedBy] = useState()
  const [submitedByuser, setSubmitedByUser] = useState()
  const [totalQuestions, settotalQuestions] = useState(0)
  const [overallscore, setoverallscore] = useState(0)
  const [totalQuestionInDepart, setTotalQuestionInDepart] = useState<any>()
  const [SubmitAlertVisible, setSubmitAlertVisible] = useState(false)
  const [remarks, setRemarks] = useState("")
  const [imageViewerData, setimageViewerData] = useState("")
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  })
  const {
    match: {
      params: { month, year, day },
    },
  } = props

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // column update
  function updateReport(obj, value, inputtype) {
    const cloneReports = [...reports]
    const findIndex = _.findIndex(cloneReports, obj)
    if (findIndex < 0) {
      cloneReports.push({ ...obj, value: value, type: inputtype })
    } else {
      cloneReports[findIndex].value = value
    }
    setReports([...cloneReports])
    if (wokerpresentId && obj.questionId == wokerpresentId)
      updateTotalWorkerPresent([...cloneReports])
    updateOverAllScore([...cloneReports])
  }

  function updateTotalWorkerPresent(dynamicReport) {
    try {
      const filter = _.filter(dynamicReport ? dynamicReport : reports, o => {
        return o.questionId == wokerpresentId && o.value
      }).map(o => Number(o.value))
      settotalworker(_.sum(filter))
    } catch (e) { }
  }
  // console.log("props", props)

  // update from Store
  useEffect(() => {
    if (props.dailymonitroingReport) {
      const { pressureLevel, report, submitedBy } = props.dailymonitroingReport
      if (pressureLevel) setPressureLevel(pressureLevel)
      if (report) setReports(report)
      if (submitedBy) setSubmitedBy(submitedBy)
      updateTotalWorkerPresent(report)
      updateOverAllScore(report)
    }
  }, [props.dailymonitroingReport, wokerpresentId])

  // update daily forms
  useEffect(() => {
    if (props.selectedFactory && props.selectedFactory.dailyMonitoringForm) {
      const firstGroup = props.selectedFactory.dailyMonitoringForm[0]
      const totalDepartment = props.selectedFactory.departments
        ? props.selectedFactory.departments.length
        : 0
      if (firstGroup) {
        const find = _.find(firstGroup.lists, o => {
          return o.name.toLowerCase().includes(worker.toLowerCase())
        })
        if (find) setwokerpresentId(find["_id"])
      }
      const totalNumberOfQuestionsArray = _.map(
        props.selectedFactory.dailyMonitoringForm,
        o => {
          return (
            _.filter(o.lists, f => f.type == "select").length * totalDepartment
          )
        }
      )
      const totalNumberOfQuestions = _.sum(totalNumberOfQuestionsArray)
      settotalQuestions(totalNumberOfQuestions)
    }
  }, [props.selectedFactory])

  // updateOverAllScore
  function updateOverAllScore(cloneReports) {
    try {
      // const submitedReports = _.filter(
      //   cloneReports ? cloneReports : reports,
      //   o => {
      //     const number = Number(o.value.split(":")[1])
      //     return o.type == "select" && number == 1
      //   }
      // ).length
      // const percetage = func.Percentage(totalQuestions, submitedReports)
      // if (percetage !== "NaN") {
      //   setoverallscore(percetage)
      // }
      let totalScore = 0
      {
        props.selectedFactory &&
          props.selectedFactory &&
          props.selectedFactory.departments &&
          props.selectedFactory.departments.map((depart, index) => {
            const dep = _.filter(cloneReports ? cloneReports : reports, {
              departmentId: depart["_id"],
              type: "select",
            })
              .map(d => d.value.split(":")[1])
              .filter(o => o == "1")
              .map(o => Number(o))
            let depScore = 0
            if (totalQuestionInDepart) {
              let depvalue: any = totalQuestionInDepart[depart["_id"]]
              // console.log("depvaluedepvalue",depvalue,totalQuestionInDepart);

              if (depvalue) {
                depScore = Math.round(Number((depvalue.divide * dep.length).toFixed(1)))
                totalScore = totalScore + depScore
                // console.log("totalScototalScoretotalScorere", totalScore)
              }
            }
          })
      }
      let finalValue = 0

      let NumberOfDepartments = props.selectedFactory.departments.length * 10
      finalValue = Number(((totalScore / NumberOfDepartments) * 100).toFixed(1))
      // console.log("ssssssssssssssssssssame", finalValue, totalScore)
      setoverallscore(Math.round(finalValue))
    } catch (e) { }
  }

  const getSubmitedUser = async userId => {
    const { orgId } = props.selectedFactory
    try {
      const response = await backend.getUserOrgById(orgId, userId)
      if (response) {
        setSubmitedByUser(response.name)
      }
    } catch (e) { }
  }
  // getDailyMonitoringReport
  async function getDailyMonitoringReport(image = false) {
    const { orgId, _id: facId } = props.selectedFactory
    try {
      const response = await backend.getdailymonitoringReport(
        orgId,
        facId,
        `${year}-${month}-${day}`
      )
      // console.log("response1111111111 first", response)

      if (response) {
        // console.log("response1111111111", response)

        if (response.images) {
          setImages(response.images)
        }
        if (response.submitedBy && !image) {
          // console.log("response get in", response)

          const saveObject = {
            pressureLevel: response.pressureLevel,
            report: response.report,
            submitedBy: response.submitedBy,
            remarks: response.remarks,
          }
          setRemarks(response.remarks)
          getSubmitedUser(response.submitedBy)
          setShowSubmit(false)
          props.saveDailyMonitoringReport(saveObject, `${year}-${month}-${day}`)
        } else {
          setShowSubmit(true)
        }
      } else {
        setShowSubmit(true)
      }

      if (props.isAdmin) {
        setShowSubmit(true)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (first && props.selectedFactory) {
      getDailyMonitoringReport()
      let data = func.getTotalQuestionsindailyMonitoringReport(
        props.selectedFactory.dailyMonitoringForm,
        props.selectedFactory.departments
      )
      setTotalQuestionInDepart(data)
      setFirst(false)
    }
  }, [first, props.selectedFactory])

  // submit button
  async function submit(reports, pressureLevel) {
    if (!props.isAdmin) {
      toggleAlert()
    }

    const { orgId, _id: facId } = props.selectedFactory
    const obj = {
      report: reports,
      pressureLevel: pressureLevel,
      submitedBy: props.user["_id"],
      remarks: remarks,
    }
    try {
      await backend.savedailymonitoringReport(
        orgId,
        facId,
        `${year}-${month}-${day}`,
        obj
      )
      getDailyMonitoringReport()
      // setRemarks("")
      Toaster.success("Report Submitted successfully")
    } catch (e) {
      Toaster.error("Report Submitted Failed")
    }
  }
  // save button
  function save() {
    const saveObject = {
      pressureLevel: pressureLevel,
      report: reports,
    }
    props.saveDailyMonitoringReport(saveObject, `${year}-${month}-${day}`)
    Toaster.success("work saved successfully")
  }

  //uploadImage
  async function uploadImage() {
    const { orgId, _id: facId } = props.selectedFactory
    const { departmentId, groupId, questionId } = modal_data
    console.log("formModal_data", formModal_data, images)

    const formData = new FormData()
    // Update the formData object
    _.each(formModal_data.file, file => {
      formData.append("image", file)
    })
    formData.append("desc", formModal_data.desc || '')
    formData.append("departmentId", departmentId)
    formData.append("groupId", groupId)
    formData.append("questionId", questionId)
    formData.append("date", `${year}-${month}-${day}`)
    await backend.uploadMonthlyMonitoringReport(orgId, facId, formData)
    getDailyMonitoringReport(true)
    tog_standard()
  }
  const submitedUser = submitedByuser
    ? submitedByuser
    : props.user && props.user.name

  if (!props.selectedFactory) {
    return <></>
  }

  const onSubmit = async () => {
    if (props.isAdmin) {
      if (remarks) {
        submit([], "0")
      } else {
        submit(reports, pressureLevel)
      }
    } else {
      toggleAlert()
    }
  }

  const toggleAlert = () => {
    setSubmitAlertVisible(!SubmitAlertVisible)
  }
  const deleteImage = (e, img) => {
    e.stopPropagation()

    const { orgId, _id: facId } = props.selectedFactory
    console.log("imgimgimg", img, orgId, facId)
    let data = {
      date: `${year}-${month}-${day}`,
      imageId: img._id,
    }
    backend
      .deleteImage(orgId, facId, data)
      .then(() => {
        console.log("Sucess")

        getDailyMonitoringReport(true)
      })
      .catch(() => {
        console.log("error in delete")
      })
  }

  let aboutDay = {
    W: "Weekly Off (W)",
    H: "Holiday (H)",
    A: "Audit (A)",
  }
  let date = `${year}-${month}-${day}`

  let todayDate = moment().format("YYYY-MM-DD")
  // console.log(
  //   "todayDatetodayDatetodayDate",
  //   todayDate,
  //   date,
  //   date === todayDate
  // )
  let isToday = date === todayDate
  // console.log("totalworkertotalworkertotalworkertotalworker",totalworker);

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={<BackButton history={props.history} />}
          breadcrumbItems={[
            { title: year, to: "/daily-monitoring" },
            {
              title: moment(month, "MM").format("MMMM"),
              to: `/daily-monitoring/${year}/${month}`,
            },
            { title: day },
          ]}
        ></Breadcrumb>
        <Container fluid>
          {submitedBy && (
            <div style={{ display: "none" }}>
              <ReportPrint
                date={`${year}-${month}-${day}`}
                ref={componentRef}
                totalworker={totalworker}
                images={images}

                overallscore={overallscore}
                selectedFactory={props.selectedFactory}
                reports={reports}
                totalQuestionInDepart={totalQuestionInDepart}
                pressureLevel={pressureLevel}
                submitedBy={submitedUser}
                reportName={`Daily Monitoring`}
              />
            </div>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <span style={{ display: "flex" }}>
                        <h4>Submit By :</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {submitedUser}
                        </p>{" "}
                      </span>
                      {submitedBy && (
                        <Button
                          onClick={handlePrint}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                          color="success"
                        >
                          Print
                        </Button>
                      )}
                    </Col>

                    <Col md={4}>
                      {/* <span style={{ display: "flex" }}>
                        <h4>Total Worker :</h4>{" "}
                        <span style={{ padding: "2px 8px" }}>
                          {totalworker}
                        </span>
                      </span> */}
                    </Col>
                    <Col md={4}>
                      <span
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <h4>Score:</h4>{" "}
                        <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {overallscore}
                        </p>{" "}
                      </span>
                    </Col>
                    <FormGroup className="col-lg-3">
                      <Label
                        for="horizontal-email-Input"
                        className="col-sm-12 col-form-Label"
                        style={{ marginTop: "7px", padding: "0px" }}
                      >
                        Day Remarks
                      </Label>

                      <Input
                        onChange={e => {
                          setRemarks(e.target.value)
                          console.log("get eventeeeeeee", e.target.value)
                        }}
                        type="select"
                        name="selectMulti"
                        value={remarks}
                        // defaultValue={`${remarks}`}

                        placeholder={"Select here"}
                      >
                        <option value={""}></option>

                        {Object.keys(aboutDay).map((key, tIndex) => {
                          return (
                            <option value={key} key={tIndex}>
                              {aboutDay[key]}
                            </option>
                          )
                        })}
                      </Input>
                      {/* {showSubmit && ( */}

                      {/* )} */}
                    </FormGroup>
                    <Col
                      md={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "17px",
                      }}
                    >
                      <div>
                        {showSubmit && (
                          <Button
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                            onClick={onSubmit}
                            color="success"
                          >
                            Confirm
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{ overflowY: "auto", height: "65vh" }}
                  >
                    <Table
                      className="table table-hover table-bordered mb-0"
                      style={{ borderCollapse: "separate", borderSpacing: 0 }}
                    >
                      <thead>
                        <tr className="table-success">
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              // background: "#ffff",
                              zIndex: 1,
                              borderTop: "1px solid #eff2f7",
                              borderBottom: "1px solid #eff2f7",
                            }}
                          >
                            CheckPoints
                          </th>
                          {props.selectedFactory &&
                            props.selectedFactory &&
                            props.selectedFactory.departments &&
                            props.selectedFactory.departments.map(
                              (depart, deIndex) => {
                                return (
                                  <th
                                    style={{
                                      position: "sticky",
                                      top: "0",
                                      // background: "#ffff",
                                      zIndex: 1,
                                      borderTop: "1px solid #eff2f7",
                                      borderBottom: "1px solid #eff2f7",
                                    }}
                                    key={deIndex}
                                  >
                                    {depart.name}
                                  </th>
                                )
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedFactory &&
                          props.selectedFactory &&
                          props.selectedFactory.departments &&
                          props.selectedFactory.dailyMonitoringForm &&
                          props.selectedFactory.dailyMonitoringForm.map(
                            (group, groupIndex) => {
                              return (
                                <React.Fragment key={groupIndex}>
                                  <tr
                                    key={groupIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    <th
                                      colSpan={
                                        props.selectedFactory.departments
                                          .length + 1
                                      }
                                      scope="row"
                                    >
                                      {group.name}
                                    </th>
                                  </tr>
                                  {group.lists.map((question, qIndex) => {
                                    return (
                                      <tr key={qIndex}>
                                        <th scope="row">{question.name}</th>
                                        {props.selectedFactory.departments.map(
                                          (depart, deIndex) => {
                                            const obj = {
                                              groupId: group["_id"],
                                              questionId: question["_id"],
                                              departmentId: depart["_id"],
                                            }
                                            const find = _.find(reports, obj)
                                            return (
                                              <td key={deIndex}>
                                                {showSubmit ? (
                                                  <EditableColumn
                                                    update={value =>
                                                      updateReport(
                                                        obj,
                                                        value,
                                                        question.type
                                                      )
                                                    }
                                                    options={Options}
                                                    placeholder={" "}
                                                    type={question.type}
                                                    value={
                                                      find && find.value
                                                        ? find.value
                                                        : null
                                                    }
                                                  />
                                                ) : (
                                                  <p>
                                                    {find && find.value
                                                      ? find.value.split(":")[0]
                                                      : null}
                                                  </p>
                                                )}
                                                <br />
                                                {find &&
                                                  find.value == "no:0" &&
                                                  showSubmit && (
                                                    <button
                                                      onClick={e => {
                                                        setmodal_data(find)
                                                        setformModal_data({})
                                                        tog_standard()
                                                      }}
                                                      style={{
                                                        padding: "2px 20px",
                                                      }}
                                                      type="button"
                                                      className="btn btn-danger waves-effect waves-light"
                                                    >
                                                      <i className="mdi mdi-upload d-block font-size-16"></i>{" "}
                                                    </button>
                                                  )}
                                              </td>
                                            )
                                          }
                                        )}
                                      </tr>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            }
                          )}
                        <tr>
                          <th scope="row">Score</th>
                          {props.selectedFactory &&
                            props.selectedFactory &&
                            props.selectedFactory.departments &&
                            props.selectedFactory.departments.map(
                              (depart, index) => {
                                const dep = _.filter(reports, {
                                  departmentId: depart["_id"],
                                  type: "select",
                                })
                                  .map(d => d.value.split(":")[1])
                                  .filter(o => o == "1")
                                  .map(o => Number(o))
                                let depScore = 0
                                if (totalQuestionInDepart) {
                                  let depvalue =
                                    totalQuestionInDepart[depart["_id"]]
                                  // console.log("depvaluedepvalue",depvalue,totalQuestionInDepart);

                                  if (depvalue) {
                                    depScore = Math.round(
                                      Number((depvalue.divide * dep.length).toFixed(1))
                                    )
                                  }
                                }
                                return <td key={index}>{depScore}</td>
                              }
                            )}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    {(isToday || props.isAdmin) && (
                      <React.Fragment>
                        {!submitedBy && (
                          <Button
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                            onClick={save}
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                        {showSubmit && (
                          <Button
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                            onClick={onSubmit}
                            color="success"
                          >
                            Submit
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* here Show Images start */}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle style={{ paddingLeft: "8px" }}>
                    Remarks (Enclose Evidences)
                  </CardTitle>
                  <div
                    className="popup-gallery"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {props.selectedFactory &&
                      props.selectedFactory &&
                      props.selectedFactory.departments &&
                      props.selectedFactory.departments.map((depart, key) => {
                        const find1 = _.find(images, {
                          departmentId: depart._id,
                        })

                        // console.log("departdepartdepart12345",find1 && find1.departmentId===depart._id,find1 ,depart._id)
                        return (
                          <React.Fragment key={key}>
                            <div>
                              {images.length > 0 && find1 && (
                                <div
                                  style={{ paddingLeft: "8px" }}
                                  className="mt-0 card-title"
                                >
                                  {depart.name}
                                </div>
                              )}
                              {images &&
                                images.map((image, key) => {
                                  // console.log("imageimages", image, images)
                                  const find: any = _.find([image], {
                                    departmentId: depart._id,
                                  })

                                  if (find) {
                                    return (
                                      <div
                                        className="img-fluid float-left"
                                        style={{
                                          padding: "10px",
                                          margin: "12px",
                                          height: "245px",
                                          width: "180px",
                                          backgroundColor: "rgb(250, 250, 250)",
                                          border: "1px dashed black",
                                        }}
                                        key={key}
                                      >
                                        {find.img && (
                                          <React.Fragment>
                                            <div
                                              onClick={e => {
                                                deleteImage(e, image)
                                              }}
                                              style={{
                                                float: "right",
                                                margin: "-2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <i className="mdi mdi-close-thick"></i>
                                            </div>
                                            <img
                                              style={{ cursor: "pointer" }}
                                              width="150px"
                                              height="150px"
                                              src={find.img.id ? fileUrl(find.img.id) : `data:${find.img.contentType};base64, ${find.img.data}`}
                                              onClick={e => {
                                                console.log("eee", e)

                                                setimageViewerData(
                                                  find.img.id ? fileUrl(find.img.id) : `data:${find.img.contentType};base64, ${find.img.data}`
                                                )
                                                // setisGallery(true)
                                                // setphotoIndex(0)
                                              }}
                                              alt=""
                                            // width="120"
                                            />
                                            {find.desc && (
                                              <p
                                                style={{
                                                  overflow: "auto",
                                                  height: "10vh",
                                                }}
                                              >
                                                {" "}
                                                {find.desc}{" "}
                                              </p>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>
                                    )
                                  }

                                  // {/* {images &&
                                  //   images.map((image, key) => {
                                  //     console.log("image", image, reports)

                                  //     const find = _.find(reports, {
                                  //       departmentId: image.departmentId,
                                  //       groupId: image.groupId,
                                  //       questionId: image.questionId,
                                  //     })
                                  //     console.log("find", find)
                                  //     if (!find || (find && find.value != "no:0")) {
                                  //       return <React.Fragment key={key}></React.Fragment>
                                  //     }
                                  //     return (
                                  //       <div
                                  //         className="img-fluid float-left"
                                  //         style={{
                                  //           padding: "10px",
                                  //           margin: "12px",
                                  //           height: "245px",
                                  //           width: "180px",
                                  //           backgroundColor: "rgb(250, 250, 250)",
                                  //           border: "1px dashed black",
                                  //         }}
                                  //         key={key}
                                  //       >
                                  //         {image.img && (
                                  //           <React.Fragment>
                                  //             <div
                                  //               onClick={e => {
                                  //                 deleteImage(e, image)
                                  //               }}
                                  //               style={{
                                  //                 float: "right",
                                  //                 margin: "-2px",
                                  //                 cursor: "pointer",
                                  //               }}
                                  //             >
                                  //               <i class="mdi mdi-close-thick"></i>
                                  //             </div>
                                  //             <img
                                  //               width="150px"
                                  //               height="150px"
                                  //               src={`data:${image.img.contentType};base64, ${image.img.data}`}
                                  //               onClick={() => {
                                  //                 // setisGallery(true)
                                  //                 // setphotoIndex(0)
                                  //               }}
                                  //               alt=""
                                  //               // width="120"
                                  //             />
                                  //             {image.desc && <p> {image.desc} </p>}
                                  //           </React.Fragment>
                                  //         )}
                                  //       </div>
                                  //     )
                                  //   })} */}
                                })}
                            </div>
                          </React.Fragment>
                        )
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* here Show Images stop */}
        </Container>
      </div>
      {imageViewerData && (
        <ImageViewer
          image={imageViewerData}
          close={() => {
            setimageViewerData("")
          }}
        />
      )}
      <ConformationAlert
        visible={SubmitAlertVisible}
        Title={"Alert"}
        Message={"If you submit you can't do any changes in the report"}
        toggleAlert={toggleAlert}
        Submit={() => {
          if (remarks) {
            submit([], "0")
          } else {
            submit(reports, pressureLevel)
          }
        }}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"Upload Documents"}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              onChange={event =>
                setformModal_data({
                  ...formModal_data,
                  desc: event.target.value,
                })
              }
              placeholder="description"
            />
          </div>
          <div className="custom-file">
            <input
              type="file"
              multiple
              accept="image/jpeg, image/png"
              className="custom-file-input"
              id="customFile"
              onChange={event => {
                setformModal_data({
                  ...formModal_data,
                  file: event.target.files,
                  // imagePreview: URL.createObjectURL(event.target.files[0]),
                })
              }}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {formModal_data && formModal_data.file
                ? formModal_data.file[0].name
                : "Choose file"}
            </label>
          </div>
          {/* formModal_data.file && formModal_data.file.length */}
          {/* {formModal_data && formModal_data.file &&
            formModal_data.file.length > 0 && formModal_data.file.map(list => {
              console.log("list123456",list);
              
              return (
                <label className="custom-file-label" htmlFor="customFile">
                  {list.name}
                </label>
              )
            })} */}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => {
              uploadImage()
            }}
            className="btn btn-primary waves-effect waves-light"
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}
const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { month, year, day },
    },
  } = props
  const { user } = state.user
  const { selected: selectedFactory } = state.factory
  const {
    dailymonitroing: { [`${year}-${month}-${day}`]: dailymonitroingReport },
  } = state.dailymonitroing
  const isAdmin =
    selectedFactory && selectedFactory.permission.includes("ADMIN")
      ? true
      : false
  return { selectedFactory, user, isAdmin: isAdmin, dailymonitroingReport }
}

export default withRouter(
  connect(mapStateToProps, { saveDailyMonitoringReport })(Report)
)
