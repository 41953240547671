import React, { useState, useEffect, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  CardTitle,
} from "reactstrap"
import * as moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import EditableColumn from "../Factory/EditableColumn"
import ExternalMonitoring from "./ExternalMonitoring"
import PerformanceMonitoring from "./PerformanceMonitoring"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import * as func from "../../common/functions"
import _ from "lodash"

const ReportPrint = React.forwardRef((props: any, ref: any) => {
  // console.log("props", props)
  let {
    userList,
    months,
    Props,
    reports,
    Options,
    year,
    performanceReport,
    score,
    performanceReportaggre,
  } = props
  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        <Container fluid>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <div style={{ display: "flex" }}>
                    <div className="mt-0 card-title">Factory Name : </div>{" "}
                    <p style={{ marginTop: "2px" }}>
                      {Props.selectedFactory.name}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="mt-0 card-title">Report Name : </div>
                    <p style={{ marginTop: "2px" }}> Monthly Monitoring</p>
                  </div>
                 
                
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table
                      className="table table-hover table-borderless mb-0"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          {Props.selectedFactory &&
                            Props.selectedFactory.teams.map((team: any, mIndex: number) => {
                              const find = _.find(userList, [
                                "_id",
                                team.userId,
                              ])
                              if (find) return <th key={mIndex}>{find.role}</th>
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {Props.selectedFactory &&
                            Props.selectedFactory.teams.map((team: any, mIndex: number) => {
                              const find = _.find(userList, [
                                "_id",
                                team.userId,
                              ])
                              const divide = 100 / 1200
                              let value = func.monthlyScoreOverall(
                                performanceReportaggre,
                                score,
                                team.userId,
                                year
                              )
                              if (find)
                                return (
                                  <th key={mIndex}>
                                    {(divide * value).toFixed(1)}
                                  </th>
                                )
                            })}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    className="table-responsive"
                    // style={{ overflowY: "auto", height: "65vh" }}
                  >
                    <Table
                      className="table table-hover  table-bordered  mb-0"
                    //   style={{
                    //     borderCollapse: "separate",
                    //     borderSpacing: 0,
                    //     textAlign: "center",
                    //   }}
                    >
                      <thead>
                        <tr>
                          <th
                            // style={{
                            //   position: "sticky",
                            //   top: "0",
                            //   background: "#ffff",
                            //   zIndex: "1",
                            //   borderTop: "1px solid #eff2f7",
                            //   borderBottom: "1px solid #eff2f7",
                            // }}
                          >
                            Description
                          </th>
                          {months.map((m: any, mIndex: number) => {
                            return (
                              <th
                                // style={{
                                //   position: "sticky",
                                //   top: "0",
                                //   background: "#ffff",
                                //   zIndex: "1",
                                //   borderTop: "1px solid #eff2f7",
                                //   borderBottom: "1px solid #eff2f7",
                                // }}
                                key={mIndex}
                              >
                                {m}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Props.selectedFactory &&
                          Props.selectedFactory &&
                          Props.selectedFactory.monthlyMonitoringForm &&
                          Props.selectedFactory.monthlyMonitoringForm.map(
                            (group: any, groupIndex: number) => {
                              return (
                                <React.Fragment key={groupIndex}>
                                  <tr
                                    key={groupIndex}
                                    style={{ textAlign: "center" }}
                                  >
                                    <th colSpan={months.length + 1} scope="row">
                                      {group.name}
                                    </th>
                                  </tr>
                                  {group.lists.map((question: any, qIndex: number) => {
                                    const lmonth = _.map(
                                      question.months,
                                      "value"
                                    )
                                    return (
                                      <React.Fragment key={qIndex}>
                                        <tr>
                                          <th scope="row" rowSpan={2}>
                                            {question.name}
                                          </th>
                                          {months.map((m: any, mIndex: number) => {
                                            const isMonth = lmonth.includes(m)
                                            const style = !isMonth
                                              ? { backgroundColor: "#dddddd" }
                                              : {}
                                            return (
                                              <td key={mIndex} style={style}>
                                                {isMonth ? question.date : ""}
                                              </td>
                                            )
                                          })}
                                        </tr>
                                        <tr>
                                          {months.map((m: any, mIndex: number) => {
                                            const isMonth = lmonth.includes(m)
                                            const style = !isMonth
                                              ? { backgroundColor: "#dddddd" }
                                              : {}
                                            const obj = {
                                              groupId: group["_id"],
                                              questionId: question["_id"],
                                              month: m,
                                            }
                                            const find = _.find(reports, obj)
                                            return (
                                              <td key={mIndex} style={style}>
                                                {isMonth ? (
                                                  <EditableColumn
                                                    update={(value: any) =>
                                                      {
                                                        // updateReport(
                                                        //   obj,
                                                        //   value,
                                                        //   question.type
                                                        // )
                                                      }
                                                    }
                                                    options={Options}
                                                    placeholder={" "}
                                                    type={question.type}
                                                    value={
                                                      find && find.value
                                                        ? find.value
                                                        : null
                                                    }
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                            )
                                          })}
                                        </tr>
                                      </React.Fragment>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            }
                          )}
                        {Props.selectedFactory &&
                          userList.length > 0 &&
                          Props.selectedFactory.teams && (
                            <PerformanceMonitoring
                              year={year}
                              updateReport={(e: any) => {}}
                              performanceReport={performanceReport}
                              userList={userList}
                              teams={Props.selectedFactory.teams}
                              months={months}
                            />
                          )}
                        {Props.selectedFactory &&
                          userList.length > 0 &&
                          Props.selectedFactory.teams && (
                            <ExternalMonitoring
                              score={score}
                              year={year}
                              userList={userList}
                              performanceReport={performanceReportaggre}
                              teams={Props.selectedFactory.teams}
                              months={months}
                            />
                          )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
})
ReportPrint.displayName = 'ReportPrint'
export default ReportPrint
