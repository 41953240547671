import React, { useState, useEffect } from "react"
import { Modal } from "reactstrap"
import { fileUrl } from "service"


const ImageViewer = (props: any) => {
  console.log("props", props)
  const [data, setData] = useState('')
  useEffect(() => {
    setData(fileUrl(props.filedata.image));
  }, [props.filedata && props.filedata.image])
  let mimeType2 = (props.filedata && props.filedata.mimeType) || props.image.match(/[^:/]\w+(?=;|,)/)[0]
  // console.log("mimeType2", mimeType2)

  return (
    <Modal
      // size="xl"
      isOpen={props.image ? true : false}
      toggle={() => {
        props.close()
      }}
    >
      {/* <AvForm
        className="form-horizontal"
        onValidSubmit={() => {
          props.Submit()
        }}
      > */}
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Document viewer
        </h5>
        <button
          type="button"
          onClick={() => {
            props.close()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ height: mimeType2 === "pdf" || mimeType2 === "application/pdf" ? "500px" : '' }}>
        {/* {props.Message} */}
        {mimeType2 !== "pdf" || mimeType2 != "application/pdf" ? (
          <img src={data ? data : props.image} alt="none" width="100%" height="500"></img>
        ) : (
          <React.Fragment>
            <iframe src={data ? data : props.image} height="100%" width="100%"></iframe>
          </React.Fragment>
        )}
      </div>
      {/* <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.toggleAlert()
           
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light"
          >
            Ok
          </button>
      </div> */}
      {/* </AvForm> */}
    </Modal>
  )
}

export default ImageViewer
