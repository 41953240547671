import React, { useState } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Modal,
  Input,
  CardTitle,
} from "reactstrap"
import * as _ from "lodash"
import "../../assets/scss/theme.scss"

const ReportPrint = React.forwardRef((props: any, ref: any) => {
  // console.log("props", props)
  let { reports, submitedBy } = props
  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        <Container fluid>
          <Row>
            <Col md={4}>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <h4 style={{ textAlign: "center" }}>{props.reportName}</h4>{" "}
                {/* <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                          {props.submitedBy}
                          
                        </p>{" "} */}
              </span>
              <span style={{ display: "flex" }}>
                <h4>Factory Name :</h4>{" "}
                <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                  {props.selectedFactory && props.selectedFactory.name}
                </p>{" "}
              </span>
              <span style={{ display: "flex" }}>
                <h4>Submit By :</h4>{" "}
                <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                  {submitedBy.name}
                </p>{" "}
              </span>
              <span style={{ display: "flex" }}>
                <h4>Date:</h4>{" "}
                <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                  {props.date}
                </p>{" "}
              </span>
            </Col>

            <Col md={4}>
              <span style={{ display: "flex", justifyContent: "flex-end" }}>
                <h4>Score:</h4>{" "}
                <p style={{ padding: "2px 4px" }} className="ml-2 mr-1">
                  {props.overallscore}
                </p>{" "}
              </span>
            </Col>
          </Row>
          <Row>
            {reports &&
              reports.map((report, oId) => {
                // console.log("reporttttttttttt", report)
                let sno = 0
                return (
                  <Col lg={6} key={oId}>
                    <Card>
                      <CardBody>
                        <CardTitle
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {report.name}
                        </CardTitle>
                        <div className="table-responsive">
                          <Table className="table table-striped table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>S#</th>
                                <th>Description</th>
                                <th>Status</th>
                                {/* <th>Documents</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {report.lists.map((list, idx) => {
                                if (!list.isHeader) {
                                  sno = sno + 1
                                }
                                // console.log("listasfkgnfsd", list)
                                // let mimeType = ""
                                // if (list.image) {
                                //   mimeType = list.image.match(
                                //     /[^:/]\w+(?=;|,)/
                                //   )[0]
                                // }
                                return (
                                  <React.Fragment key={idx}>
                                    {!list.isHeader ? (
                                      <tr>
                                        <th scope="row">{sno}</th>
                                        <td>{list.name}</td>
                                        {list.type === "select" ? (
                                          <React.Fragment>
                                            <td>
                                              <span>{ list.value ? list.value : ""}</span>
                                              {/* <select
                                                onChange={e =>
                                                  setValue(oId, idx, e)
                                                }
                                                value={
                                                  list.value ? list.value : ""
                                                }
                                                style={{
                                                  background:
                                                    list.value &&
                                                    list.value === "no"
                                                      ? "#e4e41a"
                                                      : "#FFFF",
                                                }}
                                                id="ddlCreditCardType"
                                                name="ddlCreditCardType"
                                                className="form-control"
                                              >
                                                <option value=""></option>
                                                <option value="yes">Yes</option>
                                                <option value="no">no</option>
                                                <option value="n/a">N/A</option>
                                              </select> */}
                                            </td>
                                          </React.Fragment>
                                        ) : (
                                          <td>
                                             <span>{ list.value ? list.value : ""}</span>
                                            {/* <Input
                                              // onChange={e =>
                                              //   setValue(oId, idx, e)
                                              // }
                                              value={
                                                list.value ? list.value : ""
                                              }
                                              type="text"
                                              className="form-control"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                            ></Input> */}
                                          </td>
                                        )}
                                        {/* <td>
                                          {list.value === "yes" && (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {list.image ? (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-around",
                                                  }}
                                                >
                                                  <div
                                                    
                                                  >
                                                    {mimeType !== "pdf" ? (
                                                      <img
                                                        src={list.image}
                                                        alt="none"
                                                        width="50"
                                                        height="50"
                                                      ></img>
                                                    ) : (
                                                      <i
                                                        style={{
                                                          fontSize: "41px",
                                                          color: "green",
                                                        }}
                                                        class="bx bxs-file-pdf"
                                                      ></i>
                                                    )}
                                                  </div>
                                                  <div
                                                    style={{
                                                      padding: "10px 10px",
                                                    }}
                                                  > */}
                                                    {/* <i
                                                    className="bx bx-edit-alt"
                                                    style={{
                                                      cursor:"pointer",
                                                      paddingRight: "10px",
                                                    }}
                                                    // onClick={e => onEdit(e, list)}
                                                  ></i> */}
                                                    {/* <i
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "red",
                                                        fontSize: "21px",
                                                      }}
                                                      className="bx bx-trash-alt"
                                                     
                                                    ></i> */}
                                                  {/* </div>
                                                </div>
                                              ) : (
                                                <div>
                                                
                                                  <label
                                                    // className="custom-file-label"
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "6px",
                                                      borderRadius: "5px",
                                                    }}
                                                    htmlFor={`customFile-${oId}-${idx}`}
                                                  >
                                                    Choose file
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td> */}
                                      </tr>
                                    ) : (
                                      <tr
                                        className="table-info"
                                        style={{ textAlign: "center" }}
                                      >
                                        <th colSpan={12 + 1} scope="row">
                                          {list.name}
                                        </th>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}
          </Row>
          {/* here Show Images stop */}
        </Container>
      </div>
    </React.Fragment>
  )
})

ReportPrint.displayName="ReportPrint"

export default ReportPrint
