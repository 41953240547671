import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import * as backend from "../../helpers/backend_helper"
import Toaster from "../../components/Common/Toaster"
import DeleteAlert from "../../common/ui/customAlert"

const FactoryDepartments = (props: any) => {
  const {
    match: {
      params: { orgId, facId },
    },
  } = props
  const [depart, setDepart] = useState<any>([{ name: "",holderName:"" }])
  const [count, setCount] = useState(true)
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false)
  const [deleteId, setDeleteId] = useState("")

  function handleAddRowNested() {
    const item = { name: "" }
    // if (depart.length < 10)
    setDepart([...depart, item])
  }

  function inputUpdate(value: any, idx: any,updateBy: any) {
    const clone = [...depart]
    clone[idx][updateBy] = value
    setDepart([...clone])
  }

  useEffect(() => {
    if (props.departments && props.departments.length > 0)
      setDepart(props.departments)
  }, [props.departments])

  async function submit() {
    try {
      const finalDepart = depart.filter((o: any) => o.name)
      console.log("finalDepartfinalDepartfinalDepart",finalDepart);
      await backend.updateFactory(orgId, facId, { departments: finalDepart })
      props.update()
      Toaster.success("Department Updated Success")
    } catch (e) {
      Toaster.error("Department Updated failed")
    }
  }

  function handleRemoveRowNested(e: any, idx: any) {
    const clone = [...depart]
    clone.splice(idx, 1)
    setDepart([...clone])
    setDeleteId("")
  }

  // on onDelete
  const onDelete = async (e: any, deleteId: any) => {
    // e.stopPropagation()
    setDeleteId(deleteId)
    toggleDeleteAlert()
  }

  const Submit = () => {
    handleRemoveRowNested(null, deleteId)
    toggleDeleteAlert()
  }

  const toggleDeleteAlert = () => {
    setDeleteAlertVisible(!deleteAlertVisible)
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Form className="outer-repeater">
                  <div className="inner-repeater mb-4">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr id="addrMain">
                          <td>
                            <Row
                              className="mb-2"
                              style={{ textAlign: "center" }}
                            >
                              <Col md="5">
                                <h5>Department</h5>
                              </Col>
                              <Col md="5">
                                <h5>Name</h5>
                              </Col>
                              <Col md="2"></Col>
                            </Row>
                          </td>
                        </tr>
                        {depart &&
                          depart.map((de: any, idx: any) => (
                            <tr id="addrMain" key={idx}>
                              <td>
                                <Row className="mb-2">
                                  <Col md="5">
                                    <Input
                                      type="text"
                                      value={de.name}
                                      onChange={e =>
                                        inputUpdate(e.target.value, idx,"name")
                                      }
                                      className="inner form-control"
                                      placeholder="Name"
                                    />
                                  </Col>
                                  <Col md="5">
                                    <Input
                                      type="text"
                                      value={de.holderName}
                                      onChange={
                                        e => {

                                          inputUpdate(e.target.value, idx,"holderName")
                                        }
                                      }
                                      className="inner form-control"
                                      placeholder="Name"
                                    />
                                  </Col>
                                  <Col md="2">
                                    {depart.length != 1 && (
                                      <Button
                                        color="primary"
                                        className="btn-block inner"
                                        style={{ width: "100%" }}
                                        onClick={e => {
                                          onDelete(e, idx)
                                        }}
                                      >
                                        {" "}
                                        Delete{" "}
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {(
                      <Button
                        onClick={() => {
                          handleAddRowNested()
                        }}
                        color="success"
                        className="mt-1"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={submit} color="primary">
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DeleteAlert
          visible={deleteAlertVisible}
          Title={"Delete Department"}
          Message={"Do you Want to Delete Department?"}
          toggleAlert={toggleDeleteAlert}
          Submit={Submit}
        />
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {}
}

export default withRouter<any, any>(connect(mapStateToProps, {})(FactoryDepartments))
