
import React, { useState, useEffect } from "react"

const BackButton = (props: any) => {

return(
    <div style={{ display: "flex", padding: "12px 0px" }}>
    <h4
      onClick={() => {
        props.history.goBack()
      }}
      style={{ cursor: "pointer" }}
    >
      <i
        style={{ paddingRight: "12px" }}
        className="bx bx-arrow-back"
      ></i>
      Back
    </h4>
  </div>
)

}

export default BackButton