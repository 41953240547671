import React, { useState, useEffect } from "react"
import { Col, Modal, Container, Row, Table, Form, Alert, Input } from "reactstrap"
import { connect } from "react-redux"
import { map } from "lodash"
import { withRouter } from "react-router-dom"

import * as backend from "../../helpers/backend_helper"
import UserCard from "./UserCard"
import Breadcrumb from "../../components/Common/Breadcrumb2"
import Toaster from '../../components/Common/Toaster';
import BackButton from '../../common/ui/backButton'

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const UsersList = (props: any) => {
  const { match: { params: { orgId } } } = props
  const [modal_standard, setmodal_standard] = useState(false)
  const [lists, setList] = useState([])
  const [edit, setEdit] = useState<any>(null)
  const [count, setCount] = useState(true);
 

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // handleValidSubmit
  const handleValidSubmit = async (values) => {
    if (edit) {
      update(values)
    } else {
      save(values)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      role: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      role: Yup.string().required(),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      handleValidSubmit(values);
    }
  });


  const update = async values => {
    try {
      await backend.updateUserOrg(orgId, edit['_id'], values);
      Toaster.success("User updated successfully")
      getList()
    } catch (e) {
      console.log(e)
      Toaster.error("User updated failed")
    } finally {
      tog_standard()
    }
  }
  const save = async values => {
    try {
      await backend.saveUserOrg(orgId, values);
      Toaster.success("User added successfully")
      getList()
    } catch (e) {
      console.log(e)
      Toaster.error("User added failed")
    } finally {
      tog_standard()
    }
  }

  const getList = async () => {
    try {
      const response = await backend.getUserOrg(orgId);
      setList(response);
    } catch (e) { }
  }
  useEffect(() => {
    if (lists.length == 0 && count) {
      getList()
      setCount(false);
    }
  }, [lists, count])

  //on edit
  const onEdit = (value) => {
    setEdit(value)
    validation.setValues(value);
    tog_standard()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb breadcrumbItems={[{ title: 'Orgs', to: '/orgs/list' }, { title: "Users" }]}></Breadcrumb>
        <Container fluid>
          <BackButton history={props.history} />
          <div className="mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Users</h4>
            <button
              type="button"
              onClick={() => {
                setEdit(null)
                validation.resetForm();
                tog_standard()
              }}
              className="btn btn-primary waves-effect waves-light"
              data-toggle="modal"
              data-target="#myModal"
            >
              New User
            </button>
          </div>
          <Row>
            {map(lists, (user, key) => (
              <UserCard user={user} onEdit={() => onEdit(user)} key={"_user_" + key} />
            ))}
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {edit ? "Edit User" : "Create New User"}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.error && typeof props.error === "string" ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="form-group mb-3">
              <Input
                name="name"
                label="Name"
                onChange={validation.handleChange}
                value={validation.values.name}
                className="form-control"
                placeholder="Enter Name"
                type="text"
                required
              />
            </div>
            <div className="form-group mb-3">
              <Input
                name="role"
                label="Role"
                onChange={validation.handleChange}
                value={validation.values.role}
                className="form-control"
                placeholder="Enter Role"
                type="text"
                required
              />
            </div>
            <div className="form-group mb-3">
              <Input
                name="email"
                label="Email"
                value={validation.values.email}
                onChange={validation.handleChange}
                className="form-control"
                placeholder="Enter Email"
                type="email"
                required
              />
            </div>
            <div className="form-group mb-3">
              <Input
                name="password"
                label="Password"
                value={validation.values.password}
                onChange={validation.handleChange}
                className="form-control"
                placeholder="Enter password"
                type="password"
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

export default withRouter(connect(mapStateToProps, {})(UsersList))
